import { create } from "zustand";
import { IUser } from "../api/IUser";
import { produce } from "immer";

const defaultString = "DefaultUser";
export const defaultUser: IUser = {
    id: defaultString,
    userName: defaultString,
    refreshToken: "",
    scrumMaster: false,
    useDefaults: false,
};

type UserStoreState = {
    user: IUser;
};

type UserStore = UserStoreState & {
    setUser: (v?: IUser) => void;
    clear: () => void;
};

export const useUserStore = create<UserStore>()((set) => ({
    user: defaultUser,
    setUser: (user?: IUser) =>
        set((state) =>
            produce(state, (draft) => {
                draft.user = user ? user : defaultUser;
            })
        ),
    clear: () => set({ user: defaultUser }),
}));
