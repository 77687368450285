import { create } from "zustand";
import ISubTopic from "../api/ISubTopic";
import { ITopicTask } from "../api/ITopicTask";

type ActiveTopicStoreState = {
    activeSubTopic?: ISubTopic;
    activeTask?: ITopicTask;
};

type ActiveTopicStore = ActiveTopicStoreState & {
    setActive: (subTopic?: ISubTopic, task?: ITopicTask) => void;
    setSubTopic: (subTopic?: ISubTopic) => void;
    setTask: (task?: ITopicTask) => void;
    clear: () => void;
};

export const useActiveTopicStore = create<ActiveTopicStore>()((set) => ({
    activeSubTopic: undefined,
    activeTask: undefined,

    clear: () => set({ activeSubTopic: undefined, activeTask: undefined }),
    setTask: (task) => set({ activeTask: task }),
    setSubTopic: (subTopic) => set({ activeSubTopic: subTopic }),
    setActive: (subTopic, task) =>
        set({ activeSubTopic: subTopic, activeTask: task }),
}));
