const removeKeys = <T>(obj: any, keys: string[]): T => {
    let newObj: any = {};

    for (let key in obj) {
        if (keys.includes(key)) continue;

        newObj[key as keyof typeof newObj] = obj[key];
    }

    return newObj as T;
};

export default removeKeys;
