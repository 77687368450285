import React, { useEffect, useState } from "react";
import useAllMeetings from "../api/useAllMeetings";
import { useUserStore } from "../../stores/useUserStore";
import useAxios from "../api/useAxios";
import useErrorState from "../utils/useErrorState";
import useShowError from "../utils/useShowError";
import { useNavigate, useParams } from "react-router-dom";
import { useMeetingStore } from "../../stores/useMeetingStore";
import { toString } from "../../utilities/stringify";
import IMeeting from "../../api/IMeeting";
import { get } from "react-hook-form";

const useParticipantLauncher = () => {
    const navigate = useNavigate();
    const meetingId = (useParams() as { meetingId?: string } | undefined)
        ?.meetingId;

    const setGlobalMeeting = useMeetingStore((state) => state.setMeeting);

    const user = useUserStore((state) => state.user);

    const [name, setName] = useState("");
    const [meeting, setMeeting] = useState("");
    const [group, setGroup] = useState("");
    const [joinAsHost, setJoinAsHost] = useState(false);

    const { error, setError, clear } = useErrorState();
    const { post,get } = useAxios({ error, setError });
    const { meetings } = useAllMeetings();
    useShowError(error, clear);

    const canBeHost =
        user.id === meetings.find((meet) => meet.id === meeting)?.hostId;
    const selectedMeeting = meetings.find((meet) => meet.id === meeting);

    useEffect(() => {
        meetings.sort((a, b) => b.createdOn - a.createdOn);
        if (meetings.length > 0)
            setMeeting(
                meetings.find((m) => m.id === meetingId)?.id ?? meetings[0].id
            );
    }, [meetings.length]);

    useEffect(() => {
        setName(
            user.useDefaults ? user.fullName ?? user.userName : user.userName
        );
    }, [user.id]);

    useEffect(() => {
        setGroup(
            selectedMeeting?.groups.find(
                (g) => g === user.defaultGroup && user.useDefaults
            ) ??
                selectedMeeting?.groups[0] ??
                ""
        );
    }, [toString(selectedMeeting)]);

    const toggleJoinAsHost = () => setJoinAsHost((prev) => !prev);

    const isValid = () => {
        if (name.length === 0) return setError(`Missing Name`);
        if (meeting.length === 0) return setError(`Missing Selected Meeting`);
        if (
            group.length === 0 &&
            meetings.find((m) => meeting === m.id)!.groups.length > 0
        )
            return setError(`Missing Selected Group`);
        return true;
    };

    const clearForm = () => {
        setName("");
        setMeeting("");
    };

    const submit = async () => {
        if (canBeHost && joinAsHost) {
            setGlobalMeeting(selectedMeeting);
            return navigate(`/host/${meeting}`);
        }

        if (!isValid()) return;
        console.log('using this api');
        if (group.length > 0) {
            let res = await get<IMeeting>(
                `/api/meeting/join?meetingId=${meeting}&userName=${name}&group=${group}`
            );
            if (!res) return;
            setGlobalMeeting(res);
            navigate(`/participant/${meeting}`, { state: { name } });
        }
        else {
            let res = await get<IMeeting>(
                `/api/meeting/JoinNoGroup?meetingId=${meeting}&userName=${name}`
            );
            if (!res) return;
            setGlobalMeeting(res);
            navigate(`/participant/${meeting}`, { state: { name } });
        }   
    };

    return {
        user,
        name,
        meeting,
        meetings,
        group,
        selectedMeeting,
        canBeHost,
        joinAsHost,

        setName,
        setMeeting,
        setGroup,
        toggleJoinAsHost,

        clearForm,
        submit,
    };
};

export default useParticipantLauncher;
