import React from "react";
import ISubTopic from "../../api/ISubTopic";
import { Stack } from "@mui/material";
import { TextSize } from "../utils/TextSize";
import Text from "../Text";
import { useTaskFormStore } from "../../stores/useTaskFormStore";

type ChildFormHeaderProps = {
    isChild?: boolean;
    parent?: ISubTopic;
};

const ChildFormHeader = ({ isChild, parent }: ChildFormHeaderProps) => {
    const form = useTaskFormStore((state) => state.form);
    if (!isChild || !parent) return null;
    return (
        <Stack>
            <Text bold>
                {form.includes("Add") ? "Create" : "Update"} task as child of:
            </Text>
            <Text maxLines={1} size={TextSize.Medium}>
                {parent.topic}
            </Text>
        </Stack>
    );
};

export default ChildFormHeader;
