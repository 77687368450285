import { create } from "zustand";

export enum ShownTaskForm {
    Add = "Add",
    AddChild = "AddChild",
    JiraSetup = "Jira Setup",
    JiraAdd = "Jira Add",
    Update = "Update",
    UpdateChild = "UpdateChild",
    JiraCreate = "Jira Create",
}

type TaskFormStoreState = {
    open: boolean;
    form: ShownTaskForm;
};

type TaskFormStore = TaskFormStoreState & {
    toggle: () => void;
    setOpen: (open: boolean) => void;
    setForm: (form: ShownTaskForm) => void;
    clear: () => void;
};

export const useTaskFormStore = create<TaskFormStore>()((set) => ({
    open: false,
    form: ShownTaskForm.Add,
    setOpen: (open) => set({ open }),
    toggle: () => set((state) => ({ open: !state.open })),
    setForm: (form = ShownTaskForm.Add) => set({ form }),
    clear: () => set({ form: ShownTaskForm.Add, open: false }),
}));
