import { Stack } from "@mui/material";
import React from "react";
import useAddJiraTaskForm from "../../hooks/components/useAddJiraTaskForm";
import TaskFormButtons from "./TaskFormButtons";
import FormInput from "../inputs/FormInput";
import CheckBoxInput from "../inputs/CheckBoxInput";

const AddJiraTaskForm = () => {
    const {
        control,
        loading,
        bugs,
        linked,
        done,
        submit,
        close,
        toggleBugs,
        toggleLinked,
        toggleDone,
    } = useAddJiraTaskForm();
    return (
        <Stack spacing={2}>
            <FormInput
                control={control}
                name="issueId"
                label="Jira Issue Id"
                placeHolder="Enter the Jira issue's id..."
                rules={{
                    required: "Missing Issue Id",
                }}
            />
            <Stack spacing={0}>
                <CheckBoxInput
                    checked={linked}
                    onClick={toggleLinked}
                    name="Get in progress linked issues"
                />
                {linked && (
                    <CheckBoxInput
                        checked={done}
                        onClick={toggleDone}
                        name="Get done linked issues"
                    />
                )}
                {linked && (
                    <CheckBoxInput
                        checked={bugs}
                        onClick={toggleBugs}
                        name="Get linked bugs"
                    />
                )}
            </Stack>

            <TaskFormButtons
                outlineText="Cancel"
                solidText="Add"
                outlineClick={close}
                solidClick={submit}
                loading={loading}
            />
        </Stack>
    );
};

export default AddJiraTaskForm;
