import React, { useCallback, useState } from "react";
import { useMeetingStore } from "../../stores/useMeetingStore";
import getMeetingLink from "../../utilities/meetingLink";
import useSuccessSnack from "../snackbar/useSuccessSnack";

const useQrCodeModal = () => {
    const meeting = useMeetingStore((state) => state.meeting);
    const successMsg = useSuccessSnack();

    const meetingLink = getMeetingLink(meeting?.id ?? "");

    const copyToClipboard = useCallback(() => {
        successMsg(`Link copied to clipboard`);
    }, [meetingLink]);

    return {
        meetingLink,
        copyToClipboard,
    };
};

export default useQrCodeModal;
