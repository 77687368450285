import React from "react";
import TaskCardTemplate, { TaskCardTemplateProps } from "./TaskCardTemplate";

const TaskCard = (params: TaskCardTemplateProps) => {
    return (
        <TaskCardTemplate
            {...params}
            short
            disabled={{ ...params.disabled, add: true }}
        />
    );
};

export default TaskCard;
