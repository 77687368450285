import { Box, Button, Stack } from "@mui/material";
import React from "react";
import ParticipantPageHeader from "../components/headers/ParticipantPageHeader";
import Text from "../components/Text";
import useParticipantPage from "../hooks/pages/useParticipantPage";
import PageLoading from "./PageLoading";
import ParticipantDeck from "../components/ParticipantDeck";
import Spacing from "../components/Spacing";
import ConfirmModal from "../components/modals/ConfirmModal";
import PokerCard from "../components/cards/PokerCard";
import { DeckIds } from "../api/IDeck";
import ParticipantWaitingImage from "../images/ParticipantWaiting.png";
import { ABSOLUTEFILL } from "../contracts/Styles";

const ParticipantPage = () => {
    const {
        ref,
        maxHeight,
        isVoting,
        decksLoading,
        participant,
        selected,
        confirmed,
        confirmModalOpen,
        deckOptions,
        votingTopic,

        select,
        onArrowBack,
        confirm,
        unConfirm,
        openConfirmModal,
        closeConfrimModal,
        changeDeck,
    } = useParticipantPage();

    if (!participant || decksLoading) return <PageLoading />;

    return (
        <Stack flex={1}>
            <ParticipantPageHeader
                title={votingTopic?.topic}
                deckOptions={deckOptions}
                participant={participant}
                onDeck={changeDeck}
                onArrowBack={onArrowBack}
            />

            {isVoting ? (
                <>
                    {/* {!confirmed && <Spacing vertical={10} />} */}

                    {!confirmed && (
                        <Stack
                            py={4}
                            width={"100%"}
                            alignItems={"center"}
                            justifyContent={"center"}
                            flexDirection={"column"}
                            flex={1}
                        >
                            <Stack
                                spacing={4}
                                width={{ xs: "95%", md: "90%" }}
                                alignItems={"center"}
                                justifyContent={"center"}
                                flex={1}
                            >
                                <Text>Choose your cards</Text>
                                <Stack
                                    position={"relative"}
                                    flex={1}
                                    width={"100%"}
                                >
                                    <Box
                                        sx={{
                                            ...ABSOLUTEFILL,
                                            overflowY: "auto",
                                            maxHeight: "100%",
                                        }}
                                        p={{ md: 2 }}
                                        py={{ xs: 4, md: 5 }}
                                    >
                                        <ParticipantDeck
                                            participant={participant}
                                            selected={selected}
                                            select={select}
                                        />
                                    </Box>
                                </Stack>
                                <Button
                                    disabled={selected.length === 0}
                                    onClick={openConfirmModal}
                                    color="info"
                                    variant="contained"
                                >
                                    Confirm Vote
                                </Button>
                            </Stack>
                        </Stack>
                    )}

                    {confirmed && (
                        <Stack
                            flex={1}
                            alignItems={"center"}
                            justifyContent={"center"}
                        >
                            <PokerCard
                                deck={participant.deckId as DeckIds}
                                value={selected}
                            />
                        </Stack>
                    )}
                </>
            ) : (
                <Stack
                    spacing={2}
                    flex={1}
                    alignItems={"center"}
                    justifyContent={"center"}
                >
                    <img src={ParticipantWaitingImage} alt="" />
                    <Text>Please wait for host to select topic to vote</Text>
                </Stack>
            )}

            <ConfirmModal
                isOpen={confirmModalOpen}
                onClose={closeConfrimModal}
                onNo={unConfirm}
                onYes={confirm}
            />
        </Stack>
    );
};

export default ParticipantPage;
