import React from "react";
import ModalContainer from "./ModalContainer";
import ModalTemplate, { ModalTemplateProps } from "./ModalTemplate";
import Text from "../Text";
import { Stack } from "@mui/material";
import ParticipantCard from "../cards/ParticipantCard";
import { DeckIds } from "../../api/IDeck";
import IParticipant from "../../api/IParticipant";
import FavoriteModalCard from "../cards/FavoriteModalCard";
import useFavoriteCardModal from "../../hooks/components/useFavoriteCardModal";

type FavoriteCardModalProps = ModalTemplateProps & {};

const FavoriteCardModal = ({ isOpen, onClose }: FavoriteCardModalProps) => {
    const { lowest, favorite, highest } = useFavoriteCardModal();
    return (
        <ModalTemplate isOpen={isOpen} onClose={onClose}>
            <ModalContainer>
                <Stack
                    direction={"row"}
                    alignItems={"start"}
                    justifyContent={"space-evenly"}
                    width={"100%"}
                >
                    {lowest.value.length > 0 && (
                        <FavoriteModalCard
                            title="Lowest"
                            value={lowest.value}
                            people={lowest.people}
                        />
                    )}
                    <FavoriteModalCard
                        title="Favorite"
                        value={favorite.value}
                        people={favorite.people}
                    />
                    {highest.value.length > 0 && (
                        <FavoriteModalCard
                            title="Highest"
                            value={highest.value}
                            people={highest.people}
                        />
                    )}
                </Stack>
            </ModalContainer>
        </ModalTemplate>
    );
};

export default FavoriteCardModal;
