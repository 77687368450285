import { Add, Edit, Delete, Remove } from "@mui/icons-material";
import { Stack, Button } from "@mui/material";
import React from "react";
import { COLORS, theme } from "../../utilities/theme";
import SquareIconButton from "../SquareIconButton";
import { TextSize } from "../utils/TextSize";
import Text from "../Text";
import { TaskCardDisabled } from "./TaskCardTemplate";
import useScreenSize from "../../hooks/utils/useScreenSize";

type TaskCardActionsProps = {
    active?: boolean;
    short?: boolean;
    points?: number;
    disabled?: TaskCardDisabled;
    onDeletePress?: () => void;
    onAddPress?: () => void;
    onEditPress?: () => void;
};

const TaskCardActions = ({
    active,
    short,
    points,
    disabled,
    onDeletePress,
    onAddPress,
    onEditPress,
}: TaskCardActionsProps) => {
    const { xs } = useScreenSize();

    return (
        <Stack
            sx={{ pointerEvents: "all" }}
            direction={"row"}
            flexDirection={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
            pb={2}
            px={2}
        >
            {!disabled?.all ? (
                <Stack direction={"row"} flexDirection={"row"} spacing={2}>
                    {!short && !disabled?.add && (
                        <SquareIconButton onClick={onAddPress}>
                            <Add />
                        </SquareIconButton>
                    )}
                    {!disabled?.edit && (
                        <SquareIconButton onClick={onEditPress}>
                            <Edit />
                        </SquareIconButton>
                    )}
                    {!disabled?.trash && (
                        <SquareIconButton onClick={onDeletePress}>
                            <Delete />
                        </SquareIconButton>
                    )}
                    {active && !xs && (
                        <Button
                            disableRipple
                            disableElevation
                            disableFocusRipple
                            disableTouchRipple
                            sx={{
                                cursor: "default",
                                "&:hover": {
                                    backgroundColor: theme.palette.primary.main,
                                },
                            }}
                            variant="contained"
                        >
                            <Text size={TextSize.Small}>Voting</Text>
                        </Button>
                    )}
                </Stack>
            ) : (
                <Stack></Stack>
            )}
            <Stack>
                {points !== undefined && points > 0 ? (
                    <Button
                        color="secondary"
                        onClick={onEditPress}
                        sx={{ backgroundColor: COLORS.grey }}
                    >
                        <Text
                            size={TextSize.Large}
                            color={theme.palette.info.main}
                        >
                            {points}
                        </Text>
                    </Button>
                ) : (
                    <SquareIconButton onClick={onEditPress}>
                        <Remove />
                    </SquareIconButton>
                )}
            </Stack>
        </Stack>
    );
};

export default TaskCardActions;
