import {
    Button,
    ButtonProps,
    Menu,
    MenuItem,
    Stack,
    useTheme,
} from "@mui/material";
import React, { ReactNode, useCallback, useState } from "react";
import Text from "../Text";
import { SelectItem } from "./SelectInput";
import { TextSize } from "../utils/TextSize";
import { Filter, Sort } from "@mui/icons-material";

type MenuInputProps = {
    options: SelectItem[];
    color?: string;
    includeNone?: boolean;
    title?: string;
    value?: string;
    icon?: ReactNode;
    textSize?: TextSize;
    onItem?: (item: SelectItem) => void;

    ButtonProps?: ButtonProps;
};

const MenuInput = ({
    options: originalOptions,
    color,
    title,
    includeNone = true,
    value,
    icon,
    textSize = TextSize.Medium,
    onItem = () => {},

    ButtonProps,
}: MenuInputProps) => {
    const theme = useTheme();

    const options = includeNone
        ? originalOptions.concat([
              { key: "None", value: "", placeHolder: "None" },
          ])
        : originalOptions;
    const [anchor, setAnchor] = useState<null | HTMLElement>(null);
    const open = Boolean(anchor);

    const onClick = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
        setAnchor(e.currentTarget);
    }, []);

    const onBlur = useCallback(() => {
        setAnchor(null);
    }, []);

    const onSelect = useCallback(
        (item: SelectItem) => () => {
            onItem(item);
            onBlur();
        },
        [onItem, onBlur]
    );

    return (
        <>
            <Button
                startIcon={<Sort style={{ color }} />}
                color={"secondary"}
                onClick={onClick}
                {...ButtonProps}
            >
                {icon}
                {title && (
                    <Text color={color} size={textSize}>
                        {title}
                    </Text>
                )}
            </Button>
            <Menu anchorEl={anchor} open={open} onClose={onBlur}>
                {options.map((option) => {
                    const active = option.value === value;
                    return (
                        <MenuItem key={option.key} onClick={onSelect(option)}>
                            <Text
                                color={
                                    active
                                        ? theme.palette.info.main
                                        : theme.palette.secondary.main
                                }
                                bold={active}
                                size={TextSize.Small}
                            >
                                {option.placeHolder ?? option.value}
                            </Text>
                        </MenuItem>
                    );
                })}
            </Menu>
        </>
    );
};

export default MenuInput;
