import React, { useCallback } from "react";
import { useMeetingStore } from "../../stores/useMeetingStore";
import useAxios, { UseAxiosProps } from "./useAxios";
import IMeeting from "../../api/IMeeting";
import { useActiveTopicStore } from "../../stores/useActiveTopicStore";
import { IRoundVote } from "../../api/IRound";
import useIsOpen from "../utils/useIsOpen";
import { toString } from "../../utilities/stringify";

type UseRoundControlProps = {
    axiosProps?: UseAxiosProps;
};

const useRoundControl = (params?: UseRoundControlProps) => {
    const meeting = useMeetingStore((state) => state.meeting);
    const { activeSubTopic, activeTask } = useActiveTopicStore(
        ({ activeSubTopic, activeTask }) => ({ activeSubTopic, activeTask })
    );

    const { error, loading, clearError, setError, post } = useAxios(
        params?.axiosProps
    );

    const [isCardShown, showCards, closeCards] = useIsOpen();

    const roundStarted =
        meeting?.votingRound !== undefined &&
        meeting.votingRound !== null &&
        meeting.votingRound.length > 0;

    const allParticipantsVoted = useCallback(() => {
        if (!meeting || meeting.participants.length === 0) return true;

        for (let participant of meeting.participants) {
            if (
                participant.selectedPoker === null ||
                participant.selectedPoker === undefined
            )
                return false;
        }

        return true;
    }, [toString(meeting?.participants)])();

    const startRoundValid = () => {
        if (!meeting) return setError(`Missing meeting`);
        return true;
    };

    const endRoundValid = () => {
        if (!meeting) return setError(`Missing meeting`);
        return true;
    };

    const startRound = async () => {
        closeCards();

        if (!startRoundValid()) return;

        let res = await post<IMeeting>(
            `/api/meeting/StartVotingRound?meetingId=${meeting!.id}`,
            {
                votingOn: activeTask?.id ?? activeSubTopic?.id,
            }
        );
    };

    const endRound = async () => {
        if (!endRoundValid()) return;

        closeCards();

        let res = await post<IMeeting>(
            `/api/meeting/EndVotingRound?meetingId=${meeting!.id}`,
            {
                meetingId: meeting!.id,
                votes: meeting!.participants.map(
                    (participant): IRoundVote => ({
                        userId: participant.userId,
                        value: participant.selectedPoker?.pokerValue,
                    })
                ),
            }
        );
    };

    const onRoundClick = () => {
        if (!meeting) return;

        if (!roundStarted) return startRound();

        if (!isCardShown) return showCards();

        endRound();
    };

    return {
        error,
        loading,
        roundStarted,
        allParticipantsVoted,
        isCardShown,

        onRoundClick,
        clearError,
        setError,
    };
};

export default useRoundControl;
