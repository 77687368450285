import React, { useEffect } from "react";
import { useUserStore } from "../../stores/useUserStore";
import { useLocation, useNavigate } from "react-router-dom";
import useLogout from "../api/useLogout";
import { SelectItem } from "../../components/inputs/SelectInput";
import useScreenSize from "../utils/useScreenSize";
import useIsOpen from "../utils/useIsOpen";

const useHeader = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const user = useUserStore((state) => state.user);

    // const { xsOrsm } = useScreenSize();
    const [menuOpen, _, closeMenu, toggleMenu] = useIsOpen(false);

    // useEffect(() => {}, []);

    const logout = useLogout();

    const isHome = location.pathname === "/";
    const isSettings = location.pathname === "/settings";

    const toHome = () => {
        navigate(`/`);
        closeMenu();
    };
    const toSettings = () => {
        navigate(`/settings`);
        closeMenu();
    };
    const toLogin = () => {
        logout();
        navigate("/login");
        closeMenu();
    };

    return {
        user,
        isHome,
        isSettings,
        menuOpen,

        closeMenu,
        toggleMenu,
        toHome,
        toSettings,
        toLogin,
    };
};

export default useHeader;
