import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ShownTaskForm, useTaskFormStore } from "../../stores/useTaskFormStore";
import { useMeetingStore } from "../../stores/useMeetingStore";
import { useForm } from "react-hook-form";
import useAxios from "../api/useAxios";
import useShowError from "../utils/useShowError";
import { useShowFormError } from "../utils/useShowFormError";
import IMeeting from "../../api/IMeeting";

const useAddJiraTaskForm = () => {
    const meeting = useMeetingStore((state) => state.meeting);
    const setMeeting = useMeetingStore((state) => state.setMeeting);
    const setForm = useTaskFormStore((state) => state.setForm);
    const setOpen = useTaskFormStore((state) => state.setOpen);

    const [bugs, setBugs] = useState(false);
    const [linked, setLinked] = useState(true);
    const [done, setDone] = useState(false);

    const toggleBugs = () => setBugs((prev) => !prev);
    const toggleLinked = () => setLinked((prev) => !prev);
    const toggleDone = () => setDone((prev) => !prev);

    const { error, loading, setError, clearError, get } = useAxios();
    useShowError(error, clearError);

    const {
        control,
        formState: { errors, submitCount },
        clearErrors,
        handleSubmit,
        reset,
    } = useForm<{ issueId: string }>({
        defaultValues: {
            issueId: "",
        },
    });
    useShowFormError(errors, submitCount, clearErrors);

    useEffect(() => {
        if (!meeting?.jiraConnected) setForm(ShownTaskForm.JiraSetup);
    }, [meeting?.id]);

    const close = () => {
        reset();
        setOpen(false);
    };

    const submit = handleSubmit(async (data) => {
        if (!meeting) return setError(`Missing Meeting`);

        let res = await get<IMeeting>(
            `/api/meeting/GetJiraIssue?meetingId=${
                meeting.id
            }&issueId=${data.issueId.trim()}&linked=${linked}&bugs=${bugs}&done=${done}`
        );

        if (res) setMeeting(res);

        close();
    });

    return {
        control,
        loading,
        bugs,
        linked,
        done,
        submit,
        close,
        toggleBugs,
        toggleLinked,
        toggleDone,
    };
};

export default useAddJiraTaskForm;
