import React from "react";
import ModalTemplate, { ModalTemplateProps } from "./ModalTemplate";
import { Button, Stack } from "@mui/material";
import ModalContainer from "./ModalContainer";
import Text from "../Text";

type ConfirmModalProps = ModalTemplateProps & {
    onNo?: () => void;
    onYes?: () => void;
};

const ConfirmModal = ({ isOpen, onClose, onNo, onYes }: ConfirmModalProps) => {
    return (
        <ModalTemplate isOpen={isOpen} onClose={onClose}>
            <ModalContainer>
                <Stack
                    py={2}
                    alignItems={"center"}
                    spacing={2}
                    justifyContent={"center"}
                >
                    <Text bold>Confirm Voting</Text>
                    <Text>Are you sure you want to choose this card?</Text>
                    <Stack
                        pt={1}
                        direction={"row"}
                        spacing={4}
                        alignItems={"center"}
                        justifyContent={"center"}
                    >
                        <Button onClick={onNo} color="info" variant="outlined">
                            No
                        </Button>
                        <Button
                            onClick={onYes}
                            color="info"
                            variant="contained"
                        >
                            Yes
                        </Button>
                    </Stack>
                </Stack>
            </ModalContainer>
        </ModalTemplate>
    );
};

export default ConfirmModal;
