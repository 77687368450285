import React, { HTMLInputTypeAttribute, ReactNode, Ref } from "react";
import {
    Control,
    Controller,
    FieldValues,
    Path,
    RegisterOptions,
} from "react-hook-form";
import { TextField } from "@mui/material";
import { BaseTextInputProps } from "./TextInput";
import { AllElements } from "../../hooks/utils/useFocusInputs";
import useScreenSize from "../../hooks/utils/useScreenSize";

type FormInputProps<T extends FieldValues> = Omit<
    Omit<Omit<BaseTextInputProps, "value">, "setValue">,
    "name"
> & {
    control: Control<T>;
    rules: Omit<
        RegisterOptions<T, Path<T>>,
        "valueAsNumber" | "valueAsDate" | "setValueAs" | "disabled"
    >;
    name: Path<T>;
    inputRef?: Ref<AllElements>;
    type?: HTMLInputTypeAttribute;
    multiline?: boolean;
    maxRows?: number;
    label?: string;
    endIcon?: ReactNode;
    startIcon?: ReactNode;
    onKeyPress?: (e: React.KeyboardEvent<HTMLDivElement>) => void;
};

const FormInput = <T extends FieldValues>({
    control,
    rules,
    name,
    type = "text",
    multiline,
    maxRows,
    fullWidth,
    placeHolder,
    label,
    startIcon,
    endIcon,
    inputRef,
    onKeyPress,
}: FormInputProps<T>) => {
    const { xsOrsm } = useScreenSize();
    return (
        <Controller
            control={control}
            rules={rules}
            name={name}
            render={({ field: { value, onBlur, onChange } }) => (
                <TextField
                    fullWidth={fullWidth}
                    value={value}
                    label={label ?? placeHolder ?? name}
                    placeholder={placeHolder ?? name}
                    variant="outlined"
                    color="primary"
                    type={type}
                    onChange={onChange}
                    onBlur={onBlur}
                    onKeyDown={onKeyPress}
                    multiline={multiline}
                    maxRows={multiline ? maxRows ?? 8 : 1}
                    inputRef={inputRef}
                    sx={{
                        padding: xsOrsm ? 0 : undefined,
                    }}
                    size={xsOrsm ? "small" : "medium"}
                    InputProps={{
                        endAdornment: endIcon,
                        startAdornment: startIcon,
                    }}
                />
            )}
        />
    );
};

export default FormInput;
