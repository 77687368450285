import { Stack, useTheme } from "@mui/material";
import React, { PropsWithChildren } from "react";
import { ABSOLUTEFILL } from "../../contracts/Styles";
import Text from "../Text";
import { TextSize } from "../utils/TextSize";
import { CheckCircle } from "@mui/icons-material";
import CONSTANTS from "../../contracts/Constants";

type VotingOverlayProps = {
    isVoting?: boolean;
    isSelected?: boolean;
};

const VotingOverlay = ({
    isSelected,
    isVoting,
    children,
}: PropsWithChildren<VotingOverlayProps>) => {
    const theme = useTheme();
    return (
        <Stack flex={1} sx={{ position: "relative" }}>
            {children}
            <Stack
                sx={{
                    ...ABSOLUTEFILL,
                    borderRadius: theme.shape.borderRadius,
                    bgcolor: isVoting
                        ? theme.palette.secondary.main + "BF"
                        : undefined,
                    color: theme.palette.background.paper,
                }}
                alignItems={"center"}
                justifyContent={"center"}
            >
                {isVoting && !isSelected && (
                    <Text bold size={TextSize.Large}>
                        Voting...
                    </Text>
                )}
                {isVoting && isSelected && (
                    <Stack
                        bgcolor={theme.palette.background.paper}
                        borderRadius={100}
                    >
                        <CheckCircle
                            color="success"
                            sx={{ fontSize: `${24 * 2}px` }}
                        />
                    </Stack>
                )}
            </Stack>
        </Stack>
    );
};

export default VotingOverlay;
