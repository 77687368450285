import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Stack,
    useTheme,
} from "@mui/material";
import React from "react";
import RippleStack from "../RippleStack";
import { Add } from "@mui/icons-material";
import Text from "../Text";
import { TextSize } from "../utils/TextSize";
import useIsOpen from "../../hooks/utils/useIsOpen";
import TaskFormAccordion from "./TaskFormAccordion";
import AddTaskForm from "./AddTaskForm";
import SelectInput from "../inputs/SelectInput";
import useTaskForm from "../../hooks/components/useTaskForm";
import { ShownTaskForm } from "../../stores/useTaskFormStore";
import SetupJiraForm from "./SetupJiraForm";
import UpdateTaskForm from "./UpdateTaskForm";
import CreateJiraTaskForm from "./CreateJiraTaskForm";

const TaskForm = () => {
    const theme = useTheme();
    const { form, options, setForm } = useTaskForm();
    return (
        <TaskFormAccordion>
            {form.includes("Add") && <AddTaskForm />}
            {form === ShownTaskForm.JiraSetup && <SetupJiraForm />}
            {form.includes("Update") && <UpdateTaskForm />}
            {form === ShownTaskForm.JiraCreate && <CreateJiraTaskForm />}
        </TaskFormAccordion>
    );
};

export default TaskForm;
