import React from "react";
import useHubConnection from "./useHubConnection";
import CONSTANTS from "../../contracts/Constants";

type UseOnNewMeetingProps = {
    onMeetingChange: () => void;
};

const useOnNewMeeting = ({ onMeetingChange }: UseOnNewMeetingProps) => {
    useHubConnection(
        CONSTANTS.SocketUrl + `?meetingId=All`,
        (hubConnection) => {
            hubConnection.on("MeetingStarted", onMeetingChange);
            hubConnection.on("MeetingEnded", onMeetingChange);
        }
    );
};

export default useOnNewMeeting;
