import React from "react";
import useJWTCookie from "./useJWTCookie";
import { useNavigate } from "react-router-dom";
import { useUserStore } from "../../stores/useUserStore";

const useLogout = () => {
    const navigate = useNavigate();
    const setUser = useUserStore((state) => state.setUser);
    const { remove } = useJWTCookie();

    const logout = () => {
        remove();
        setUser(undefined);
    };

    return logout;
};

export default useLogout;
