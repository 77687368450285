import { Backdrop, Box, Card, Paper, Stack, useTheme } from "@mui/material";
import React, { useEffect } from "react";
import Text from "../Text";
import CONSTANTS from "../../contracts/Constants";
import { TextSize } from "../utils/TextSize";
import FlipCard from "./FlipCard";
import SvgCard from "./SvgCard";
import IParticipant from "../../api/IParticipant";
import { cutString, toString } from "../../utilities/stringify";
import { DeckIds } from "../../api/IDeck";
import useParticipantCard from "../../hooks/components/useParticipantCard";
import VotingOverlay from "./VotingOverlay";
import PokerCard from "./PokerCard";
import useCardSize from "../../hooks/utils/useCardSize";
import useScreenSize from "../../hooks/utils/useScreenSize";

type ParticipantCardProps = {
    participant: IParticipant;
    isVoting?: boolean;
    fullName?: boolean;
    isShown?: boolean;
    overideSize?: number;
    onClick?: () => void;
};

const ParticipantCard = ({
    participant,
    isShown,
    isVoting,
    fullName,
    overideSize,
    onClick,
}: ParticipantCardProps) => {
    const theme = useTheme();
    const { xsOrsm } = useScreenSize();
    const [width, height] = useCardSize(overideSize);

    return (
        <Stack minWidth={width} alignItems={"center"} spacing={1}>
            <Text bold size={TextSize.Large}>
                {fullName
                    ? participant.name
                    : cutString(participant.name, xsOrsm ? 10 : 13)}
            </Text>

            <Stack
                height={height}
                width={"100%"}
                sx={{
                    position: "relative",
                }}
                alignItems={"center"}
            >
                <FlipCard
                    overideSize={overideSize}
                    isFlipped={isShown}
                    onClick={onClick}
                    front={
                        participant.selectedPoker && (
                            <PokerCard
                                deck={participant.deckId as DeckIds}
                                value={participant.selectedPoker.pokerValue}
                                overideSize={overideSize}
                            />
                        )
                    }
                    back={
                        <VotingOverlay
                            isVoting={isVoting}
                            isSelected={
                                participant.selectedPoker !== undefined &&
                                participant.selectedPoker !== null
                            }
                        >
                            <PokerCard
                                overideSize={overideSize}
                                deck={participant.deckId as DeckIds}
                            />
                        </VotingOverlay>
                    }
                />
            </Stack>
            <Text size={TextSize.Medium}>{participant.group}</Text>
        </Stack>
    );
};

export default ParticipantCard;
