import { Stack } from "@mui/material";
import React from "react";
import useSetupJiraForm from "../../hooks/components/useSetupJiraForm";
import TaskFormButtons from "./TaskFormButtons";
import FormInput from "../inputs/FormInput";

const SetupJiraForm = () => {
    const { control, loading, submit, close } = useSetupJiraForm();
    return (
        <Stack spacing={2}>
            <FormInput
                control={control}
                name="email"
                label="Jira Account Email"
                placeHolder="Enter your Jira connected email..."
                rules={{
                    required: "Missing Email",
                }}
            />
            <FormInput
                control={control}
                name="token"
                label="Jira Api Token"
                placeHolder="Enter your Jira api token..."
                rules={{
                    required: "Missing Api Token",
                }}
                multiline
            />
            <TaskFormButtons
                solidText="Connect"
                outlineClick={close}
                solidClick={submit}
                loading={loading}
            />
        </Stack>
    );
};

export default SetupJiraForm;
