import React from "react";
// import { useCookies } from "react-cookie";
// import { ReactSession } from "react-client-session";
import localforage from "localforage";
import CONSTANTS from "../../contracts/Constants";

const oneMinuteMilliseconds = CONSTANTS.OneMinute;

const useJWTCookie = () => {
    // ReactSession.setStoreType("sessionStorage");

    // const set = (name: string, value: any) => ReactSession.set(name, value);
    // const get = (name: string) => ReactSession.get(name);

    const set = (name: string, value: any) =>
        localforage.setItem(name, value).catch((err) => {});
    const get = async (name: string) =>
        localforage
            .getItem(name)
            .then((value) => value)
            .catch((err) => {});

    const setJwt = async (token: string) => {
        remove();
        let refreshAt = Date.now() + oneMinuteMilliseconds * 30;

        await set("jwt", token);
        await set("refreshAt", refreshAt); // 2 min
    };

    const getJwt = async () => {
        const jwt = (await get("jwt")) as string | undefined | null;
        const refresh = (await get("refreshAt")) as string | undefined | null;
        return [jwt, refresh];
    };

    const remove = async () => {
        await set("jwt", "");
        await set("refreshAt", 0);
    };

    return {
        setJwt,
        getJwt,
        remove,
    };
};

export default useJWTCookie;
