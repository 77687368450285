import React, { useCallback } from "react";
import IParticipant from "../../api/IParticipant";
import { DeckIds } from "../../api/IDeck";
import { LovelyPokerKeys, BicyclePokerKeys } from "../../utilities/pokerImages";
import useComponentDimensions from "../utils/useComponentDimensions";
import useToRow from "../utils/useToRow";
import { toString } from "../../utilities/stringify";

type UseParticipantDeckProps = {
    participant: IParticipant;
    selected?: string;
};

const useParticipantDeck = ({
    participant,
    selected,
}: UseParticipantDeckProps) => {
    const { ref, width: maxWidth } = useComponentDimensions<HTMLDivElement>();
    const deckValues = Object.values(
        participant.deckId === DeckIds.Lovely
            ? LovelyPokerKeys
            : BicyclePokerKeys
    ) as string[];

    const rows = useToRow<string>(deckValues, { width: maxWidth });

    const getSelectedRow = useCallback(
        (rows: string[][], selected?: string) => {
            let rowNumber = -1;

            for (let i = 0; i < rows.length; i++) {
                let row = rows[i];

                let found = row.find((v) => v === selected);

                if (found !== undefined) {
                    rowNumber = i;
                }
            }

            return rowNumber;
        },
        []
    );

    return {
        deckValues,
        rows,
        ref,
        selectedRow: getSelectedRow(rows, selected),
        isCustom: participant.deckId === DeckIds.Custom,
    };
};

export default useParticipantDeck;
