import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import useIsOpen from "../utils/useIsOpen";
import { useShowFormError } from "../utils/useShowFormError";
import useAxios from "../api/useAxios";
import { IUser } from "../../api/IUser";
import useLogin from "../api/useLogin";
import useFocusInputs from "../utils/useFocusInputs";
import { useParams } from "react-router-dom";
import useOrientation from "../utils/useOrientation";
import useScreenSize from "../utils/useScreenSize";

const useLoginPage = () => {
    const { orientation } = useOrientation();
    const { xsOrsm } = useScreenSize();

    return {
        orientation,
        xsOrsm,
    };
};

export default useLoginPage;
