import React, { useEffect } from "react";
import useErrorSnack from "../snackbar/useErrorSnack";

const useShowError = (
    error: string,
    clear: () => void,
    duration: number = 2250
) => {
    const errorMsg = useErrorSnack();
    useEffect(() => {
        if (error.length === 0) return () => {};

        errorMsg(error, duration);
        setTimeout(() => {
            clear();
        }, duration);
    }, [error]);
    return;
};

export default useShowError;
