import {
    Backdrop,
    IconButton,
    Modal,
    Stack,
    Typography,
    useTheme,
} from "@mui/material";
import React from "react";
import {
    Close,
    Home,
    Menu,
    Power,
    PowerSettingsNew,
    Settings,
    Sort,
} from "@mui/icons-material";
import useHeader from "../../hooks/components/useHeader";
import Logo from "../Logo";
import { TextSize } from "../utils/TextSize";
import Text from "../Text";
import HeaderTab from "./HeaderTab";
import { defaultUser } from "../../stores/useUserStore";
import useScreenSize from "../../hooks/utils/useScreenSize";
import MenuInput from "../inputs/MenuInput";
import MobileHeaderTab from "./MobileHeaderTab";
import ContentOnlyAccordion from "../ContentOnlyAccordion";
import { COLORS } from "../../utilities/theme";

const Header = () => {
    const theme = useTheme();
    const textColor = theme.palette.primary.contrastText;

    const {
        user,
        isHome,
        isSettings,
        menuOpen,

        closeMenu,
        toggleMenu,
        toHome,
        toSettings,
        toLogin,
    } = useHeader();

    const { xs, sm, xsOrsm } = useScreenSize();

    return (
        <Stack>
            <Stack
                bgcolor={theme.palette.info.main}
                direction={"row"}
                flexDirection={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}
                padding={{ xs: 1, sm: 2 }}
                px={{ xs: undefined, md: 4 }}
                sx={{ position: "relative", zIndex: 50 }}
            >
                <Stack
                    alignItems={"center"}
                    justifyContent={"flex-start"}
                    spacing={1}
                    direction={"row"}
                    flexDirection={"row"}
                >
                    <Logo size={xs ? 3 : undefined} />
                </Stack>

                {xsOrsm && (
                    <ContentOnlyAccordion
                        open={menuOpen}
                        sx={{
                            position: "absolute",
                            top: "99%",
                            left: 0,
                            right: 0,
                            maxHeight: "max-content",
                            zIndex: 50,
                            bgcolor: theme.palette.info.main,
                            pt: menuOpen ? "2px" : 0,
                            background: `linear-gradient(to right, ${COLORS.purple}, ${COLORS.lightBlue})`,
                        }}
                    >
                        <Stack px={4} pb={1} bgcolor={theme.palette.info.main}>
                            <MobileHeaderTab
                                title="Home"
                                border
                                icon={<Home style={{ color: textColor }} />}
                                onPress={toHome}
                            />
                            <MobileHeaderTab
                                title="Settings"
                                border
                                icon={<Settings style={{ color: textColor }} />}
                                onPress={toSettings}
                            />
                            <MobileHeaderTab
                                title="Logout"
                                icon={
                                    <PowerSettingsNew
                                        style={{ color: textColor }}
                                    />
                                }
                                onPress={toLogin}
                            />
                        </Stack>
                    </ContentOnlyAccordion>
                )}

                {user.id !== defaultUser.id ? (
                    xsOrsm ? (
                        <Stack>
                            <IconButton
                                onClick={toggleMenu}
                                sx={{ color: theme.palette.background.paper }}
                            >
                                {menuOpen ? (
                                    <Close />
                                ) : (
                                    <Menu
                                        style={{
                                            transform: `rotateY(180deg)`,
                                        }}
                                    />
                                )}
                            </IconButton>
                        </Stack>
                    ) : (
                        <Stack
                            spacing={2}
                            direction={"row"}
                            flexDirection={"row"}
                        >
                            <HeaderTab
                                text="Home"
                                to="/"
                                bold={isHome}
                                icon={<Home style={{ color: textColor }} />}
                                // onPress={toHome}
                            />
                            <HeaderTab
                                text="Settings"
                                to="/settings"
                                bold={isSettings}
                                icon={<Settings style={{ color: textColor }} />}
                                // onPress={toSettings}
                            />
                            <HeaderTab
                                text="Logout"
                                to="/login"
                                icon={
                                    <PowerSettingsNew
                                        style={{ color: textColor }}
                                    />
                                }
                                onPress={toLogin}
                            />
                        </Stack>
                    )
                ) : (
                    <Stack></Stack>
                )}
            </Stack>

            <Modal
                open={menuOpen}
                onClose={closeMenu}
                sx={{ opacity: 0, pointerEvents: "none" }}
            >
                <Stack />
            </Modal>

            <Backdrop
                onScroll={(e) => e.preventDefault()}
                onScrollCapture={(e) => e.preventDefault()}
                component={"div"}
                open={menuOpen}
                onClick={closeMenu}
                sx={{
                    position: "fixed",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    pointerEvents: "all",
                    overflowY: "auto",
                    zIndex: 45,
                }}
            />
        </Stack>
    );
};

export default Header;
