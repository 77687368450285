import React from "react";
import { DeckIds, DeckNames } from "../../api/IDeck";
import {
    getClubsImage,
    getDiamondImage,
    getHeartsImage,
    getLovelyImage,
    getSpadesImage,
} from "../../utilities/pokerImages";

type UseSvgCard = {
    deck: DeckIds;
    value?: string;
};

const useSvgCard = ({ deck, value }: UseSvgCard) => {
    const getCardImage = () => {
        return deck === DeckIds.Lovely
            ? getLovelyImage(value)
            : deck === DeckIds.Hearts
            ? getHeartsImage(value)
            : deck === DeckIds.Diamonds
            ? getDiamondImage(value)
            : deck === DeckIds.Spades
            ? getSpadesImage(value)
            : getClubsImage(value);
    };

    return {
        getCardImage,
    };
};

export default useSvgCard;
