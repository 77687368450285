import React from "react";
import { ShownTaskForm, useTaskFormStore } from "../../stores/useTaskFormStore";
import { SelectItem } from "../../components/inputs/SelectInput";

const useTaskForm = () => {
    const { form, setForm } = useTaskFormStore();

    const options = Object.values(ShownTaskForm).map(
        (form): SelectItem => ({ key: form, value: form, placeHolder: form })
    );
    return {
        options,
        form,
        setForm,
    };
};

export default useTaskForm;
