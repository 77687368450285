import { Container, Stack, SxProps, Theme } from "@mui/material";
import React, { useCallback } from "react";
import Text from "../Text";
import SquareIconButton from "../SquareIconButton";
import { Window, Filter, Menu } from "@mui/icons-material";
import ParticipantViewerHeader from "./ParticipantViewerHeader";
import ParticipantCard from "../cards/ParticipantCard";
import useToRow from "../../hooks/utils/useToRow";
import ParticipantRow from "./ParticipantRow";
import useComponentDimensions from "../../hooks/utils/useComponentDimensions";
import { ABSOLUTEFILL } from "../../contracts/Styles";
import CONSTANTS from "../../contracts/Constants";
import { useMeetingStore } from "../../stores/useMeetingStore";
import IParticipant from "../../api/IParticipant";
import useParticipantViewer from "../../hooks/components/useParticipantViewer";
import ParticipantListItem from "./ParticipantListItem";
import ParticipantListHeader from "./ParticipantListHeader";
import { COLORS, theme } from "../../utilities/theme";
import useScreenSize from "../../hooks/utils/useScreenSize";

type ParticipantViewerProps = {
    isVoting?: boolean;
    isCardShown?: boolean;
    onParticipant?: (person: IParticipant) => void;
    onDiscuss?: () => void;
};

const ParticipantViewer = ({
    isVoting,
    isCardShown,
    onParticipant,
    onDiscuss,
}: ParticipantViewerProps) => {
    const {
        ref,
        mdUp,
        rows,
        isCardView,
        participants,
        filter,
        setFilter,
        toCardView,
        toListView,
    } = useParticipantViewer();

    const stackStyle: SxProps<Theme> = isCardView
        ? {
              width: { xs: "100%", md: "95%" },
              flex: 1,
          }
        : {
              width: "99%",
              bgcolor: theme.palette.background.paper,
              borderWidth: 2,
              borderStyle: "solid",
              borderColor: COLORS.grey,
              borderRadius: theme.shape.borderRadius,
          };

    return (
        <Stack
            ref={ref}
            paddingX={{ xs: isCardView ? 0 : 2, md: 4 }}
            flex={1}
            spacing={2}
        >
            <ParticipantViewerHeader
                filter={filter}
                setFilter={setFilter}
                cardActive={isCardView}
                onCardClick={toCardView}
                onMenuClick={toListView}
                onDiscuss={onDiscuss}
            />
            <Stack flex={1} style={{ position: "relative" }}>
                <Stack
                    sx={
                        mdUp
                            ? {
                                  ...ABSOLUTEFILL,
                                  overflowY: "auto",
                              }
                            : undefined
                    }
                    alignItems={"center"}
                    justifyContent={"flex-start"}
                    spacing={3}
                >
                    <Stack sx={stackStyle}>
                        {!isCardView && (
                            <ParticipantListHeader
                                bottomBorder={participants.length > 0}
                            />
                        )}
                        {isCardView
                            ? rows.map((m, i) => (
                                  <ParticipantRow
                                      isVoting={isVoting}
                                      isCardShown={isCardShown}
                                      onParticipant={onParticipant}
                                      participants={m}
                                      key={i}
                                  />
                              ))
                            : participants.map((participant, i, arr) => (
                                  <ParticipantListItem
                                      key={participant.name}
                                      isVoting={isVoting}
                                      isShown={isCardShown}
                                      participant={participant}
                                      isLast={i === arr.length - 1}
                                      onClick={() =>
                                          onParticipant &&
                                          onParticipant(participant)
                                      }
                                  />
                              ))}
                    </Stack>
                </Stack>
            </Stack>
        </Stack>
    );
};

export default ParticipantViewer;
