import { create } from "zustand";
import IParticipant from "../api/IParticipant";

type ActiveParticipantStoreState = {
    participant?: IParticipant;
};

type ActiveParticipantStore = ActiveParticipantStoreState & {
    setParticipant: (participant?: IParticipant) => void;
};

export const useActiveParticipantStore = create<ActiveParticipantStore>()(
    (set) => ({
        participant: undefined,
        setParticipant: (participant) => set({ participant }),
    })
);
