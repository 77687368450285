export const onEnterKey =
    (cb?: () => void) => (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (keyboardKey.isEnter(e.key)) cb && cb();
    };

const keyboardKey = {
    Enter: "Enter",

    isEnter(key: string): boolean {
        return key === this.Enter;
    },

    onEnterKey,
};

export default keyboardKey;
