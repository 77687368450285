import { Stack, Container, Box, Paper, Button } from "@mui/material";
import React from "react";
import Text from "../components/Text";
import { useNavigate } from "react-router-dom";
import { TextSize } from "../components/utils/TextSize";

const ErrorPage = () => {
    const navigate = useNavigate();
    const toHome = () => navigate("/");
    return (
        <Stack flex={1} alignItems={"center"} justifyContent={"center"}>
            <Container maxWidth={"lg"}>
                <Stack width={"100%"} spacing={4}>
                    <Box>
                        <Paper elevation={2}>
                            <Stack
                                padding={4}
                                alignItems={"center"}
                                justifyContent={"center"}
                                spacing={2}
                            >
                                <Text bold size={TextSize.Large}>
                                    Sorry, Page Not Found
                                </Text>
                                <Text size={TextSize.Medium}>
                                    The page you are looking for has either been
                                    removed or does not exist
                                </Text>
                                <Button
                                    variant="contained"
                                    color="info"
                                    onClick={toHome}
                                >
                                    <Text size={TextSize.Small}>
                                        Ok take me home
                                    </Text>
                                </Button>
                            </Stack>
                        </Paper>
                    </Box>
                </Stack>
            </Container>
        </Stack>
    );
};

export default ErrorPage;
