import React from "react";
import ModalTemplate, { ModalTemplateProps } from "./ModalTemplate";
import { Fab, Stack } from "@mui/material";
import QRCode from "qrcode.react";
import useQrCodeModal from "../../hooks/components/useQrCodeModal";
import { Link } from "react-router-dom";
import Text from "../Text";
import ModalContainer from "./ModalContainer";
import useScreenSize from "../../hooks/utils/useScreenSize";
import { ContentCopy, CopyAll } from "@mui/icons-material";
import { CopyToClipboard } from "react-copy-to-clipboard";

type QrCodeModalProps = ModalTemplateProps & {};

const QrCodeModal = ({ isOpen, onClose }: QrCodeModalProps) => {
    const { xsOrsm } = useScreenSize();
    const { meetingLink, copyToClipboard } = useQrCodeModal();
    return (
        <ModalTemplate isOpen={isOpen} onClose={onClose}>
            <ModalContainer>
                <QRCode value={meetingLink} size={xsOrsm ? 250 : 300} />

                <Link
                    style={{ maxWidth: xsOrsm ? "90%" : undefined }}
                    to={meetingLink}
                >
                    <Text
                        sx={{
                            // maxWidth: { xs: "75%", md: undefined },
                            overflowWrap: "break-word",
                        }}
                    >
                        {meetingLink}
                    </Text>
                </Link>

                <CopyToClipboard text={meetingLink}>
                    <Fab onClick={copyToClipboard} color="info">
                        <ContentCopy />
                    </Fab>
                </CopyToClipboard>
            </ModalContainer>
        </ModalTemplate>
    );
};

export default QrCodeModal;
