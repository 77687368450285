import { Box, Button, Stack, useTheme } from "@mui/material";
import React from "react";
import Text from "../components/Text";
import Spacing from "../components/Spacing";
import FormInput from "../components/inputs/FormInput";
import CheckBoxInput from "../components/inputs/CheckBoxInput";
import useLoginPage from "../hooks/pages/useLoginPage";
import { TextSize } from "../components/utils/TextSize";
import { Spa } from "@mui/icons-material";
import { COLORS } from "../utilities/theme";
import { onEnterKey } from "../utilities/keyboardKey";
import LoginFormCard from "../components/formCards/LoginFormCard";

const LoginPage = () => {
    const theme = useTheme();

    const {} = useLoginPage();

    return (
        <Stack
            flex={1}
            height={"100%"}
            alignItems={"center"}
            justifyContent={"center"}
            py={4}
            px={{ xs: 6, md: undefined }}
        >
            <LoginFormCard />
        </Stack>
    );
};

export default LoginPage;
