import { Button, Chip, IconButton, Stack } from "@mui/material";
import React from "react";
import LauncherHeader from "./LauncherHeader";
import TextInput from "../inputs/TextInput";
import useHostLauncher from "../../hooks/components/useHostLauncher";
import SelectInput from "../inputs/SelectInput";
import { Add, Cancel, Close } from "@mui/icons-material";
import Text from "../Text";
import { TextSize } from "../utils/TextSize";
import { theme } from "../../utilities/theme";
import { onEnterKey } from "../../utilities/keyboardKey";
import LauncherButtons from "./LauncherButtons";

type HostLauncherProps = {
    toJoinMeeting?: () => void;
};

const HostLauncher = ({ toJoinMeeting }: HostLauncherProps) => {
    const {
        user,
        title,
        defaultDeck,
        group,
        decks,
        allGroups,

        setTitle,
        setDefaultDeck,
        setGroup,

        addGroup,
        removeGroup,

        submit,
    } = useHostLauncher();

    return (
        <Stack
            flex={{ xs: 1, md: undefined }}
            width={"100%"}
            spacing={3}
            alignItems={"center"}
            justifyContent={{ xs: "center", md: "flex-start" }}
        >
            <LauncherHeader title={"Start Meeting"} />
            <Stack spacing={{ xs: 2, md: 3 }} width={"100%"}>
                <TextInput
                    fullWidth
                    name="Meeting Title"
                    value={title}
                    setValue={setTitle}
                    onKeyPress={onEnterKey(submit)}
                />
                <SelectInput
                    name="Participant Default Deck"
                    value={defaultDeck}
                    setValue={setDefaultDeck}
                    fullWidth
                    options={decks.map((deck) => ({
                        key: deck.id,
                        value: deck.id,
                        placeHolder: deck.description,
                    }))}
                />

                <Stack spacing={1} width={"100%"}>
                    <TextInput
                        name="New Group"
                        value={group}
                        setValue={setGroup}
                        fullWidth
                        endIcon={
                            <IconButton
                                sx={{ pr: { xs: 0, md: undefined } }}
                                onClick={addGroup}
                            >
                                <Add color="info" />
                            </IconButton>
                        }
                        onKeyPress={onEnterKey(() => addGroup())}
                    />
                    <Stack direction={"row"} flexDirection={"row"} spacing={1}>
                        {allGroups.map((group) => (
                            <Chip
                                key={group}
                                label={group}
                                color="default"
                                deleteIcon={
                                    <Cancel
                                        style={{
                                            color: theme.palette.info.main,
                                        }}
                                    />
                                }
                                onDelete={() => removeGroup(group)}
                            />
                        ))}
                    </Stack>
                </Stack>
            </Stack>
            <LauncherButtons
                leftText={user.scrumMaster ? `Join Meeting` : undefined}
                onLeft={user.scrumMaster ? toJoinMeeting : undefined}
                submitText="Start"
                clearText="Clear"
                onSubmit={submit}
                onClear={() => setTitle("")}
            />
        </Stack>
    );
};

export default HostLauncher;
