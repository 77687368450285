import { Box, Stack } from "@mui/material";
import LogoImage from "../images/WhiteLogo.png";
import React from "react";

type LogoProps = {
    size?: number;
};

const LogoWidth = 35;

const Logo = ({ size = 5 }: LogoProps) => {
    return (
        <Stack
            alignItems={"center"}
            justifyContent={"center"}
            width={LogoWidth * size}
            height={LogoWidth * size}
            maxHeight={24 * 1.5}
        >
            <img
                style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "contain",
                }}
                src={LogoImage}
                alt="Resource Pro"
            />
        </Stack>
    );
};

export default Logo;
