import React from "react";
import IParticipant from "../../api/IParticipant";
import { DeckIds } from "../../api/IDeck";
import ParticipantCard from "./ParticipantCard";
import { Stack } from "@mui/material";
import Text from "../Text";
import { TextSize } from "../utils/TextSize";
import useCardSize from "../../hooks/utils/useCardSize";
import useScreenSize from "../../hooks/utils/useScreenSize";

type FavoriteModalCardProps = {
    title: string;
    value: string;
    people?: string[];
    deckId?: string;
};

const FavoriteModalCard = ({
    title,
    value,
    people = [],
    deckId,
}: FavoriteModalCardProps) => {
    const pt: IParticipant = {
        deckId: deckId ?? DeckIds.Diamonds,
        group: "",
        name: title,
        selectedPoker: {
            id: "",
            originalPokerValue: value,
            participantName: "",
            pokerId: "",
            pokerValue: value,
        },
        userId: "",
        isPickedPoker: true,
    };
    const { xsOrsm } = useScreenSize();
    const overide = xsOrsm ? 0.55 : undefined;
    const [maxWidth, cardHeight] = useCardSize(overide);
    return (
        <Stack alignItems={"center"} justifyContent={"center"}>
            <ParticipantCard
                overideSize={overide}
                participant={pt}
                fullName
                isShown
            />
            <Stack
                maxHeight={(cardHeight / 3) * 2}
                width={"100%"}
                maxWidth={maxWidth}
                sx={{ overflowY: "auto" }}
                alignItems={"center"}
                spacing={{ xs: 1.5, md: 2 }}
            >
                {people.map((person) => (
                    <Text
                        sx={{
                            width: "90%",
                            overflowWrap: "break-word",
                            textAlign: "center",
                        }}
                        key={person}
                        size={TextSize.Medium}
                    >
                        {person}
                    </Text>
                ))}
            </Stack>
        </Stack>
    );
};

export default FavoriteModalCard;
