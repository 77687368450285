import { Button, Stack, useTheme } from "@mui/material";
import { ReactNode } from "react";
import Text from "../Text";
import { TextSize } from "../utils/TextSize";
import { Link } from "react-router-dom";

type HeaderTabProps = {
    text: string;
    to: string;
    bold?: boolean;
    onPress?: () => void;
    icon?: ReactNode;
};

const HeaderTab = ({ text, to, bold, onPress, icon }: HeaderTabProps) => {
    const theme = useTheme();
    return (
        <Link to={to}>
            <Button onClick={onPress} variant="text">
                <Stack
                    spacing={1}
                    direction={"row"}
                    flexDirection={"row"}
                    alignItems={"center"}
                >
                    <Text
                        bold={bold}
                        color={theme.palette.primary.contrastText}
                        size={TextSize.Medium}
                    >
                        {text}
                    </Text>
                    {icon}
                </Stack>
            </Button>
        </Link>
    );
};

export default HeaderTab;
