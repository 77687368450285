import React, { useState } from "react";
import axios from "axios";
import useIsOpen from "../utils/useIsOpen";
import { useUserStore } from "../../stores/useUserStore";
import useErrorState from "../utils/useErrorState";

export type UseAxiosProps = {
    error: string;
    setError: (v: string) => boolean;
};

const useAxios = (params?: UseAxiosProps) => {
    const user = useUserStore((state) => state.user);
    const [loading, startLoad, endLoad] = useIsOpen();
    const { error, setError, clearError } = useErrorState();

    const get = async <T,>(
        url: string,
        bearerToken?: string
    ): Promise<T | undefined> => {
        const token =
            bearerToken || user.refreshToken.length > 0
                ? `Bearer ${bearerToken ?? user.refreshToken}`
                : undefined;
        try {
            startLoad();
            let response = await axios.get<T>(url, {
                headers: {
                    Authorization: token,
                    Accept: '*/*'
                },
            });
            endLoad();
            return response.data;
        } catch (err: any) {
            params ? params.setError(err.message) : setError(err.message);
            endLoad();
            return undefined;
        }
    };

    const post = async <T,>(
        url: string,
        data?: any
    ): Promise<T | undefined> => {
        try {
            startLoad();
            let response = await axios.post(url, data, {
                headers: {
                    Authorization:
                        user.refreshToken.length > 0
                            ? `Bearer ${user.refreshToken}`
                            : undefined,
                    Accept: '*/*'
                },
            });
            endLoad();
            return response.data;
        } catch (err: any) {
            console.log(err)
            endLoad();
            params ? params.setError(err.message) : setError(err.message);
            return undefined;
        }
    };

    const put = async <T,>(url: string, data?: any): Promise<T | undefined> => {
        try {
            startLoad();
            let response = await axios.put(url, data, {
                headers: {
                    Authorization:
                        user.refreshToken.length > 0
                            ? `Bearer ${user.refreshToken}`
                            : undefined,
                },
            });
            endLoad();
            return response.data;
        } catch (err: any) {
            endLoad();
            params ? params.setError(err.message) : setError(err.message);
            return undefined;
        }
    };

    const del = async <T,>(url: string) => {
        try {
            startLoad();
            let response = await axios.delete(url, {
                headers: {
                    Authorization:
                        user.refreshToken.length > 0
                            ? `Bearer ${user.refreshToken}`
                            : undefined,
                },
            });
            endLoad();
            return response.data;
        } catch (err: any) {
            endLoad();
            params ? params.setError(err.message) : setError(err.message);
            return undefined;
        }
    };

    return {
        error,
        loading,

        get,
        post,
        put,
        del,
        clearError,
        setError,
    };
};

export default useAxios;
