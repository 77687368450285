import React from "react";
import { useForm } from "react-hook-form";
import { useMeetingStore } from "../../stores/useMeetingStore";
import { useTaskFormStore } from "../../stores/useTaskFormStore";
import useAxios from "../api/useAxios";
import useShowError from "../utils/useShowError";
import { useShowFormError } from "../utils/useShowFormError";
import CONSTANTS from "../../contracts/Constants";
import IMeeting from "../../api/IMeeting";
import { toString } from "../../utilities/stringify";

type SetupJiraFormState = {
    email: string;
    token: string;
};

const useSetupJiraForm = () => {
    const meeting = useMeetingStore((state) => state.meeting);
    const setMeeting = useMeetingStore((state) => state.setMeeting);
    const setOpen = useTaskFormStore((state) => state.setOpen);
    // const setForm = useTaskFormStore(state => state.setForm)

    const { error, loading, setError, clearError, post } = useAxios();
    useShowError(error, clearError);

    const {
        control,
        formState: { errors, submitCount },
        clearErrors,
        handleSubmit,
        reset,
    } = useForm<SetupJiraFormState>({
        defaultValues: {
            email: CONSTANTS.Jira.jiraEmail,
            token: CONSTANTS.Jira.jiraToken,
        },
    });
    useShowFormError(errors, submitCount, clearErrors);

    const close = () => {
        reset();
        setOpen(false);
    };

    const submit = handleSubmit(async (data) => {
        if (!meeting) return setError(`Missing Meeting`);

        let res = await post<IMeeting>(
            `/api/meeting/SetupJiraAuth`,
            {
                ...data,
                meetingId: meeting.id,
            }
        );

        if (res) setMeeting(res);

        close();
    });

    return {
        control,
        loading,
        submit,
        close,
    };
};

export default useSetupJiraForm;
