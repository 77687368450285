import { Stack } from "@mui/material";
import React from "react";
import SquareIconButton from "../SquareIconButton";
import Text from "../Text";
import { Assessment, Menu, Window } from "@mui/icons-material";
import MenuInput from "../inputs/MenuInput";
import { useMeetingStore } from "../../stores/useMeetingStore";

type ParticipantViewerHeaderProps = {
    filter: string;
    setFilter: (v: string) => void;
    cardActive?: boolean;
    onMenuClick?: () => void;
    onCardClick?: () => void;
    onDiscuss?: (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};

const ParticipantViewerHeader = ({
    filter,
    cardActive,
    setFilter,
    onMenuClick,
    onCardClick,
    onDiscuss,
}: ParticipantViewerHeaderProps) => {
    const meeting = useMeetingStore((state) => state.meeting);
    return (
        <Stack
            width={"100%"}
            direction={"row"}
            flexDirection={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
        >
            <Stack
                direction={"row"}
                flexDirection={"row"}
                alignItems={"center"}
            >
                {meeting && (
                    <MenuInput
                        title={"Filter by"}
                        includeNone
                        value={filter}
                        onItem={(v) => setFilter(v.value)}
                        options={meeting.groups.map((group) => ({
                            key: group,
                            value: group,
                            placeHolder: group,
                        }))}
                    />
                )}
            </Stack>
            <Stack
                direction={"row"}
                flexDirection={"row"}
                alignItems={"center"}
                spacing={1}
            >
                {onDiscuss && (
                    <SquareIconButton active onClick={onDiscuss}>
                        <Assessment />
                    </SquareIconButton>
                )}
                <SquareIconButton
                    borderActive={cardActive}
                    onClick={onCardClick}
                >
                    <Window />
                </SquareIconButton>
                <SquareIconButton
                    borderActive={cardActive === false}
                    onClick={onMenuClick}
                >
                    <Menu />
                </SquareIconButton>
            </Stack>
        </Stack>
    );
};

export default ParticipantViewerHeader;
