import { Stack, SxProps, Theme } from "@mui/material";
import React from "react";
import Text from "../Text";
import IParticipant from "../../api/IParticipant";
import { TextSize } from "../utils/TextSize";
import { COLORS, theme } from "../../utilities/theme";
import useScreenSize from "../../hooks/utils/useScreenSize";

type ParticipantListItemProps = {
    participant: IParticipant;
    isLast?: boolean;
    isVoting?: boolean;
    isShown?: boolean;
    onClick?: () => void;
};

const ParticipantListItem = ({
    participant,
    isLast,
    isVoting,
    isShown,
    onClick,
}: ParticipantListItemProps) => {
    const { xsOrsm } = useScreenSize();

    const isSelected =
        participant.selectedPoker !== undefined &&
        participant.selectedPoker !== null;
    const isGreen = isVoting && isSelected;

    const borderSx: SxProps<Theme> = !isLast
        ? {
              borderWidth: 0,
              borderBottomWidth: 2,
              borderStyle: "solid",
              borderColor: COLORS.grey,
          }
        : {};

    const itemBorder: SxProps<Theme> = xsOrsm
        ? {
              borderWidth: 0,
              borderRightWidth: 2,
              borderStyle: "solid",
              borderColor: COLORS.grey,
          }
        : {};

    return (
        <Stack
            pl={2}
            py={1}
            direction={"row"}
            alignItems={"center"}
            onClick={onClick}
            sx={{
                ...borderSx,
                cursor: onClick ? "pointer" : undefined,
                ":hover": {
                    bgcolor: onClick ? COLORS.lightGrey : undefined,
                },
            }}
            spacing={2}
        >
            <Stack
                justifyContent={"center"}
                sx={itemBorder}
                width={"25%"}
                height={"100%"}
            >
                <Text size={TextSize.Medium}>{participant.group}</Text>
            </Stack>
            <Stack
                justifyContent={"center"}
                sx={itemBorder}
                width={"45%"}
                height={"100%"}
            >
                <Text size={TextSize.Medium}>{participant.name}</Text>
            </Stack>
            <Stack justifyContent={"center"} width={"20%"} height={"100%"}>
                <Text
                    size={TextSize.Medium}
                    color={
                        isGreen && !isShown ? theme.palette.success.main : ""
                    }
                    bold={isGreen}
                >
                    {isVoting
                        ? isSelected
                            ? isShown
                                ? participant.selectedPoker?.pokerValue
                                : "Ready"
                            : "Voting..."
                        : ""}
                </Text>
            </Stack>
        </Stack>
    );
};

export default ParticipantListItem;
