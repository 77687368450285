import { Stack, Button } from "@mui/material";
import React from "react";
import useOrientation from "../../hooks/utils/useOrientation";

type LauncherButtonsProps = {
    leftText?: string;
    onLeft?: () => void;

    onClear?: () => void;
    clearText?: string;

    onSubmit?: () => void;
    submitText: string;
};

const LauncherButtons = ({
    leftText,
    onLeft,

    onClear,
    clearText,
    onSubmit,
    submitText,
}: LauncherButtonsProps) => {
    return (
        <Stack
            // pt={topSpace ? 2 : undefined}
            width={"100%"}
            direction={{ xs: "column-reverse", md: "row" }}
            flexDirection={{ xs: "column-reverse", md: "row" }}
            spacing={2}
            alignItems={{ md: "center" }}
            justifyContent={{ md: "space-between" }}
        >
            <Button
                style={{ opacity: leftText ? 1 : 0 }}
                variant="outlined"
                onClick={onLeft}
            >
                {leftText}
            </Button>

            <Stack
                spacing={2}
                direction={"row"}
                justifyContent={{ xs: "space-between", md: undefined }}
            >
                {clearText && (
                    <Button onClick={onClear} color="info" variant="outlined">
                        {clearText}
                    </Button>
                )}
                <Button onClick={onSubmit} color="info" variant="contained">
                    {submitText}
                </Button>
            </Stack>
        </Stack>
    );
};

export default LauncherButtons;
