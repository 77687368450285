import React, { useEffect, useState } from "react";
import IMeeting from "../../api/IMeeting";
import useAxios, { UseAxiosProps } from "./useAxios";
import { useUserStore } from "../../stores/useUserStore";
import { toString } from "../../utilities/stringify";
import useOnNewMeeting from "./useOnNewMeeting";

type UseAllMeetingsProps = {
    axiosProps?: UseAxiosProps;
};

const useAllMeetings = (props?: UseAllMeetingsProps) => {
    const user = useUserStore((state) => state.user);
    const [meetings, setMeetings] = useState<IMeeting[]>([]);

    const { loading, error, clearError, get } = useAxios(props?.axiosProps);

    useEffect(() => {
        if (user.refreshToken.length > 0) getMeeting();
    }, [user.refreshToken.length]);

    useOnNewMeeting({ onMeetingChange: () => getMeeting() });

    const getMeeting = async () => {
        let res = await get<IMeeting[]>(`/api/meeting/GetMeetings`);
        if (!res) return;
        setMeetings(res);
    };

    return {
        meetings,
        loading,
        error,
        clearError,
    };
};

export default useAllMeetings;
