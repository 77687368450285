import React, { useCallback } from "react";
import { toString } from "../../utilities/stringify";
import CONSTANTS from "../../contracts/Constants";
import useCardSize from "./useCardSize";

const useToRow = <T,>(
    original: T[],
    config:
        | { itemsInRow: number; width?: number }
        | { width: number; itemsInRow?: number },
    deps: (boolean | string | number)[] = []
) => {
    const [cardSize, cardHeight] = useCardSize();

    const calcMaxPerRow = useCallback(
        (width: number) => {
            const singleSpace = 12 * 2;

            let leftover = width - cardSize;
            let count = 1;

            while (leftover > 0) {
                leftover -= cardSize + singleSpace;

                if (leftover > 0) count++;
            }

            return count;
        },
        [cardSize, ...deps]
    );

    const balanceRows = useCallback(
        (rows: T[][], items: T[]) => {
            if (rows.length === 0) return rows;

            let allRows = rows.length;

            if (items.length % allRows !== 0) return rows;

            const balancedLength = items.length / allRows;
            let newRows: T[][] = [];
            let newRow: T[] = [];

            for (let item of items) {
                if (newRow.length !== balancedLength) {
                    newRow.push(item);
                } else {
                    newRows.push(newRow);
                    newRow = [];
                    newRow.push(item);
                }
            }

            if (newRow.length !== 0) newRows.push(newRow);

            return newRows;
        },
        [...deps]
    );

    const toRows = useCallback(
        (items: T[]) => {
            const rows: T[][] = [];
            let row = [];
            const itemsInRow = config.itemsInRow
                ? config.itemsInRow
                : calcMaxPerRow(config.width!);

            for (let i = 0; i < items.length; i++) {
                const value = items[i];
                if (i === 0) {
                    row.push(value);
                    continue;
                }
                if (i % itemsInRow === 0) {
                    rows.push(row);
                    row = [];
                }
                row.push(value);
            }

            if (row.length !== 0) rows.push(row);

            return balanceRows(rows, items);
        },
        [toString(config), calcMaxPerRow, ...deps]
    );
    return toRows(original);
};

export default useToRow;
