import { ArrowBack, Send, Settings } from "@mui/icons-material";
import { IconButton, Paper, Stack, useTheme } from "@mui/material";
import React, { useCallback } from "react";
import Text from "../Text";
import { COLORS } from "../../utilities/theme";
import { TextSize } from "../utils/TextSize";
import { useNavigate } from "react-router-dom";
import { useMeetingStore } from "../../stores/useMeetingStore";
import SquareIconButton from "../SquareIconButton";
import { useTaskFormStore } from "../../stores/useTaskFormStore";
import { useActiveTopicStore } from "../../stores/useActiveTopicStore";
import useLeaveMeetingPage from "../../hooks/utils/useLeaveMeetingPage";

type HostPageHeaderProps = {
    title?: string;
    onSettingClick: () => void;
    onSendClick: () => void;
};

const HostPageHeader = ({
    title,
    onSettingClick,
    onSendClick,
}: HostPageHeaderProps) => {
    const meeting = useMeetingStore((state) => state.meeting);
    const theme = useTheme();

    const onArrowBack = useLeaveMeetingPage();

    return (
        <Stack width={"100%"}>
            <Paper elevation={2}>
                <Stack
                    bgcolor={theme.palette.background.default}
                    padding={2}
                    direction={"row"}
                    flexDirection={"row"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                >
                    <Stack
                        spacing={2}
                        direction={"row"}
                        flexDirection={"row"}
                        alignItems={"center"}
                        justifyContent={"space-between"}
                    >
                        <IconButton onClick={onArrowBack}>
                            <ArrowBack />
                        </IconButton>
                        <IconButton
                            sx={{
                                borderRadius: 1,
                                opacity: 0,
                                bgcolor: COLORS.grey,
                                cursor: "default",
                            }}
                        >
                            <Send />
                        </IconButton>
                    </Stack>

                    <Stack alignItems={"center"} justifyContent={"center"}>
                        <Text bold>Voting On: {title}</Text>
                        <Text size={TextSize.Small}>{meeting?.topic}</Text>
                    </Stack>

                    <Stack
                        spacing={2}
                        direction={"row"}
                        flexDirection={"row"}
                        alignItems={"center"}
                        justifyContent={"space-between"}
                    >
                        <SquareIconButton onClick={onSettingClick}>
                            <Settings />
                        </SquareIconButton>
                        <SquareIconButton onClick={onSendClick}>
                            <Send />
                        </SquareIconButton>
                    </Stack>
                </Stack>
            </Paper>
        </Stack>
    );
};

export default HostPageHeader;
