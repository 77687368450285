import { Box, Paper, Stack, useTheme } from "@mui/material";
import React from "react";
import ParticipantLauncher from "./ParticipantLauncher";
import HostLauncher from "./HostLauncher";

type HomeLauncherProps = {
    isJoining: boolean;
    toggleJoining: () => void;
};

const HomeLauncher = ({ isJoining, toggleJoining }: HomeLauncherProps) => {
    const theme = useTheme();
    return (
        <Stack
            flex={1}
            // direction={"row"}
            // flexDirection={"row"}
            alignItems={"center"}
            justifyContent={"center"}
            p={2}
        >
            {isJoining ? (
                <ParticipantLauncher toHostMeeting={toggleJoining} />
            ) : (
                <HostLauncher toJoinMeeting={toggleJoining} />
            )}
        </Stack>
    );
};

export default HomeLauncher;
