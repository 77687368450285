import { Stack } from "@mui/material";
import React, { PropsWithChildren } from "react";

type ModalContainerProps = {};

const ModalContainer = ({
    children,
}: PropsWithChildren<ModalContainerProps>) => {
    return (
        <Stack
            p={2}
            py={4}
            width={{ xs: "80vw", md: "50vw" }}
            justifyContent={"center"}
            alignItems={"center"}
            spacing={2}
        >
            {children}
        </Stack>
    );
};

export default ModalContainer;
