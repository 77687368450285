import React, { useCallback, useRef } from "react";
import stringify from "../../utilities/stringify";
import keyboardKey from "../../utilities/keyboardKey";

type UseFocusInputsProps = {
    inputs: string[];
};

export type AllElements =
    | HTMLInputElement
    | HTMLSelectElement
    | HTMLDivElement
    | null;

const useFocusInputs = ({ inputs }: UseFocusInputsProps) => {
    const inputRefs = useRef<AllElements[]>(inputs.map((v) => null));

    const isValueValidInput = (v: string) => inputs.includes(v);

    const getIndex = (v: string) => inputs.findIndex((f) => f === v);

    const getRef = (v: string) => {
        if (!isValueValidInput(v)) return null;
        const index = getIndex(v);

        const ref = inputRefs.current[index];
        return ref;
    };

    const unFocusAll = () => {
        for (let ref of inputRefs.current) if (ref) ref.blur();
    };

    const setInputRef = useCallback(
        (v: string) => (elem: AllElements) => {
            if (!isValueValidInput(v)) return;
            const index = getIndex(v);

            inputRefs.current[index] = elem;
        },
        [stringify(inputs)]
    );

    const focusInputRef = (v: string) => {
        const ref = getRef(v);
        if (!ref) return unFocusAll();

        ref.focus();
    };

    const onEnterFocus = (v: string) =>
        keyboardKey.onEnterKey(() => focusInputRef(v));

    return {
        setInputRef,
        focusInputRef,
        onEnterFocus,
    };
};

export default useFocusInputs;
