import IPoker from "./IPoker";

export default interface IDeck {
    id: string;
    description: string;
    pokers: IPoker[];
}

export enum DeckNames {
    Blue = "Blue",
    Custom = "Custom",
    Lovely = "SVG Lovely",
    Hearts = "SVG Hearts",
    Diamonds = "SVG Diamonds",
    Spades = "SVG Spades",
    Clubs = "SVG Clubs",
}

export enum DeckIds {
    Blue = "6fdf6ffc-ed77-94fa-407e-a7b86ed9e592",
    Custom = "6fdf6ffc-ed77-94fa-407e-a7b86ed9e593",
    Lovely = "6fdf6ffc-ed77-94fa-407e-a7b86ed9e591",
    Hearts = "6fdf6ffc-ed77-94fa-407e-a7b86ed9e594",
    Diamonds = "6fdf6ffc-ed77-94fa-407e-a7b86ed9e596",
    Spades = "6fdf6ffc-ed77-94fa-407e-a7b86ed9e595",
    Clubs = "6fdf6ffc-ed77-94fa-407e-a7b86ed9e597",
}
