import { Container, Paper, Stack } from "@mui/material";
import React, { PropsWithChildren } from "react";
import useScreenSize from "../../hooks/utils/useScreenSize";

const PageCard = ({ children }: PropsWithChildren<{}>) => {
    const { xsOrsm } = useScreenSize();
    return (
        <Stack
            flex={1}
            alignItems={"center"}
            justifyContent={"center"}
            py={4}
            px={{ xs: 4, md: undefined }}
        >
            <Container
                sx={{
                    px: { xs: 0, md: undefined },
                    flex: { xs: 1, md: 0 },
                    display: { xs: "flex", md: "block" },
                }}
                maxWidth={xsOrsm ? false : "lg"}
            >
                <Stack flex={{ xs: 1, md: undefined }} width={"100%"}>
                    <Paper
                        sx={{
                            width: "100%",
                            display: "flex",
                            flex: { xs: 1, md: undefined },
                        }}
                        elevation={2}
                    >
                        <Stack
                            flex={{ xs: 1, md: undefined }}
                            flexDirection={"row"}
                            direction={"row"}
                            alignItems={"center"}
                            justifyContent={"center"}
                            py={{ xs: 2, md: 4 }}
                        >
                            {children}
                        </Stack>
                    </Paper>
                </Stack>
            </Container>
        </Stack>
    );
};

export default PageCard;
