import React, { useEffect, useState } from "react";
import useScreenSize from "./useScreenSize";

type OrientationType = "portrait" | "landscape";

const useOrientation = () => {
    const [orientation, setOrientation] = useState<OrientationType>("portrait");

    const { xsOrsm } = useScreenSize();

    useEffect(() => {
        if (xsOrsm) {
            let angle = window.screen.orientation.angle;

            if ((angle === 90 || angle === -90) && orientation !== "landscape")
                setOrientation("landscape");
            if (angle !== 90 && angle !== -90 && orientation !== "portrait")
                setOrientation("portrait");
        } else {
            if (orientation !== "portrait") setOrientation("portrait");
        }
    }, [xsOrsm]);

    const isLandscape = orientation === "landscape";
    const isPortrait = orientation === "portrait";

    return { orientation, isLandscape, isPortrait };
};

export default useOrientation;
