import React, { useEffect, useState } from "react";
import useAxios, { UseAxiosProps } from "./useAxios";
import IDeck from "../../api/IDeck";
import { useUserStore } from "../../stores/useUserStore";

type UseAllDecksProps = {
    axiosProps?: UseAxiosProps;
};

const useAllDecks = (props?: UseAllDecksProps) => {
    const user = useUserStore((state) => state.user);
    const [decks, setDecks] = useState<IDeck[]>([]);
    const { loading, error, setError, clearError, get } = useAxios(
        props?.axiosProps
    );

    useEffect(() => {
        if (user.refreshToken.length > 0) getAllDecks();
    }, [user.refreshToken]);

    const getAllDecks = async () => {
        let res = await get<IDeck[]>(`/api/deck/GetPokerDecks`);
        if (res) setDecks(res);
    };

    return {
        loading,
        error,
        decks,

        setError,
        clearError,
    };
};

export default useAllDecks;
