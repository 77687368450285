import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import IMeeting from "../../api/IMeeting";
import { useMeetingStore } from "../../stores/useMeetingStore";
import { ShownTaskForm, useTaskFormStore } from "../../stores/useTaskFormStore";
import useAxios from "../api/useAxios";
import useShowError from "../utils/useShowError";
import { useShowFormError } from "../utils/useShowFormError";
import { useActiveTopicStore } from "../../stores/useActiveTopicStore";
import { toString } from "../../utilities/stringify";

type UpdateTaskFormState = {
    title: string;
    desc: string;
    points: string;
};

const useUpdateTaskForm = () => {
    const meeting = useMeetingStore((state) => state.meeting);
    const { subTopic, task, setActive } = useActiveTopicStore(
        ({ activeSubTopic, activeTask, setActive }) => ({
            subTopic: activeSubTopic,
            task: activeTask,
            setActive,
        })
    );
    const { setOpen, setForm } = useTaskFormStore(({ setOpen, setForm }) => ({
        setOpen,
        setForm,
    }));

    const updating = task ?? subTopic;
    const parent = subTopic;
    const isChild = task && subTopic ? true : false;

    const { error, loading, setError, clearError, post ,get,put} = useAxios();
    useShowError(error, clearError);

    const {
        control,
        formState: { errors, submitCount },
        watch,
        clearErrors,
        handleSubmit,
        getValues,
        setValue,
        reset,
    } = useForm<UpdateTaskFormState>({
        defaultValues: {
            title: updating?.topic ?? "",
            desc: updating?.desc ?? "",
            points: updating?.points?.toString() ?? "",
        },
    });
    useShowFormError(errors, submitCount, clearErrors);

    const title = watch("title");
    const desc = watch("desc");
    const points = watch("points");

    useEffect(() => {
        if (!subTopic) close();
    }, []);

    useEffect(() => {
        preFill();
    }, [toString(updating)]);

    const preFill = () => {
        if (!updating) return;

        const values = getValues();

        if (values.title !== updating.topic) setValue("title", updating.topic);
        if (values.desc !== updating.desc) setValue("desc", updating.desc);
        if (values.points !== updating.points?.toString())
            setValue("points", updating.points?.toString() ?? "");
    };

    const getSolidButtonState = () => {
        const hasBeenChanged = () => {
            const values = getValues();

            if (!updating) return undefined;
            if (values.title !== updating.topic) return true;
            if (values.desc !== updating.desc) return true;
            if (
                (updating.points === null || updating.points === undefined) &&
                values.points.length === 0
            )
                return false;
            if (values.points !== updating.points?.toString()) return true;

            return false;
        };

        const existsInJira = () => {
            if (!updating) return false;

            return updating.jiraKey !== undefined && updating.jiraKey !== null;
        };

        const isChanged = hasBeenChanged();
        const jiraIssueExists = existsInJira();

        if (isChanged) return 0;

        if (!jiraIssueExists) return 1;

        return 2;
    };

    const getSolidButtonText = () => {
        const state = getSolidButtonState();
        if (state === 0) return "Save Locally";
        if (state === 1) return "Create Jira Issue";
        return "Update Jira Issue";
    };

    const getSolidButtonOnClick = () => {
        const state = getSolidButtonState();
        if (state === 0) return saveLocal;

        if (state === 1) return () => setForm(ShownTaskForm.JiraCreate);

        return saveJira;
    };

    const close = () => {
        reset();
        setOpen(false);
    };

    const saveLocal = handleSubmit(async (data) => {
        if (!meeting) return setError(`Missing Meeting`);
        if (data.points.length > 0 && Number.isNaN(parseFloat(data.points)))
            return setError(`Invalid points`);
        console.log('adada');
        let res = await post<IMeeting>(`/api/meeting/UpdateSubTopic`, {
            meetingId: meeting.id,
            subTopicId: subTopic!.id,
            taskId: task?.id,
            topic: data.title,
            desc: data.desc,
            points: parseFloat(data.points),
        });

        if (!res) return;

        const newSubTopic = res.subTopics.find((v) => v.id === subTopic?.id);
        const newTask =
            newSubTopic && task
                ? newSubTopic?.tasks.find((v) => v.id === task.id)
                : undefined;

        setActive(newSubTopic, newTask);
    });

    const saveJira = handleSubmit(async (data) => {
        if (!meeting) return setError(`Missing Meeting`);
        if (!updating?.jiraKey)
            return setError(`Topic must allready be in Jira`);
        if (data.points.length > 0 && Number.isNaN(parseFloat(data.points)))
            return setError(`Invalid points`);

        let res = await post<IMeeting>(
            `/api/meeting/UpdateJiraIssue?meetingId=${meeting.id}&issueId=${updating.jiraKey}`,
            {
                topic: data.title,
                desc: data.desc,
                points: parseFloat(data.points),
            }
        );

        setActive(undefined, undefined);

        close();
    });

    return {
        control,
        loading,
        parent,
        isChild,
        getSolidButtonText,
        getSolidButtonOnClick,
        close,
    };
};

export default useUpdateTaskForm;
