import { create } from "zustand";
import IMeeting from "../api/IMeeting";

type MeetingStoreState = {
    meeting: IMeeting | undefined;
};

type MeetingStore = MeetingStoreState & {
    setMeeting: (v?: IMeeting) => void;
    clearMeeting: () => void;
};

export const useMeetingStore = create<MeetingStore>()((set) => ({
    meeting: undefined,
    setMeeting: (v) => set({ meeting: v }),
    clearMeeting: () => set({ meeting: undefined }),
}));
