import { TextField } from "@mui/material";
import React, { ReactNode, useCallback } from "react";
import useScreenSize from "../../hooks/utils/useScreenSize";

export type BaseTextInputProps = {
    name: string;
    placeHolder?: string;
    value: string;
    fullWidth?: boolean;
    setValue: (value: string) => void;
};

type TextInputProps = BaseTextInputProps & {
    endIcon?: ReactNode;
    startIcon?: ReactNode;
    onKeyPress?: (e: React.KeyboardEvent<HTMLDivElement>) => void;
};

const TextInput = ({
    name,
    placeHolder,
    value,
    fullWidth = true,
    startIcon,
    endIcon,
    setValue,
    onKeyPress,
}: TextInputProps) => {
    const { xsOrsm } = useScreenSize();
    const onChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
            setValue(e.currentTarget.value),
        [setValue]
    );
    return (
        <TextField
            fullWidth={fullWidth}
            value={value}
            label={name}
            placeholder={placeHolder ?? name}
            variant="outlined"
            color="primary"
            onChange={onChange}
            onKeyDown={onKeyPress}
            size={xsOrsm ? "small" : "medium"}
            InputProps={{
                endAdornment: endIcon,
                startAdornment: startIcon,
            }}
        />
    );
};

export default TextInput;
