import { Button, Stack, useTheme } from "@mui/material";
import React from "react";
import useSettingsPage from "../hooks/pages/useSettingsPage";
import TextInput from "../components/inputs/TextInput";
import FormInput from "../components/inputs/FormInput";
import CheckBoxInput from "../components/inputs/CheckBoxInput";
import Text from "../components/Text";
import Spacing from "../components/Spacing";
import { TextSize } from "../components/utils/TextSize";
import PokerCard from "../components/cards/PokerCard";
import SelectInput from "../components/inputs/SelectInput";
import { DeckIds } from "../api/IDeck";
import { LoadingButton } from "@mui/lab";
import useScreenSize from "../hooks/utils/useScreenSize";
import PageCard from "../components/cards/PageCard";

const SettingsPage = () => {
    const theme = useTheme();
    const {
        loading,
        control,
        deckOptions,
        deck,
        futureMeetings,

        submit,
        toggleFutureMeetings,
        setDeck,
    } = useSettingsPage();

    const { xsOrsm } = useScreenSize();
    return (
        <PageCard>
            <Stack
                flex={1}
                direction={{ xs: "column-reverse", md: "row" }}
                spacing={{ xs: 3, md: 5 }}
                px={{ xs: 2, md: 4 }}
            >
                <Stack width={{ xs: undefined, md: "75%" }} spacing={3}>
                    <Text
                        size={xsOrsm ? TextSize.Largest : TextSize.Humoungous}
                        bold
                    >
                        Settings
                    </Text>
                    {!xsOrsm && <Spacing vertical={0} />}
                    <FormInput
                        control={control}
                        name="fullName"
                        label="Default Join Meeting Name"
                        rules={{
                            required: "Missing default join meeting name",
                        }}
                    />
                    <FormInput
                        control={control}
                        name="group"
                        label="Default Join Meeting Group"
                        rules={{
                            required: "Missing default join meeting group",
                        }}
                    />
                    <FormInput
                        control={control}
                        name="jiraEmail"
                        label="Default Jira Connection Email"
                        rules={{ required: "Missing default jira email" }}
                    />
                    <FormInput
                        control={control}
                        name="jiraToken"
                        label="Default Jira Connection Api Token"
                        rules={{ required: "Missing default jira api token" }}
                    />
                    <SelectInput
                        includeNone={deck.length === 0}
                        name="Default Join Meeting Deck"
                        value={deck}
                        setValue={(v) => setDeck(v as DeckIds)}
                        options={deckOptions}
                    />
                    <CheckBoxInput
                        checked={futureMeetings}
                        onClick={toggleFutureMeetings}
                        name="Use the current settings for future meetings"
                    />
                    <Stack alignItems={"flex-end"}>
                        <LoadingButton
                            onClick={submit}
                            loading={loading}
                            variant="contained"
                            color="info"
                        >
                            Submit
                        </LoadingButton>
                    </Stack>
                </Stack>
                <Stack
                    width={{ xs: "100%", md: "25%" }}
                    height={"100%"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    direction={{ xs: "row", md: "column" }}
                    spacing={{ xs: 5, md: 3 }}
                >
                    <PokerCard
                        smaller={xsOrsm}
                        deck={
                            deck.length === 0 ? DeckIds.Blue : (deck as DeckIds)
                        }
                    />
                    <PokerCard
                        smaller={xsOrsm}
                        deck={
                            deck.length === 0 ? DeckIds.Blue : (deck as DeckIds)
                        }
                        value="1"
                    />
                </Stack>
            </Stack>
        </PageCard>
    );
};

export default SettingsPage;
