import { Stack } from "@mui/material";
import React from "react";
import useCreateJiraTaskForm from "../../hooks/components/useCreateJiraTaskForm";
import SelectInput from "../inputs/SelectInput";
import CheckBoxInput from "../inputs/CheckBoxInput";
import TaskFormButtons from "./TaskFormButtons";
import SelectJiraProject from "../inputs/SelectJiraProject";
import ISubTopic from "../../api/ISubTopic";
import TaskCard from "../cards/TaskCard";

const CreateJiraTaskForm = () => {
    const {
        parentTask,
        projectId,
        loading,
        projectOptions,
        issueOptions,
        issueType,
        createIssueForChildren,
        hasChildren,
        defaultChildProjectId,
        defaultIssueType,
        individualChildren,

        close,
        setProjectId,
        setIssueType,
        setDefaultChildProjectId,
        setDefaultIssueType,
        toggleCreateIssueForChildren,
        submit,
        toggleIndividualChildren,
        getChildConfig,
    } = useCreateJiraTaskForm();
    return (
        <Stack spacing={2}>
            <SelectJiraProject
                projectName="Choose Jira Project"
                issueName="Choose Jira Issue Type"
                projectPlaceholder="Choose the Jira project to create issue in..."
                issuePlaceholder="Choose the Jira issue type..."
                projectId={projectId}
                issueType={issueType}
                setProjectId={setProjectId}
                setIssueType={setIssueType}
                projectOptions={projectOptions}
                issueOptions={issueOptions}
            />

            {hasChildren && projectId.length > 0 && issueType.length > 0 && (
                <Stack spacing={2}>
                    <CheckBoxInput
                        checked={createIssueForChildren}
                        onClick={toggleCreateIssueForChildren}
                        name="Create issue for child tasks NOT in Jira"
                    />

                    {!individualChildren && createIssueForChildren && (
                        <SelectJiraProject
                            projectName="Choose All Child Issues Project"
                            issueName="Choose All Child Issues Type"
                            projectPlaceholder="Choose the Jira project to create child issues in..."
                            issuePlaceholder="Choose the Jira type to make all child issues..."
                            projectId={defaultChildProjectId}
                            issueType={defaultIssueType}
                            setProjectId={setDefaultChildProjectId}
                            setIssueType={setDefaultIssueType}
                            projectOptions={projectOptions}
                            issueOptions={issueOptions}
                        />
                    )}

                    {createIssueForChildren && (
                        <CheckBoxInput
                            checked={individualChildren}
                            onClick={toggleIndividualChildren}
                            name="Individually choose child project & issue type"
                        />
                    )}

                    {createIssueForChildren &&
                        individualChildren &&
                        parentTask && (
                            <Stack pt={1} spacing={3}>
                                {(parentTask as ISubTopic).tasks.map((task) => {
                                    const {
                                        projectId,
                                        issueType,
                                        setIssueType,
                                        setProjectId,
                                    } = getChildConfig(task);
                                    return (
                                        <Stack spacing={4} key={task.id}>
                                            <Stack
                                                alignItems={"center"}
                                                justifyContent={"center"}
                                            >
                                                <TaskCard
                                                    task={task}
                                                    disabled={{ all: true }}
                                                />
                                            </Stack>
                                            <SelectJiraProject
                                                projectName="Choose Child Issue Project"
                                                issueName="Choose Child Issue Type"
                                                projectPlaceholder="Choose the Jira project to create child issue in..."
                                                issuePlaceholder="Choose the Jira type to make child issue..."
                                                projectId={projectId}
                                                issueType={issueType}
                                                setProjectId={setProjectId}
                                                setIssueType={setIssueType}
                                                projectOptions={projectOptions}
                                                issueOptions={issueOptions}
                                            />
                                        </Stack>
                                    );
                                })}
                            </Stack>
                        )}
                </Stack>
            )}

            <TaskFormButtons
                loading={loading}
                solidClick={submit}
                outlineClick={close}
            />
        </Stack>
    );
};

export default CreateJiraTaskForm;
