import React, { PropsWithChildren, useEffect } from "react";
import { COLORS } from "../utilities/theme";
import { IconButton, useTheme } from "@mui/material";
import { CSSProperties } from "@mui/styled-engine";

type SquareIconButtonProps = {
    borderActive?: boolean;
    active?: boolean;
    onClick?: (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};

const SquareIconButton = ({
    children,
    borderActive,
    active,
    onClick,
}: PropsWithChildren<SquareIconButtonProps>) => {
    const theme = useTheme();
    const borderStyle: CSSProperties = borderActive
        ? {
              borderWidth: 1,
              borderStyle: "solid",
              borderColor: theme.palette.info.main,
          }
        : {};
    const bgcolor = active
        ? theme.palette.info.main
        : borderActive
        ? theme.palette.background.default
        : COLORS.grey;

    return (
        <IconButton
            onClick={onClick}
            sx={{
                borderRadius: 1,
                bgcolor,
                color: active
                    ? theme.palette.background.paper
                    : theme.palette.info.main,
                "&:focus": {
                    bgcolor,
                },
                ":hover": {
                    bgcolor,
                },
                ...borderStyle,
            }}
        >
            {children}
        </IconButton>
    );
};

export default SquareIconButton;
