import { createTheme } from "@mui/material";

// When using TypeScript 4.x and above
import type {} from "@mui/lab/themeAugmentation";
// When using TypeScript 3.x and below
// import "@mui/lab/themeAugmentation";

export const COLORS = {
    lightBlue: "#497EDC",
    navyBlue: "#021F62",
    teal: "#00BBC2",
    green: "#7EBD31",
    black: "#0B0B0B",
    darkGrey: "#959597",
    grey: "#DDDDDF80",
    lightGrey: "#DDDDDF20",
    offWhite: "#F3F3F1",
    white: "#FFFFFF",
    purple: "#A14FEE",
    pink: "#FB4671",
    yellow: "#E9FF6E",
};

export const theme = createTheme({
    typography: {
        allVariants: {
            fontFamily: "satoshi-light",
        },
        button: {
            fontFamily: "satoshi-bold",
        },
    },
    palette: {
        primary: {
            main: COLORS.lightBlue,
            contrastText: COLORS.white,
        },
        secondary: {
            main: COLORS.black,
            contrastText: COLORS.white,
        },
        info: {
            main: COLORS.navyBlue,
            contrastText: COLORS.white,
        },
        background: {
            paper: COLORS.white,
            default: COLORS.offWhite,
        },
        error: {
            main: COLORS.pink,
            contrastText: COLORS.white,
        },
        warning: {
            main: COLORS.yellow,
            contrastText: COLORS.black,
        },
        success: {
            main: COLORS.green,
            contrastText: COLORS.white,
        },
    },
});
