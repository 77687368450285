import { LoadingButton } from "@mui/lab";
import { Stack, Button } from "@mui/material";
import React from "react";
import FormInput from "../inputs/FormInput";
import { TextSize } from "../utils/TextSize";
import Text from "../Text";
import useAddLocalTaskForm from "../../hooks/components/useAddLocalTaskForm";
import TaskFormButtons from "./TaskFormButtons";
import ChildFormHeader from "./ChildFormHeader";

const AddLocalTaskForm = () => {
    const { control, loading, isChild, parent, close, submit } =
        useAddLocalTaskForm();

    return (
        <Stack flexGrow={1} spacing={2}>
            <ChildFormHeader isChild={isChild} parent={parent} />

            <FormInput
                control={control}
                name="title"
                label="Task Title"
                placeHolder="Enter a title for the task..."
                rules={{
                    required: "Missing Task Title",
                }}
            />
            <FormInput
                control={control}
                name="desc"
                label="Task Description"
                placeHolder="Enter the description for the task..."
                rules={{
                    required: "Missing Task Description",
                }}
                multiline
            />
            <TaskFormButtons
                outlineText="Cancel"
                solidText="Save"
                outlineClick={close}
                solidClick={submit}
                loading={loading}
            />
        </Stack>
    );
};

export default AddLocalTaskForm;
