import { Stack, useTheme } from "@mui/material";
import React from "react";
import Text from "../Text";
import { TextSize } from "../utils/TextSize";

type LauncherHeaderProps = {
    title: string;
};

const LauncherHeader = ({ title }: LauncherHeaderProps) => {
    const theme = useTheme();
    return (
        <Stack>
            <Text color={theme.palette.info.main} size={TextSize.Largest} bold>
                {title}
            </Text>
        </Stack>
    );
};

export default LauncherHeader;
