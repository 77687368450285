import IMeeting from "../../api/IMeeting";
import CONSTANTS from "../../contracts/Constants";
import { useMeetingStore } from "../../stores/useMeetingStore";
import stringify, { toString } from "../../utilities/stringify";
import useHubConnection from "./useHubConnection";

type UseMeetingChangeSocket = {
    meetingId: string;
};

const useMeetingChangeSocket = ({ meetingId }: UseMeetingChangeSocket) => {
    const setMeeting = useMeetingStore((state) => state.setMeeting);

    useHubConnection(
        CONSTANTS.SocketUrl + `?meetingId=${meetingId}`,
        (hubConnection) => {
            hubConnection.on("MeetingChanged", onMeetingChange);
        }
    );

    const onMeetingChange = (changedId: string, newMeeting: IMeeting) => {
        if (meetingId !== changedId) return;
        setMeeting(newMeeting);
    };

    return;
};

export default useMeetingChangeSocket;
