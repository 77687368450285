import React, { useEffect, useState } from "react";
import useAllDecks from "../api/useAllDecks";
import useAxios from "../api/useAxios";
import { produce } from "immer";
import useErrorState from "../utils/useErrorState";
import useShowError from "../utils/useShowError";
import { useUserStore } from "../../stores/useUserStore";
import IMeeting from "../../api/IMeeting";
import { useNavigate } from "react-router-dom";

const useHostLauncher = () => {
    const navigate = useNavigate();

    const user = useUserStore((state) => state.user);

    const [title, setTitle] = useState("");
    const [defaultDeck, setDefaultDeck] = useState("");
    const [group, setGroup] = useState("");
    const [allGroups, setAllGroups] = useState<string[]>([
        "Dev Team",
        "QA Team",
    ]);

    const { error, setError, clear } = useErrorState();
    const { post } = useAxios({ error, setError });
    const { decks } = useAllDecks({ axiosProps: { error, setError } });
    useShowError(error, clear);

    useEffect(() => {
        if (decks.length > 0) setDefaultDeck(decks[0].id);
    }, [decks.length]);

    const addGroup = () => {
        setAllGroups((prev) =>
            produce(prev, (draft) => {
                draft.push(group);
            })
        );
        setGroup("");
    };

    const removeGroup = (group: string) => {
        setAllGroups((prev) =>
            produce(prev, (draft) => {
                return draft.filter((val) => val !== group);
            })
        );
    };

    const isValid = () => {
        if (title.length === 0) return setError(`Missing Meeting Title`);
        if (defaultDeck.length === 0) return setError(`Missing Default Deck`);
        return true;
    };

    const submit = async () => {
        if (!isValid()) return;

        let res = await post<IMeeting>(`/api/meeting/start`, {
            topic: title,
            userId: user.id,
            deckId: defaultDeck,
            groups: allGroups,
        });

        if (!res) return;

        navigate(`/host/${res.id}`);
    };

    return {
        user,
        title,
        defaultDeck,
        group,
        decks,
        allGroups,

        setTitle,
        setDefaultDeck,
        setGroup,

        addGroup,
        removeGroup,

        submit,
    };
};

export default useHostLauncher;
