import { Button, Stack } from "@mui/material";
import React from "react";
import FormInput from "../inputs/FormInput";
import Text from "../Text";
import { TextSize } from "../utils/TextSize";
import { LoadingButton } from "@mui/lab";
import { ShownTaskForm, useTaskFormStore } from "../../stores/useTaskFormStore";
import AddLocalTaskForm from "./AddLocalTaskForm";
import CheckBoxInput from "../inputs/CheckBoxInput";
import AddJiraTaskForm from "./AddJiraTaskForm";

const AddTaskForm = () => {
    const form = useTaskFormStore((state) => state.form);
    const setForm = useTaskFormStore((state) => state.setForm);

    const toggle = () =>
        setForm(
            form === ShownTaskForm.Add
                ? ShownTaskForm.JiraAdd
                : ShownTaskForm.Add
        );
    return (
        <Stack spacing={2}>
            {form !== ShownTaskForm.AddChild && (
                <CheckBoxInput
                    checked={form === ShownTaskForm.JiraAdd}
                    name="Add new task from Jira"
                    onClick={toggle}
                />
            )}
            {form === ShownTaskForm.Add || form === ShownTaskForm.AddChild ? (
                <AddLocalTaskForm />
            ) : (
                form === ShownTaskForm.JiraAdd && <AddJiraTaskForm />
            )}
        </Stack>
    );
};

export default AddTaskForm;
