import * as signalR from "@microsoft/signalr";
import { useEffect, useRef } from "react";

const useHubConnection = (
    url: string,
    connections: (hubConnection: signalR.HubConnection) => void,
    onStart: () => Promise<void> | void = () => {}
) => {
    const connected = useRef(false);

    useEffect(() => {
        const hubConnection = new signalR.HubConnectionBuilder()
            .configureLogging(signalR.LogLevel.Warning)
            .withAutomaticReconnect()
            .withUrl(url)
            .build();

        connections(hubConnection);

        hubConnection.start().then(() => {
            connected.current = true;
            onStart();
        });

        return () => {
            if (!connected.current) return;
            hubConnection.stop();
            connected.current = false;
        };
    }, [url]);
};

export default useHubConnection;
