import { useEffect } from "react";
import { FieldErrors } from "react-hook-form";
import useErrorSnack from "../snackbar/useErrorSnack";

export const useShowFormError = (
    errorState: FieldErrors<any>,
    submited: number,
    clear: () => void
) => {
    const error = useErrorSnack();

    useEffect(() => {
        validate();
    }, [submited]);

    const validate = () => {
        for (let key in errorState) {
            if (key === "root") continue;

            let value = errorState[key];
            if (!value?.message) continue;

            error(value.message.toString());
            clear();
            return;
        }
    };

    return;
};
