import React from "react";
import { FieldValues, useForm } from "react-hook-form";
import { useShowFormError } from "../utils/useShowFormError";
import { ShownTaskForm, useTaskFormStore } from "../../stores/useTaskFormStore";
import useAxios from "../api/useAxios";
import useShowError from "../utils/useShowError";
import { useMeetingStore } from "../../stores/useMeetingStore";
import IMeeting from "../../api/IMeeting";
import { useActiveTopicStore } from "../../stores/useActiveTopicStore";

type AddLocalTaskFormState = {
    title: string;
    desc: string;
};

const useAddLocalTaskForm = () => {
    const meeting = useMeetingStore((state) => state.meeting);
    const parent = useActiveTopicStore((state) => state.activeSubTopic);
    const { setOpen, form } = useTaskFormStore(({ setOpen, form }) => ({
        setOpen,
        form,
    }));

    const { error, loading, setError, clearError, post } = useAxios();
    useShowError(error, clearError);

    const {
        control,
        formState: { errors, submitCount },
        clearErrors,
        handleSubmit,
        reset,
    } = useForm<AddLocalTaskFormState>({
        defaultValues: {
            title: "",
            desc: "",
        },
    });
    useShowFormError(errors, submitCount, clearErrors);

    const isChild = form === ShownTaskForm.AddChild;

    const close = () => {
        reset();
        setOpen(false);
    };

    const submit = handleSubmit(async (data) => {
        if (!meeting) return setError(`Missing Meeting`);

        console.log('useAddLocalTaskForm');
        let res = await post<IMeeting>(`/api/meeting/CreateSubTopic`, {
            meetingId: meeting.id,
            subTopicId: isChild ? parent?.id : undefined,
            // taskId: task.id,

            topic: data.title,
            desc: data.desc,
        });

        close();
    });

    return {
        control,
        loading,
        isChild,
        parent,
        submit,
        close,
    };
};

export default useAddLocalTaskForm;
