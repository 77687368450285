import { Stack } from "@mui/material";
import React from "react";
import useUpdateTaskForm from "../../hooks/components/useUpdateTaskForm";
import FormInput from "../inputs/FormInput";
import TaskFormButtons from "./TaskFormButtons";
import ChildFormHeader from "./ChildFormHeader";

const UpdateTaskForm = () => {
    const {
        control,
        loading,
        parent,
        isChild,
        close,
        getSolidButtonText,
        getSolidButtonOnClick,
    } = useUpdateTaskForm();
    return (
        <Stack spacing={2}>
            <ChildFormHeader isChild={isChild} parent={parent} />

            <FormInput
                control={control}
                name="title"
                label="Task Title"
                placeHolder="Enter a title for the task..."
                rules={{
                    required: "Missing Task Title",
                }}
            />
            <FormInput
                control={control}
                name="desc"
                label="Task Description"
                placeHolder="Enter the description for the task..."
                rules={{
                    required: "Missing Task Description",
                }}
                multiline
            />
            <FormInput
                control={control}
                name="points"
                label="Task Points"
                placeHolder="Enter the task's estimated points..."
                rules={{
                    required: false,
                }}
                type="number"
            />
            <TaskFormButtons
                outlineText="Cancel"
                solidText={getSolidButtonText()}
                outlineClick={close}
                solidClick={getSolidButtonOnClick()}
                loading={loading}
            />
        </Stack>
    );
};

export default UpdateTaskForm;
