import { Box, Stack, useTheme } from "@mui/material";
import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import Header from "../components/headers/Header";
import useIgnoreErrors from "../hooks/utils/useIgnoreErrors";
import useRefreshUser from "../hooks/api/useRefreshUser";

const RootPage = () => {
    const theme = useTheme();

    useIgnoreErrors();
    useRefreshUser();

    return (
        <Stack
            display={"flex"}
            flexDirection={"column"}
            minHeight={"100vh"}
            maxHeight={{ md: "100vh" }}
            overflow={"clip"}
        >
            <Header />
            <Stack
                maxHeight={"100%"}
                bgcolor={theme.palette.background.default}
                flex={1}
            >
                <Outlet />
            </Stack>
        </Stack>
    );
};

export default RootPage;
