import IMeeting from "../api/IMeeting";
import ISubTopic from "../api/ISubTopic";

export const findMeetingTopic = (topicId: string, subTopics: ISubTopic[]) => {
    for (let subTopic of subTopics) {
        if (subTopic.id === topicId) return subTopic;

        for (let topic of subTopic.tasks) {
            if (topic.id === topicId) return topic;
        }
    }

    return undefined;
};
