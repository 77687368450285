import {
    Button,
    ButtonBase,
    CardActionArea,
    Paper,
    Stack,
    useTheme,
} from "@mui/material";
import React, { useCallback } from "react";
import CONSTANTS from "../../contracts/Constants";
import SquareIconButton from "../SquareIconButton";
import { Add, Delete, Edit, Remove } from "@mui/icons-material";
import Text from "../Text";
import { TextSize } from "../utils/TextSize";
import { CSSProperties } from "@mui/styled-engine";
import { COLORS } from "../../utilities/theme";
import TouchRipple from "@mui/material/ButtonBase/TouchRipple";
import RippleStack from "../RippleStack";
import TaskCardActions from "./TaskCardActions";
import { ITopicTask } from "../../api/ITopicTask";
import ISubTopic from "../../api/ISubTopic";
import { toString } from "../../utilities/stringify";
import useScreenSize from "../../hooks/utils/useScreenSize";
import { useMeetingStore } from "../../stores/useMeetingStore";

export type TaskCardDisabled = {
    all?: boolean;
    press?: boolean;
    add?: boolean;
    edit?: boolean;
    trash?: boolean;
};

export type TaskCardTemplateProps = {
    task: ITopicTask;
    active?: boolean;
    short?: boolean;
    disabled?: TaskCardDisabled;
    onCardPress?: (item: ITopicTask | ISubTopic) => void;
    onDeletePress?: (item: ITopicTask) => void;
    onEditPress?: (item: ITopicTask) => void;
    onAddPress?: (item: ITopicTask) => void;
};

const TaskCardTemplate = ({
    task,
    active,
    short,
    disabled,
    onCardPress: onCardPressParent = () => {},
    onDeletePress: onDeletePressParent = () => {},
    onEditPress: onEditPressParent = () => {},
    onAddPress: onAddPressParent = () => {},
}: TaskCardTemplateProps) => {
    const meeting = useMeetingStore((state) => state.meeting);
    const theme = useTheme();
    const border: CSSProperties["border"] = `2px solid ${
        active ? theme.palette.info.main : COLORS.grey
    }`;

    const onCardPress = useCallback(() => {
        if (disabled?.all || disabled?.press) return;
        onCardPressParent(task);
    }, [onCardPressParent, toString(task)]);

    const onDeletePress = useCallback(() => {
        if (disabled?.all || disabled?.trash) return;
        onDeletePressParent(task);
    }, [onDeletePressParent, toString(task)]);

    const onEditPress = useCallback(() => {
        if (disabled?.all || disabled?.edit) return;
        onEditPressParent(task);
    }, [onEditPressParent, toString(task)]);

    const onAddPress = useCallback(() => {
        if (disabled?.all || disabled?.add) return;
        onAddPressParent(task);
    }, [onAddPressParent, toString(task)]);

    return (
        <RippleStack
            onClick={onCardPress}
            disabled={disabled?.all || disabled?.press}
            containerStyle={{
                cursor:
                    disabled?.all || disabled?.press ? "default" : "pointer",
                width: short ? "90%" : "100%",
            }}
            borderRadius={theme.shape.borderRadius}
        >
            <Paper
                sx={{ borderRadius: theme.shape.borderRadius, border }}
                elevation={5}
            >
                <Stack height={CONSTANTS.Card.Task.height} spacing={1}>
                    <Stack pt={2} px={2} flex={1}>
                        <Text bold maxLines={1} size={TextSize.Medium}>
                            {task.topic}
                        </Text>
                        <Text size={TextSize.Small} maxLines={3}>
                            {task.desc}
                        </Text>
                    </Stack>
                    <Stack style={{ position: "relative" }}>
                        <Stack style={{ opacity: 0 }}>
                            <TaskCardActions />
                        </Stack>
                        <Stack
                            style={{
                                pointerEvents: "all",
                                zIndex: 1,
                                cursor: "default",
                                position: "absolute",
                                top: 0,
                                left: 0,
                                right: 0,
                                bottom: 0,
                            }}
                        >
                            <TaskCardActions
                                active={meeting?.votingOn === task.id}
                                disabled={disabled}
                                points={task.points}
                                onAddPress={onAddPress}
                                onDeletePress={onDeletePress}
                                onEditPress={onEditPress}
                            />
                        </Stack>
                    </Stack>
                </Stack>
            </Paper>
        </RippleStack>
    );
};

export default TaskCardTemplate;
