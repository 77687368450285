import { Button, Fab, Stack, useTheme } from "@mui/material";
import React from "react";
import TaskList from "../components/taskList/TaskList";
import HostPageHeader from "../components/headers/HostPageHeader";
import ParticipantViewer from "../components/participantViewer/ParticipantViewer";
import TaskForm from "../components/taskForm/TaskForm";
import useHostPage from "../hooks/pages/useHostPage";
import ParticipantModal from "../components/modals/ParticipantModal";
import { LoadingButton } from "@mui/lab";
import QrCodeModal from "../components/modals/QrCodeModal";
import useScreenSize from "../hooks/utils/useScreenSize";
import { Close, ViewSidebar } from "@mui/icons-material";
import FavoriteCardModal from "../components/modals/FavoriteCardModal";

const HostPage = () => {
    const theme = useTheme();

    const {
        roundLoading,
        participant,
        roundStarted,
        allParticipantsVoted,
        isCardShown,
        qrOpen,
        favOpen,
        taskBarOpen,
        votingTopic,

        onRoundClick,
        openJiraSetup,
        closeParticipantModal,
        selectParticipant,
        openQr,
        closeQr,
        openFav,
        closeFav,
        toggleTaskBarOpen,
    } = useHostPage();

    const { xsOrsm } = useScreenSize();

    return (
        <Stack
            maxHeight={"100%"}
            flex={1}
            display={"flex"}
            direction={"row"}
            flexDirection={"row"}
            sx={{ position: "relative" }}
        >
            <Stack
                flex={{ xs: taskBarOpen ? 0 : 1, md: 0.72 }}
                height={{ xs: taskBarOpen ? 0 : undefined, md: undefined }}
                spacing={3}
                alignItems={"center"}
                justifyContent={"flex-start"}
                overflow={"hidden"}
                sx={{
                    transition: `250ms ease-in-out`,
                    transitionProperty: "flex",
                }}
            >
                <HostPageHeader
                    title={votingTopic?.topic}
                    onSettingClick={openJiraSetup}
                    onSendClick={openQr}
                />

                <LoadingButton
                    loading={roundLoading}
                    disabled={roundStarted && !allParticipantsVoted}
                    onClick={onRoundClick}
                    color="info"
                    variant="contained"
                >
                    {roundStarted
                        ? allParticipantsVoted
                            ? isCardShown
                                ? "End Voting"
                                : "Show Votes"
                            : "Voting..."
                        : "Start Voting"}
                </LoadingButton>

                <Stack paddingBottom={4} flex={1} width={"100%"}>
                    <ParticipantViewer
                        isVoting={roundStarted}
                        isCardShown={isCardShown}
                        onParticipant={selectParticipant}
                        onDiscuss={openFav}
                    />
                </Stack>
            </Stack>

            <Stack
                padding={taskBarOpen || !xsOrsm ? 3 : 0}
                bgcolor={theme.palette.background.paper}
                flex={{ xs: taskBarOpen ? 1 : 0, md: 0.28 }}
                spacing={2}
                overflow={"hidden"}
                sx={{
                    transition: `250ms ease-in-out`,
                    transitionProperty: "flex",
                }}
            >
                <TaskList />
            </Stack>

            {xsOrsm && (
                <Fab
                    color="info"
                    onClick={toggleTaskBarOpen}
                    sx={{
                        position: "fixed",
                        bottom: 20,
                        right: 20,
                        borderRadius: theme.shape.borderRadius / 2,
                    }}
                >
                    {taskBarOpen ? <Close /> : <ViewSidebar />}
                </Fab>
            )}

            <ParticipantModal
                isOpen={participant !== undefined}
                initialPeek={isCardShown}
                roundStarted={roundStarted}
                onClose={closeParticipantModal}
            />

            <QrCodeModal isOpen={qrOpen} onClose={closeQr} />

            <FavoriteCardModal isOpen={favOpen} onClose={closeFav} />
        </Stack>
    );
};

export default HostPage;
