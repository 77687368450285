import { Stack } from "@mui/material";
import React from "react";
import Text from "../Text";
import { TextSize } from "../utils/TextSize";

type TaskListHeaderProps = {
    points: number;
};

const TaskListHeader = ({ points }: TaskListHeaderProps) => {
    return (
        <Stack
            direction={"row"}
            flexDirection={"row"}
            alignItems={"center"}
            justifyContent={"flex-start"}
            spacing={2}
        >
            <Text size={TextSize.Large} bold>
                Task List
            </Text>
            <Text size={TextSize.Small}>
                {points} task point{points > 0 ? "s" : ""}
            </Text>
        </Stack>
    );
};

export default TaskListHeader;
