import { Stack, Button, useTheme,CircularProgress, Snackbar, Alert   } from "@mui/material";
import LoginImage from "../../images/LoginImage.png";
import { onEnterKey } from "../../utilities/keyboardKey";
import { COLORS } from "../../utilities/theme";
import CheckBoxInput from "../inputs/CheckBoxInput";
import FormInput from "../inputs/FormInput";
import { TextSize } from "../utils/TextSize";
import Text from "../Text";
import useLoginFormCard from "../../hooks/components/useLoginFormCard";
import useScreenSize from "../../hooks/utils/useScreenSize";
import {useState} from "react";
import {Simulate} from "react-dom/test-utils";
import load = Simulate.load;

const LoginFormCard = () => {
    const theme = useTheme();

    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('');
    //const [severity, setSeverity] = useState<'success' | 'info' | 'warning' | 'error'>('success');
    
    const { xs, sm, xsOrsm } = useScreenSize();
    const {
        control,
        scrumMaster,
        toggleScrumMaster,
        login,
        loginGuest,
        onEnterFocus,
        setInputRef,
    } = useLoginFormCard();
    
    const handleLogin = async () => {
        setLoading(true);
        try {
            await login(); // 调用原 login 方法
            //setMessage('登录成功！'); // 设置成功消息
            //setSeverity('success'); // 设置消息类型为成功
        } catch (error) {
            console.error("Login failed", error);
            //setMessage('登录失败，请检查您的凭据。'); // 设置错误消息
            //setSeverity('error'); // 设置消息类型为错误
        } finally {
            setLoading(false);
        }
    };

    const handleClose = () => {
        setMessage(''); // 关闭提示
    };
    return (
        <Stack
            bgcolor={theme.palette.background.paper}
            width={{ xs: "100%", md: "80vw" }}
            flex={{ xs: 1, md: undefined }}
            direction={{ xs: "column", md: "row" }}
            p={{ xs: 2, md: 4 }}
        >
            <Stack
                alignItems={"center"}
                justifyContent={"center"}
                flex={{ xs: 0.5, sm: 0.25, md: 0.6 }}
            >
                <img
                    // height={sm ? "50%" : undefined}
                    width={sm ? "50%" : "90%"}
                    src={LoginImage}
                    alt=""
                />
            </Stack>
            <Stack
                flex={{ xs: 0.5, sm: 0.75, md: 0.4 }}
                alignItems={"center"}
                justifyContent={"center"}
            >
                <Stack
                    spacing={{ xs: 1, sm: 3, md: 4 }}
                    height={"95%"}
                    width={"100%"}
                    alignItems={"center"}
                    justifyContent={"center"}
                >
                    <Stack py={{ xs: 2, md: 0 }}>
                        <Text
                            size={xs ? TextSize.Largest : TextSize.Humoungous}
                            bold
                        >
                            Agile Assistant
                        </Text>
                    </Stack>

                    <Stack width={"100%"} spacing={2}>
                        <FormInput
                            inputRef={setInputRef("username")}
                            control={control}
                            name="userName"
                            label="Username"
                            rules={{ required: "Missing username" }}
                            onKeyPress={onEnterFocus("password")}
                        />
                        <FormInput
                            inputRef={setInputRef("password")}
                            control={control}
                            name="password"
                            label="Password"
                            rules={{ required: "Missing password" }}
                            type="password"
                            onKeyPress={onEnterKey(login)}
                        />
                        <CheckBoxInput
                            checked={scrumMaster}
                            onClick={toggleScrumMaster}
                            name="Login as Scrum Master"
                        />
                    </Stack>

                    <Stack spacing={{ xs: 1, md: 2 }} width={"100%"}>
                        <Button onClick={handleLogin} variant="contained" fullWidth  disabled={loading}>
                            {loading ? <CircularProgress size={24} /> : 'Log In'}
                        </Button>
                    </Stack>
                    {/*<Snackbar open={!!message} autoHideDuration={3000}  onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>*/}
                    {/*    <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>*/}
                    {/*        {message}*/}
                    {/*    </Alert>*/}
                    {/*</Snackbar>*/}
                </Stack>
            </Stack>
        </Stack>
    );
};

export default LoginFormCard;
