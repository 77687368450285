import { Stack } from "@mui/material";
import React from "react";
import ParticipantCard from "../cards/ParticipantCard";
import IParticipant from "../../api/IParticipant";

type ParticipantRowProps = {
    participants: IParticipant[];
    isVoting?: boolean;
    isCardShown?: boolean;
    onParticipant?: (person: IParticipant) => void;
};

const ParticipantRow = ({
    participants,
    isVoting,
    isCardShown,
    onParticipant,
}: ParticipantRowProps) => {
    return (
        <Stack
            direction={"row"}
            flexDirection={"row"}
            justifyContent={"center"}
            spacing={{ xs: 2, md: 3 }}
        >
            {participants.map((participant, i) => {
                return (
                    <ParticipantCard
                        isVoting={isVoting}
                        isShown={isCardShown}
                        onClick={() =>
                            onParticipant && onParticipant(participant)
                        }
                        participant={participant}
                        key={i}
                    />
                );
            })}
        </Stack>
    );
};

export default ParticipantRow;
