import { useSnackbar } from "notistack";

const useErrorSnack = () => {
    const { enqueueSnackbar } = useSnackbar();

    const error = (msg: string, duration = 2250) => {
        enqueueSnackbar(msg, {
            variant: "error",
            preventDuplicate: true,
            autoHideDuration: duration,
        });
        return false;
    };
    return error;
};

export default useErrorSnack;
