import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Stack,
    SxProps,
    Theme,
} from "@mui/material";
import React, { PropsWithChildren } from "react";

type ContentOnlyAccordionProps = {
    open: boolean;
    sx?: SxProps<Theme>;
};

const ContentOnlyAccordion = ({
    children,
    open,
    sx = {},
}: PropsWithChildren<ContentOnlyAccordionProps>) => {
    return (
        <Accordion disableGutters elevation={0} expanded={open} sx={sx}>
            <AccordionSummary
                sx={{
                    padding: 0,
                    margin: 0,
                    minHeight: 0,
                    height: 0,
                    maxHeight: 0,

                    ".MuiAccordionSummary-content": {
                        padding: 0,
                        margin: 0,
                    },
                }}
                TouchRippleProps={{ style: { padding: 0 } }}
            ></AccordionSummary>
            <AccordionDetails sx={{ p: 0 }}>{children}</AccordionDetails>
        </Accordion>
    );
};

export default ContentOnlyAccordion;
