import React, { useState } from "react";

const useErrorState = () => {
    const [error, _setError] = useState("");
    const setError = (val: string) => {
        _setError(val);
        return false;
    };
    const clear = () => setError("");
    const clearError = () => setError("");
    return {
        error,
        setError,
        clear,
        clearError,
    };
};

export default useErrorState;
