import { Add, Close } from "@mui/icons-material";
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Stack,
} from "@mui/material";
import React, { PropsWithChildren, useCallback } from "react";
import { COLORS, theme } from "../../utilities/theme";
import RippleStack from "../RippleStack";
import { TextSize } from "../utils/TextSize";
import Text from "../Text";
import { ShownTaskForm, useTaskFormStore } from "../../stores/useTaskFormStore";

type TaskFormAccordionProps = {};

const TaskFormAccordion = ({
    children,
}: PropsWithChildren<TaskFormAccordionProps>) => {
    const { open, toggle, setForm } = useTaskFormStore();

    const onClick = useCallback(() => {
        if (!open) setForm(ShownTaskForm.Add);

        toggle();

        if (open)
            setTimeout(() => {
                setForm(ShownTaskForm.Add);
            }, 250);
    }, [toggle, setForm, open]);

    return (
        <Stack sx={{ maxHeight: "100%" }}>
            <RippleStack
                onClick={onClick}
                borderRadius={theme.shape.borderRadius}
                direction={"row"}
                flexDirection={"row"}
                spacing={1}
                flex={1}
                padding={1.5}
                px={2}
                ml={-0.5}
                sx={{
                    ":hover": {
                        backgroundColor: COLORS.lightGrey,
                        color: theme.palette.info.main,
                    },
                }}
            >
                {open ? <Close color="info" /> : <Add color="info" />}
                <Text size={TextSize.Small} color={theme.palette.info.main}>
                    {open ? "Close" : "Add Task"}
                </Text>
            </RippleStack>
            <Accordion
                disableGutters
                elevation={0}
                expanded={open}
                sx={{
                    cursor: "default",
                    maxHeight: "100%",
                    overflowY: "auto",
                }}
            >
                <AccordionSummary
                    sx={{
                        padding: 0,
                        margin: 0,
                        minHeight: 0,
                        height: 0,
                        maxHeight: 0,

                        ".MuiAccordionSummary-content": {
                            padding: 0,
                            margin: 0,
                        },
                    }}
                    TouchRippleProps={{ style: { padding: 0 } }}
                ></AccordionSummary>
                <AccordionDetails>
                    <Stack spacing={2} mt={1} flex={1}>
                        {children}
                    </Stack>
                </AccordionDetails>
            </Accordion>
        </Stack>
    );
};

export default TaskFormAccordion;
