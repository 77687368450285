import React from "react";
import { Route, Routes } from "react-router-dom";
import RootPage from "../pages/RootPage";
import HomePage from "../pages/HomePage";
import HostPage from "../pages/HostPage";
import ParticipantPage from "../pages/ParticipantPage";
import SettingsPage from "../pages/SettingsPage";
import LoginPage from "../pages/LoginPage";
import useRefreshUser from "../hooks/api/useRefreshUser";
import ErrorPage from "../pages/ErrorPage";

const AppRoutes = () => {
    return (
        <Routes>
            <Route path="" element={<RootPage />}>
                <Route path="" index element={<HomePage />} />
                <Route path="host/:id" element={<HostPage />} />
                <Route path="participant/:id" element={<ParticipantPage />} />
                <Route path="login/:meetingId" element={<LoginPage />} />
                <Route path="login" element={<LoginPage />} />
                <Route path="settings" element={<SettingsPage />} />
                <Route path=":meetingId" element={<HomePage />} />
                <Route path="*" element={<ErrorPage />} />
            </Route>
        </Routes>
    );
};

export default AppRoutes;
