import { Button, Portal, Stack } from "@mui/material";
import React from "react";
import ModalTemplate, { ModalTemplateProps } from "./ModalTemplate";
import Text from "../Text";
import useParticipantModal from "../../hooks/components/useParticipantModal";
import ParticipantCard from "../cards/ParticipantCard";
import { ExitToApp, Visibility, VisibilityOff } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import ModalContainer from "./ModalContainer";

type ParticipantModalProps = ModalTemplateProps & {
    roundStarted: boolean;
    initialPeek: boolean;
};

const ParticipantModal = ({
    isOpen,
    roundStarted,
    initialPeek,
    onClose,
}: ParticipantModalProps) => {
    const { participant, peek, loading, kickParticipant, togglePeek } =
        useParticipantModal({ onClose, initialPeek });
    return (
        <ModalTemplate isOpen={isOpen} onClose={onClose}>
            {participant && (
                <ModalContainer>
                    <ParticipantCard
                        fullName
                        isShown={peek}
                        participant={participant}
                    />

                    <Stack
                        width={{ xs: "80%", md: "50%" }}
                        direction={"row"}
                        alignItems={"center"}
                        justifyContent={"space-between"}
                    >
                        <LoadingButton
                            loading={loading}
                            onClick={kickParticipant}
                            endIcon={<ExitToApp />}
                            variant="outlined"
                            color="error"
                        >
                            Kick
                        </LoadingButton>
                        <Button
                            disabled={
                                !roundStarted || !participant.selectedPoker
                            }
                            endIcon={peek ? <VisibilityOff /> : <Visibility />}
                            onClick={togglePeek}
                            variant="contained"
                            color="primary"
                        >
                            {peek ? "Hide" : "Peek"}
                        </Button>
                    </Stack>
                </ModalContainer>
            )}
        </ModalTemplate>
    );
};

export default ParticipantModal;
