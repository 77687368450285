export enum TextSize {
    Humoungous = "h3",
    Largest = "h5",
    Large = "h6",
    Medium = "body1",
    Small = "button",
}

export enum TextFontFamily {
    Normal = "satoshi-light",
    Bold = "satoshi-bold",
}
