import { Portal, Modal, Stack, useTheme } from "@mui/material";
import React, { ReactElement, ReactNode } from "react";

export type ModalTemplateProps = {
    isOpen: boolean;
    onClose: () => void;
};

const ModalTemplate = ({
    isOpen,
    children,
    onClose,
}: ModalTemplateProps & { children: ReactNode }) => {
    const theme = useTheme();
    return (
        <Portal>
            <Modal
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    outline: "none",
                    "&:focus": {
                        outline: "none",
                    },
                }}
                open={isOpen}
                onClose={onClose}
            >
                <Stack
                    bgcolor={theme.palette.background.paper}
                    // bgcolor={"green"}
                    borderRadius={theme.shape.borderRadius}
                    borderColor={"transparent"}
                    overflow={"hidden"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    sx={{
                        outline: "none",
                        "&:focus": {
                            outline: "none",
                        },
                    }}
                >
                    {children}
                </Stack>
            </Modal>
        </Portal>
    );
};

export default ModalTemplate;
