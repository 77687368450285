import { LoadingButton } from "@mui/lab";
import { Stack, Button } from "@mui/material";
import React from "react";
import { TextSize } from "../utils/TextSize";
import Text from "../Text";

type TaskFormButtonsProps = {
    outlineText?: string;
    solidText?: string;
    loading: boolean;
    solidClick: () => void;
    outlineClick: () => void;
};

const TaskFormButtons = ({
    outlineText = "Close",
    solidText = "Save",
    loading,
    outlineClick,
    solidClick,
}: TaskFormButtonsProps) => {
    return (
        <Stack
            direction={"row"}
            flexDirection={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
            spacing={2}
        >
            <Button
                onClick={outlineClick}
                fullWidth
                variant="outlined"
                color="info"
            >
                <Text size={TextSize.Small}>{outlineText}</Text>
            </Button>
            <LoadingButton
                loading={loading}
                disabled={loading}
                onClick={solidClick}
                fullWidth
                variant="contained"
                color="info"
            >
                <Text size={TextSize.Small}>{solidText}</Text>
            </LoadingButton>
        </Stack>
    );
};

export default TaskFormButtons;
