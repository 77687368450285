import React, { useCallback, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useMeetingChangeSocket from "../api/useMeetingChangeSocket";
import useGetMeeting from "../api/useGetMeeting";
import useErrorSnack from "../snackbar/useErrorSnack";
import useErrorState from "../utils/useErrorState";
import useShowError from "../utils/useShowError";
import useTimeoutEffect from "../utils/useTimeoutEffect";
import { useMeetingStore } from "../../stores/useMeetingStore";
import useLogin from "../api/useLogin";
import { defaultUser, useUserStore } from "../../stores/useUserStore";
import { ShownTaskForm, useTaskFormStore } from "../../stores/useTaskFormStore";
import { useActiveTopicStore } from "../../stores/useActiveTopicStore";
import { toString } from "../../utilities/stringify";
import { useActiveParticipantStore } from "../../stores/useActiveParticpantStore";
import IParticipant from "../../api/IParticipant";
import useMeetingFromParams from "../utils/useMeetingFromParams";
import useRoundControl from "../api/useRoundControl";
import useIsOpen from "../utils/useIsOpen";
import useScreenSize from "../utils/useScreenSize";
import ISubTopic from "../../api/ISubTopic";
import { findMeetingTopic } from "../../utilities/findTopic";

type HostPageParams = { id: string };

const useHostPage = () => {
    const navigate = useNavigate();

    const clearTaskForm = useTaskFormStore((state) => state.clear);
    const clearActiveTask = useActiveTopicStore((state) => state.clear);
    const { setOpen, setForm } = useTaskFormStore(({ setOpen, setForm }) => ({
        setOpen,
        setForm,
    }));
    const { setParticipant, participant } = useActiveParticipantStore();
    const meeting = useMeetingStore((state) => state.meeting);

    const { error, setError, clear } = useErrorState();
    useShowError(error, clear);

    const {
        onRoundClick,
        loading: roundLoading,
        roundStarted,
        allParticipantsVoted,
        isCardShown,
    } = useRoundControl({
        axiosProps: { error, setError },
    });

    const [qrOpen, openQr, closeQr] = useIsOpen(false);
    const [favOpen, openFav, closeFav] = useIsOpen(false);
    const [taskBarOpen, openTaskBar, __, toggleTaskBarOpen] = useIsOpen(false);
    const { xsOrsm } = useScreenSize();

    useMeetingFromParams();

    const getVotingTopic = useCallback(
        (subTopics: ISubTopic[], topicId?: string) => {
            if (!topicId) return undefined;
            return findMeetingTopic(topicId, subTopics);
        },
        []
    );

    const openJiraSetup = useCallback(() => {
        setForm(ShownTaskForm.JiraSetup);
        setOpen(true);
        openTaskBar();
    }, [setOpen, setForm]);

    const closeParticipantModal = useCallback(() => {
        setParticipant(undefined);
    }, []);

    const selectParticipant = useCallback(
        (person: IParticipant) => {
            setParticipant(
                person.name === participant?.name ? undefined : person
            );
        },
        [participant?.name]
    );

    return {
        roundLoading,
        participant,
        roundStarted,
        allParticipantsVoted,
        isCardShown,
        qrOpen,
        favOpen,
        taskBarOpen: taskBarOpen && xsOrsm,
        votingTopic: getVotingTopic(
            meeting?.subTopics ?? [],
            meeting?.votingOn
        ),

        onRoundClick,
        selectParticipant,
        openJiraSetup,
        closeParticipantModal,
        openQr,
        closeQr,
        openFav:
            isCardShown && meeting && meeting.participants.length > 0
                ? openFav
                : undefined,
        closeFav,
        toggleTaskBarOpen,
    };
};

export default useHostPage;
