import { Stack, SxProps, Theme } from "@mui/material";
import React from "react";
import Text from "../Text";
import { TextSize } from "../utils/TextSize";
import { COLORS } from "../../utilities/theme";
import useScreenSize from "../../hooks/utils/useScreenSize";

type ParticipantListHeaderProps = {
    bottomBorder?: boolean;
};

const ParticipantListHeader = ({
    bottomBorder,
}: ParticipantListHeaderProps) => {
    const { xsOrsm } = useScreenSize();
    const itemBorder: SxProps<Theme> = xsOrsm
        ? {
              borderWidth: 0,
              borderRightWidth: 2,
              borderStyle: "solid",
              borderColor: COLORS.grey,
          }
        : {};
    return (
        <Stack
            pl={2}
            py={1}
            direction={"row"}
            alignItems={"center"}
            sx={
                bottomBorder
                    ? {
                          borderWidth: 0,
                          borderBottomWidth: 2,
                          borderStyle: "solid",
                          borderColor: COLORS.grey,
                      }
                    : undefined
            }
            spacing={2}
        >
            <Stack
                justifyContent={"center"}
                sx={itemBorder}
                width={"25%"}
                height={"100%"}
            >
                <Text size={TextSize.Medium} bold>
                    Team
                </Text>
            </Stack>
            <Stack
                justifyContent={"center"}
                sx={itemBorder}
                width={"45%"}
                height={"100%"}
            >
                <Text size={TextSize.Medium} bold>
                    Name
                </Text>
            </Stack>
            <Stack justifyContent={"center"} width={"20%"} height={"100%"}>
                <Text size={TextSize.Medium} bold>
                    Value
                </Text>
            </Stack>
        </Stack>
    );
};

export default ParticipantListHeader;
