import React, { useCallback } from "react";
import { SelectItem } from "../../components/inputs/SelectInput";
import { useMeetingStore } from "../../stores/useMeetingStore";
import { toString } from "../../utilities/stringify";
import { useActiveTopicStore } from "../../stores/useActiveTopicStore";
import { ITopicTask } from "../../api/ITopicTask";
import ISubTopic from "../../api/ISubTopic";
import useDeleteTask from "../api/useDeleteTask";
import useShowError from "../utils/useShowError";
import useTaskForm from "./useTaskForm";
import { ShownTaskForm, useTaskFormStore } from "../../stores/useTaskFormStore";

const SortingOptions = ["Points Asc", "Points Desc", "Title Asc", "Title Desc"];

const useTaskList = () => {
    const meeting = useMeetingStore((state) => state.meeting);
    const { activeSubTopic, activeTask, setActive } = useActiveTopicStore();
    const { form, setOpen, setForm } = useTaskFormStore();

    const {
        error,
        setError,
        clearError,
        remove: _removeTask,
    } = useDeleteTask();
    useShowError(error, clearError);

    const sortOptions: SelectItem[] = SortingOptions.map((option) => ({
        key: option,
        value: option,
        placeHolder: option,
    }));
    const tasks = meeting?.subTopics ?? [];

    const active: ITopicTask | ISubTopic | undefined =
        activeTask ?? activeSubTopic;

    const removeTask = useCallback(
        (task: ISubTopic | ITopicTask) => {
            if (!meeting) return setError(`Missing Meeting`);
            _removeTask(meeting.id, task);
        },
        [meeting?.id, _removeTask]
    );

    const editTask = useCallback(
        (subTopic: ISubTopic, task?: ITopicTask) => {
            setActive(subTopic, task);
            setForm(task ? ShownTaskForm.UpdateChild : ShownTaskForm.Update);
            setOpen(true);
        },
        [setActive]
    );

    const cardPress = useCallback(
        (subTopic?: ISubTopic, task?: ITopicTask) => {
            setActive(subTopic, task);
            if (form !== ShownTaskForm.Add && form !== ShownTaskForm.JiraAdd)
                setOpen(false);
        },
        [form, setActive, setOpen]
    );

    const onSubTopicAdd = useCallback(
        (item: ISubTopic | ITopicTask) => {
            setActive(item as ISubTopic, undefined);
            setForm(ShownTaskForm.AddChild);
            setOpen(true);
        },
        [setActive, setForm, setOpen]
    );
    const onSubTopicEdit = useCallback(
        (item: ISubTopic | ITopicTask) => {
            editTask(item as ISubTopic, undefined);
        },
        [editTask]
    );
    const onSubTopicPress = useCallback(
        (item: ISubTopic | ITopicTask) => {
            cardPress(
                activeSubTopic?.id === item.id && activeTask === undefined
                    ? undefined
                    : (item as ISubTopic),
                undefined
            );
        },
        [cardPress, activeSubTopic?.id, activeTask?.id]
    );

    const onTaskPress = useCallback(
        (subTopic: ISubTopic) => (item: ITopicTask) => {
            activeTask?.id === item.id
                ? cardPress(undefined, undefined)
                : cardPress(subTopic, item);
        },
        [cardPress, activeTask?.id]
    );
    const onTaskEdit = useCallback(
        (subTopic: ISubTopic) => (item: ITopicTask) => {
            editTask(subTopic, item);
        },
        [editTask]
    );
    const onTaskDelete = useCallback(
        (item: ITopicTask) => {
            removeTask(item);
        },
        [removeTask]
    );

    const calculatePoints = useCallback((topics: ISubTopic[]) => {
        let totalPoints = 0;
        for (let subTopic of topics) {
            if (subTopic.points === undefined || subTopic.points === null)
                continue;
            totalPoints += subTopic.points;

            for (let task of subTopic.tasks) {
                if (task.points === undefined || task.points === null) continue;
                totalPoints += task.points;
            }
        }
        return totalPoints;
    }, []);

    return {
        sortOptions,
        tasks,
        active,
        totalPoints: calculatePoints(tasks),
        setActive,
        onSubTopicPress,
        onSubTopicAdd,
        onSubTopicEdit,
        onTaskPress,
        onTaskEdit,
        onTaskDelete,
    };
};

export default useTaskList;
