import React, { useEffect } from "react";
import useOnWindowRefresh from "../utils/useOnWindowRefresh";
import useLogin from "./useLogin";
import useJWTCookie from "./useJWTCookie";
import { defaultUser, useUserStore } from "../../stores/useUserStore";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { IUser } from "../../api/IUser";
import useAxios from "./useAxios";
import { useTimeout } from "ahooks";
import useTimeoutEffect from "../utils/useTimeoutEffect";

const useRefreshUser = () => {
    const navigate = useNavigate();
    const location = useLocation().pathname;

    const meetingId = (useParams() as { meetingId?: string } | undefined)
        ?.meetingId;

    const { user, clear: clearUser, setUser } = useUserStore();

    const { getJwt, setJwt, remove: clearJwt } = useJWTCookie();
    const { error, loading, get } = useAxios();

    const isDefaultUser = user.id === defaultUser.id;

    useOnWindowRefresh(() => refresh());

    useTimeoutEffect(
        () => {
            refresh();
        },
        [location],
        1500
    );

    const toLogin = () => {
        let to = meetingId ? `/login/${meetingId}` : "/login";
        clearJwt().then(() => {
            // ce6d6e8f-3150-4053-81f0-214214c83dae
            clearUser();
            navigate(to);
        });
    };

    const refresh = async () => {
        const [jwtToken, expirationDateString] = await getJwt();

        const tokenExpired = () => {
            const expirationDateNumber =
                !expirationDateString || Number.isNaN(expirationDateString)
                    ? undefined
                    : parseInt(expirationDateString);

            if (expirationDateNumber === undefined) return true;

            //     `Now: ${new Date().toLocaleTimeString()} vs Then: ${new Date(
            //         expirationDateNumber
            //     ).toLocaleTimeString()}`
            // );

            if (new Date().getTime() < expirationDateNumber) return false;

            return true;
        };

        const getToken = () => {
            let token = user.jiraToken;

            if (jwtToken && jwtToken.length > 0) return jwtToken;

            if (token && token.length > 0) return token;

            return undefined;
        };

        const callApi = async (token: string) => {
            let res = await get<IUser>(`/api/user/refresh`, token);

            if (!res) return toLogin();

            setJwt(res.refreshToken);
            setUser(res);
        };

        let token = getToken();

        if (!token) {
            return toLogin();
        }

        if (tokenExpired()) {
            return toLogin();
        }

        if (!isDefaultUser) {
            return;
        }

        await callApi(token);
    };
};

export default useRefreshUser;
