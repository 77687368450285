import { Stack } from "@mui/material";
import React from "react";
import SelectInput, { SelectItem } from "./SelectInput";

type SelectJiraProjectProps = {
    projectName: string;
    issueName: string;

    projectPlaceholder?: string;
    issuePlaceholder?: string;

    projectId: string;
    issueType: string;

    projectOptions: SelectItem[];
    issueOptions: SelectItem[];

    setProjectId: (v: string) => void;
    setIssueType: (v: string) => void;
};

const SelectJiraProject = ({
    projectName,
    issueName,
    projectPlaceholder,
    issuePlaceholder,
    projectId,
    issueType,
    projectOptions,
    issueOptions,
    setProjectId,
    setIssueType,
}: SelectJiraProjectProps) => {
    return (
        <Stack spacing={2}>
            <SelectInput
                name={projectName}
                placeHolder={projectPlaceholder}
                fullWidth
                value={projectId}
                setValue={setProjectId}
                options={projectOptions}
            />
            {projectId.length > 0 && (
                <SelectInput
                    name={issueName}
                    placeHolder={issuePlaceholder}
                    fullWidth
                    value={issueType}
                    setValue={setIssueType}
                    options={issueOptions}
                />
            )}
        </Stack>
    );
};

export default SelectJiraProject;
