import { Stack, SxProps, Theme, useTheme } from "@mui/material";
import React from "react";
import { DeckIds } from "../../api/IDeck";
import CONSTANTS from "../../contracts/Constants";
import FilledPokerCard from "./FilledPokerCard";
import SvgCard from "./SvgCard";
import { BicyclePokerKeys } from "../../utilities/pokerImages";
import useScreenSize from "../../hooks/utils/useScreenSize";
import useCardSize from "../../hooks/utils/useCardSize";

type PokerCardProps = {
    deck: DeckIds;
    smaller?: boolean;
    sx?: SxProps<Theme>;
    value?: string;
    overideSize?: number;
    onClick?: () => void;
};

const PokerCard = ({
    deck,
    value,
    sx,
    smaller,
    overideSize,
    onClick,
}: PokerCardProps) => {
    const theme = useTheme();
    const [width, height] = useCardSize(overideSize);

    const isBicycleCard =
        deck !== DeckIds.Blue &&
        deck !== DeckIds.Lovely &&
        deck !== DeckIds.Custom;
    const isBackCard =
        value === undefined || value === null || value === "BACK";
    const Card =
        deck === DeckIds.Blue || deck === DeckIds.Custom
            ? FilledPokerCard
            : SvgCard;

    // const smallScreenDeduction = smaller ? 15 : 0;

    return (
        <Stack
            width={width}
            height={height}
            onClick={onClick}
            alignItems={"center"}
            justifyContent={"center"}
            sx={{
                ...sx,
                borderRadius: theme.shape.borderRadius,
                cursor: onClick ? "pointer" : "default",
            }}
        >
            <Card
                backgroundSize={
                    isBicycleCard && !isBackCard && smaller
                        ? "contain"
                        : undefined
                }
                deck={deck}
                value={value}
            />
        </Stack>
    );
};

export default PokerCard;
