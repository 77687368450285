import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useActiveTopicStore } from "../../stores/useActiveTopicStore";
import { useMeetingStore } from "../../stores/useMeetingStore";
import { useTaskFormStore } from "../../stores/useTaskFormStore";

const useLeaveMeetingPage = () => {
    const navigate = useNavigate();
    const clearMeeting = useMeetingStore((state) => state.clearMeeting);
    const clearTaskForm = useTaskFormStore((state) => state.clear);
    const clearActiveTask = useActiveTopicStore((state) => state.clear);

    const onArrowBack = useCallback(() => {
        clearMeeting();
        clearTaskForm();
        clearActiveTask();
        navigate("/");
    }, [navigate, clearMeeting]);

    return onArrowBack;
};

export default useLeaveMeetingPage;
