import { Stack, useTheme } from "@mui/material";
import React, { ReactNode } from "react";
import Text from "../Text";
import { TextSize } from "../utils/TextSize";
import RippleStack from "../RippleStack";
import { COLORS } from "../../utilities/theme";

type MobileHeaderTabProps = {
    title: string;
    border?: boolean;
    icon?: ReactNode;
    onPress?: () => void;
};

const MobileHeaderTab = ({
    title,
    icon,
    border,
    onPress,
}: MobileHeaderTabProps) => {
    const theme = useTheme();
    return (
        <Stack onClick={onPress} width={"100%"}>
            <RippleStack
                direction={"row"}
                color={theme.palette.background.paper}
            >
                <Stack width={"100%"}>
                    <Stack
                        direction={"row"}
                        alignItems={"center"}
                        justifyContent={"center"}
                        spacing={2}
                        py={2}
                    >
                        <Text
                            color={theme.palette.background.paper}
                            size={TextSize.Medium}
                            bold
                        >
                            {title}
                        </Text>
                        {icon}
                    </Stack>
                    {border && (
                        <Stack
                            width={"100%"}
                            pt={"2px"}
                            bgcolor={theme.palette.primary.main}
                        />
                    )}
                </Stack>
            </RippleStack>
        </Stack>
    );
};

export default MobileHeaderTab;
