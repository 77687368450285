import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import useTimeoutEffect from "./useTimeoutEffect";
import { useMeetingStore } from "../../stores/useMeetingStore";
import { useUserStore } from "../../stores/useUserStore";
import { error } from "console";
import useGetMeeting from "../api/useGetMeeting";
import useMeetingChangeSocket from "../api/useMeetingChangeSocket";
import useShowError from "./useShowError";
import { toString } from "../../utilities/stringify";

const useMeetingFromParams = () => {
    const navigate = useNavigate();
    const meetingId = (useParams() as { id: string }).id;

    const { setMeeting, meeting } = useMeetingStore();
    const user = useUserStore((state) => state.user);

    useMeetingChangeSocket({ meetingId });
    const { getMeeting, loading, error, clearError } = useGetMeeting();

    useShowError(error, clearError);

    useTimeoutEffect(
        () => {
            if (meetingId.length === 0) navigate("/");
            else if (meeting?.id !== meetingId)
                (async () => {
                    let meeting = await getMeeting(meetingId);
                    if (!meeting) return navigate("/");
                    setMeeting(meeting);
                })();
        },
        [user.id],
        750
    );

    return loading;
};

export default useMeetingFromParams;
