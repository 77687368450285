import React from "react";
import TaskCardTemplate, { TaskCardTemplateProps } from "./TaskCardTemplate";
import ISubTopic from "../../api/ISubTopic";
import { Stack } from "@mui/material";
import { Task } from "@mui/icons-material";
import TaskCard from "./TaskCard";
import { useActiveTopicStore } from "../../stores/useActiveTopicStore";
import { ITopicTask } from "../../api/ITopicTask";

type SubTopicCardProps = Omit<TaskCardTemplateProps, "task"> & {
    task: ISubTopic;
    onTaskPress?: (item: ITopicTask) => void;
    onTaskEdit?: (item: ITopicTask) => void;
    onTaskDelete?: (item: ITopicTask) => void;
};

const SubTopicCard = ({
    task,
    active,

    onCardPress,
    onDeletePress,
    onEditPress,
    onAddPress,

    onTaskPress,
    onTaskEdit,
    onTaskDelete,
}: SubTopicCardProps) => {
    const activeTask = useActiveTopicStore((state) => state.activeTask);
    return (
        <Stack spacing={3}>
            <TaskCardTemplate
                task={task}
                active={active}
                onAddPress={onAddPress}
                onCardPress={onCardPress}
                onDeletePress={onDeletePress}
                onEditPress={onEditPress}
            />
            {task.tasks.length > 0 && (
                <Stack spacing={3} alignItems={"flex-end"}>
                    {task.tasks.map((task) => {
                        const isActive = task.id === activeTask?.id;
                        return (
                            <TaskCard
                                key={task.id}
                                task={task}
                                active={isActive}
                                onCardPress={onTaskPress}
                                onEditPress={onTaskEdit}
                                onDeletePress={onTaskDelete}
                            />
                        );
                    })}
                </Stack>
            )}
        </Stack>
    );
};

export default SubTopicCard;
