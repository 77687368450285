import { Stack } from "@mui/material";
import React from "react";

type SpacingProps = {
    vertical?: number;
    horizontal?: number;
};

const Spacing = ({ vertical, horizontal }: SpacingProps) => {
    return <Stack pt={vertical} pl={horizontal} />;
};

export default Spacing;
