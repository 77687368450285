import React from "react";
import { ITopicTask } from "../../api/ITopicTask";
import ISubTopic from "../../api/ISubTopic";
import useAxios, { UseAxiosProps } from "./useAxios";

type UseDeleteTaskProps = {
    axiosProps?: UseAxiosProps;
};

const useDeleteTask = (props?: UseDeleteTaskProps) => {
    const { loading, error, setError, clearError, del,get } = useAxios(
        props?.axiosProps
    );

    const remove = async (meetingId: string, task: ITopicTask | ISubTopic) => {
        await get(`/api/meeting/RemoveSubTopic?meetingId=${meetingId}&topicId=${task.id}`);
    };

    return { loading, error, remove, setError, clearError };
};

export default useDeleteTask;
