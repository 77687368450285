import React, { useCallback } from "react";
import { BaseTextInputProps } from "./TextInput";
import { MenuItem, TextField } from "@mui/material";
import useScreenSize from "../../hooks/utils/useScreenSize";

export type SelectItem = {
    key: string | number;
    value: string;
    placeHolder?: string;
};

type SelectInputProps = BaseTextInputProps & {
    options: SelectItem[];
    includeNone?: boolean;
};

const SelectInput = ({
    value,
    name,
    placeHolder,
    fullWidth,
    options,
    includeNone = true,
    setValue,
}: SelectInputProps) => {
    const { xsOrsm } = useScreenSize();
    const allOptions: SelectItem[] = (
        includeNone
            ? [{ key: "EmptyKey", value: "", placeHolder: "None" }]
            : ([] as SelectItem[])
    ).concat(options);

    const onClick = useCallback(
        (value: string) => (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
            setValue(value);
        },
        [setValue]
    );

    return (
        <TextField
            fullWidth={fullWidth}
            value={value}
            label={name}
            placeholder={placeHolder ?? name}
            variant="outlined"
            color="primary"
            size={xsOrsm ? "small" : "medium"}
            select
            SelectProps={{
                MenuProps: {
                    sx: {
                        maxHeight: 300,
                    },
                },
            }}
        >
            {allOptions.map((option) => (
                <MenuItem
                    onClick={onClick(option.value)}
                    key={option.key}
                    value={option.value}
                >
                    {option.placeHolder ?? option.value}
                </MenuItem>
            ))}
        </TextField>
    );
};

export default SelectInput;
