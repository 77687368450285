import React, { useCallback, useEffect, useState } from "react";
import CONSTANTS from "../../contracts/Constants";
import { useMeetingStore } from "../../stores/useMeetingStore";
import useComponentDimensions from "../utils/useComponentDimensions";
import useToRow from "../utils/useToRow";
import useIsOpen from "../utils/useIsOpen";
import useScreenSize from "../utils/useScreenSize";

const useParticipantViewer = () => {
    const meeting = useMeetingStore((state) => state.meeting);

    const { mdUp, xsOrsm } = useScreenSize();
    const { ref, width: maxWidth } = useComponentDimensions<HTMLDivElement>([
        mdUp,
        xsOrsm,
    ]);

    const [isCardView, toCardView, toListView] = useIsOpen(true);
    const [filter, setFilter] = useState("");

    let allParticipants = (meeting?.participants ?? []).filter((participant) =>
        filter.length === 0 ? true : participant.group === filter
    );

    const rows = useToRow(allParticipants ?? [], { width: maxWidth }, [
        mdUp,
        xsOrsm,
    ]);

    return {
        ref,
        mdUp,
        rows,
        isCardView,
        participants: allParticipants ?? [],
        filter,
        setFilter,
        toCardView,
        toListView,
    };
};

export default useParticipantViewer;
