import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import useMeetingFromParams from "../utils/useMeetingFromParams";
import { defaultUser, useUserStore } from "../../stores/useUserStore";
import { useMeetingStore } from "../../stores/useMeetingStore";
import useTimeoutEffect from "../utils/useTimeoutEffect";
import useLeaveMeetingPage from "../utils/useLeaveMeetingPage";
import useLeaveMeeting from "../api/useLeaveMeeting";
import IParticipant from "../../api/IParticipant";
import { toString } from "../../utilities/stringify";
import useLogin from "../api/useLogin";
import useIsOpen from "../utils/useIsOpen";
import useAxios from "../api/useAxios";
import useShowError from "../utils/useShowError";
import useAllDecks from "../api/useAllDecks";
import IMeeting from "../../api/IMeeting";
import IParticipantPoker from "../../api/IParticipantPoker";
import guid from "../../utilities/guid";
import { SelectItem } from "../../components/inputs/SelectInput";
import useRoundControl from "../api/useRoundControl";
import ISubTopic from "../../api/ISubTopic";
import { findMeetingTopic } from "../../utilities/findTopic";
import { DeckIds } from "../../api/IDeck";
import IPoker from "../../api/IPoker";
import useComponentDimensions from "../utils/useComponentDimensions";

type ParticipantPageParams = {
    id: string;
};

const useParticipantPage = () => {
    const navigate = useNavigate();
    const routeState = useLocation().state as { name: string };

    const { ref, height: maxHeight } = useComponentDimensions();

    const meeting = useMeetingStore((state) => state.meeting);
    const user = useUserStore((state) => state.user);

    const participantName = routeState.name;
    const [participant, setParticipant] = useState<IParticipant | undefined>(
        undefined
    );

    const [selected, setSelected] = useState("");
    const select = (value: string) =>
        setSelected((prev) => (prev === value ? "" : value));

    const [confirmModalOpen, openConfirmModal, closeConfrimModal] =
        useIsOpen(false);
    const [confirmed, confirm, unConfirm] = useIsOpen(false);

    const meetingLoading = useMeetingFromParams();

    const leavePage = useLeaveMeetingPage();
    const { leaveMeeting, loading: leavingLoading } = useLeaveMeeting();

    const { error, loading, setError, clearError, post } = useAxios();
    const { decks, loading: decksLoading } = useAllDecks({
        axiosProps: { error, setError },
    });
    const changeDeck = async (val: string) => {
        if (!meeting) return setError(`Missing meeting`);

        await post<any>(
            `/api/meeting/UpdateParticipantDeck?meetingId=${meeting.id}&userName=${participantName}&deckId=${val}`
        );
        if (!confirmed) setSelected("");
    };
    useShowError(error, clearError);

    const deckOptions = decks.map(
        (deck): SelectItem => ({
            key: deck.id,
            value: deck.id,
            placeHolder: deck.description,
        })
    );

    const { roundStarted: isVoting } = useRoundControl();

    useTimeoutEffect(
        () => {
            if (meetingLoading) return;

            let found = (meeting?.participants ?? ([] as IParticipant[])).find(
                (person) => person.name === participantName
            );

            if (!found) return onArrowBack();

            if (toString(participant) === toString(found)) return;

            setParticipant(found);

            if (found.selectedPoker) {
                setSelected(found.selectedPoker.pokerValue);
                confirm();
            }
        },
        [
            toString(meeting),
            toString(participant),
            meetingLoading,
            participantName,
            user.id,
        ],
        1500
    );

    useEffect(() => {
        if (confirmed) {
            unConfirm();
            setSelected("");
        }
    }, [isVoting]);

    const getVotingTopic = useCallback(
        (subTopics: ISubTopic[], topicId?: string) => {
            if (!topicId) return undefined;
            return findMeetingTopic(topicId, subTopics);
        },
        []
    );

    const onArrowBack = async () => {
        await leaveMeeting(participantName);
        leavePage();
    };

    const onConfirm = async () => {
        const findOriginalPoker = (): IPoker | undefined =>
            participant?.deckId === DeckIds.Custom
                ? {
                      id: guid.create(),
                      value: selected,
                  }
                : decks
                      .find((deck) => deck.id === participant?.deckId)
                      ?.pokers.find((poker) => poker.value === selected);

        const isValid = () => {
            if (!meeting) return setError(`Missing meeting`);

            let originalPoker = findOriginalPoker();

            if (!originalPoker) return setError(`Missing selected poker`);

            return true;
        };

        if (!isValid()) return;

        const constructParticipantPoker = () => {
            const originalPoker = findOriginalPoker()!;

            let selectedPoker: IParticipantPoker = {
                id: guid.create(),
                pokerId: originalPoker.id,
                pokerValue: originalPoker.value,
                originalPokerValue: originalPoker.value,
                participantName,
            };

            return selectedPoker;
        };

        let res = await post<IMeeting>(
            `/api/meeting/SelectPokers?meetingId=${meeting!.id}`,
            {
                meetingId: meeting!.id,
                userName: participantName,
                selectedPoker: constructParticipantPoker(),
            }
        );

        if (!res) return;

        confirm();
        closeConfrimModal();
    };

    return {
        ref,
        maxHeight,
        loading,
        isVoting,
        decksLoading,
        participant,
        selected,
        confirmed,
        confirmModalOpen,
        deckOptions,
        votingTopic: getVotingTopic(
            meeting?.subTopics ?? [],
            meeting?.votingOn
        ),

        openConfirmModal,
        closeConfrimModal,
        onArrowBack,
        select,
        changeDeck,
        confirm: onConfirm,
        unConfirm: () => {
            unConfirm();
            closeConfrimModal();
        },
    };
};

export default useParticipantPage;
