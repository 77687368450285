import React, { PropsWithChildren } from "react";
import { TextFontFamily, TextSize } from "./utils/TextSize";
import { SxProps, Theme, Typography, TypographyProps } from "@mui/material";
import { CSSProperties } from "@mui/styled-engine";

type TextProps = {
    component?: TypographyProps["component"];
    size?: TextSize;
    bold?: boolean;
    color?: string;
    maxLines?: number;
    sx?: SxProps<Theme>;
};

const Text = ({
    size = TextSize.Large,
    component = "span",
    bold,
    color,
    children,
    maxLines,
    sx,
}: PropsWithChildren<TextProps>) => {
    const fontFamily = bold ? TextFontFamily.Bold : TextFontFamily.Normal;
    const maxLineStyle: CSSProperties = maxLines
        ? {
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "-webkit-box",
              lineClamp: maxLines,
              WebkitLineClamp: maxLines,
              WebkitBoxOrient: "vertical",
          }
        : {};
    return (
        <Typography
            component={component}
            color={color}
            variant={size}
            fontFamily={fontFamily}
            display={"inline"}
            sx={{ ...maxLineStyle, ...sx }}
        >
            {children}
        </Typography>
    );
};

export default Text;
