import { useTheme } from "@mui/material";
import { useSnackbar } from "notistack";

const useSuccessSnack = () => {
    const { enqueueSnackbar } = useSnackbar();
    const theme = useTheme();

    const success = (msg: string, duration = 2250) => {
        enqueueSnackbar(msg, {
            variant: "default",
            preventDuplicate: true,
            autoHideDuration: duration,
            TransitionProps: {
                style: {
                    backgroundColor: theme.palette.info.main,
                    borderRadius: theme.shape.borderRadius,
                },
            },
        });
        return true;
    };

    return success;
};

export default useSuccessSnack;
