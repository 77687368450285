import React from "react";
import useScreenSize from "./useScreenSize";
import CONSTANTS from "../../contracts/Constants";

const useCardSize = (overide?: number): [number, number] => {
    const { xsOrsm } = useScreenSize();

    const multiplier = overide !== undefined ? overide : xsOrsm ? 0.7 : 1;

    const width = CONSTANTS.Card.Deck.width * multiplier;
    const height = CONSTANTS.Card.Deck.height * multiplier;

    return [width, height];
};

export default useCardSize;
