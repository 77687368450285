import Lovely_1 from "../images/lovely/1.svg";
import Lovely_2 from "../images/lovely/2.svg";
import Lovely_3 from "../images/lovely/3.svg";
import Lovely_5 from "../images/lovely/5.svg";
import Lovely_8 from "../images/lovely/8.svg";
import Lovely_13 from "../images/lovely/13.svg";
import Lovely_20 from "../images/lovely/20.svg";
import Lovely_40 from "../images/lovely/40.svg";
import Lovely_100 from "../images/lovely/100.svg";
import Lovely_Cover from "../images/lovely/cover.svg";
import Lovely_Coffee from "../images/lovely/coffee.svg";
import Lovely_Infinite from "../images/lovely/infinite.svg";
import Lovely_Brownie from "../images/lovely/brownie.svg";
import Lovely_Dragons from "../images/lovely/dragons.svg";
import Lovely_YakShaving from "../images/lovely/yak_shaving.svg";

import Hearts_0 from "../images/bicycleCards/Hearts/0H.svg";
import Hearts_Half from "../images/bicycleCards/Hearts/HalfH.svg";
import Hearts_1 from "../images/bicycleCards/Hearts/1H.svg";
import Hearts_2 from "../images/bicycleCards/Hearts/2H.svg";
import Hearts_3 from "../images/bicycleCards/Hearts/3H.svg";
import Hearts_5 from "../images/bicycleCards/Hearts/5H.svg";
import Hearts_8 from "../images/bicycleCards/Hearts/8H.svg";
import Hearts_13 from "../images/bicycleCards/Hearts/13H.svg";
import Hearts_20 from "../images/bicycleCards/Hearts/20H.svg";
import Hearts_40 from "../images/bicycleCards/Hearts/40H.svg";
import Hearts_100 from "../images/bicycleCards/Hearts/100H.svg";
import Hearts_Infinite from "../images/bicycleCards/Hearts/InfiniteH.svg";
import Hearts_Coffee from "../images/bicycleCards/Hearts/CoffeeH.svg";
import Hearts_Question from "../images/bicycleCards/Hearts/QuestionH.svg";

import Spades_0 from "../images/bicycleCards/Spades/0S.svg";
import Spades_Half from "../images/bicycleCards/Spades/HalfS.svg";
import Spades_1 from "../images/bicycleCards/Spades/1S.svg";
import Spades_2 from "../images/bicycleCards/Spades/2S.svg";
import Spades_3 from "../images/bicycleCards/Spades/3S.svg";
import Spades_5 from "../images/bicycleCards/Spades/5S.svg";
import Spades_8 from "../images/bicycleCards/Spades/8S.svg";
import Spades_13 from "../images/bicycleCards/Spades/13S.svg";
import Spades_20 from "../images/bicycleCards/Spades/20S.svg";
import Spades_40 from "../images/bicycleCards/Spades/40S.svg";
import Spades_100 from "../images/bicycleCards/Spades/100S.svg";
import Spades_Infinite from "../images/bicycleCards/Spades/InfiniteS.svg";
import Spades_Coffee from "../images/bicycleCards/Spades/CoffeeS.svg";
import Spades_Question from "../images/bicycleCards/Spades/QuestionS.svg";

import Diamonds_0 from "../images/bicycleCards/Diamonds/0D.svg";
import Diamonds_Half from "../images/bicycleCards/Diamonds/HalfD.svg";
import Diamonds_1 from "../images/bicycleCards/Diamonds/1D.svg";
import Diamonds_2 from "../images/bicycleCards/Diamonds/2D.svg";
import Diamonds_3 from "../images/bicycleCards/Diamonds/3D.svg";
import Diamonds_5 from "../images/bicycleCards/Diamonds/5D.svg";
import Diamonds_8 from "../images/bicycleCards/Diamonds/8D.svg";
import Diamonds_13 from "../images/bicycleCards/Diamonds/13D.svg";
import Diamonds_20 from "../images/bicycleCards/Diamonds/20D.svg";
import Diamonds_40 from "../images/bicycleCards/Diamonds/40D.svg";
import Diamonds_100 from "../images/bicycleCards/Diamonds/100D.svg";
import Diamonds_Infinite from "../images/bicycleCards/Diamonds/InfiniteD.svg";
import Diamonds_Coffee from "../images/bicycleCards/Diamonds/CoffeeD.svg";
import Diamonds_Question from "../images/bicycleCards/Diamonds/QuestionD.svg";

import Clubs_0 from "../images/bicycleCards/Clubs/0C.svg";
import Clubs_Half from "../images/bicycleCards/Clubs/HalfC.svg";
import Clubs_1 from "../images/bicycleCards/Clubs/1C.svg";
import Clubs_2 from "../images/bicycleCards/Clubs/2C.svg";
import Clubs_3 from "../images/bicycleCards/Clubs/3C.svg";
import Clubs_5 from "../images/bicycleCards/Clubs/5C.svg";
import Clubs_8 from "../images/bicycleCards/Clubs/8C.svg";
import Clubs_13 from "../images/bicycleCards/Clubs/13C.svg";
import Clubs_20 from "../images/bicycleCards/Clubs/20C.svg";
import Clubs_40 from "../images/bicycleCards/Clubs/40C.svg";
import Clubs_100 from "../images/bicycleCards/Clubs/100C.svg";
import Clubs_Infinite from "../images/bicycleCards/Clubs/InfiniteC.svg";
import Clubs_Coffee from "../images/bicycleCards/Clubs/CoffeeC.svg";
import Clubs_Question from "../images/bicycleCards/Clubs/QuestionC.svg";

import AstroBack from "../images/bicycleCards/Back.svg";

type PokerKey = string | undefined | null;

export enum LovelyPokerKeys {
    One = "1",
    Two = "2",
    Three = "3",
    Five = "5",
    Eight = "8",
    Thirteen = "13",
    Twenty = "20",
    Fourty = "40",
    OneHundred = "100",
    Coffee = "coffee",
    Infinite = "infinite",
    Brownie = "brownie",
    Dragons = "dragons",
    YakShaving = "yak_shaving",
}

export function getLovelyImage(key: PokerKey) {
    switch (key) {
        case LovelyPokerKeys.One:
            return Lovely_1;
        case LovelyPokerKeys.Two:
            return Lovely_2;
        case LovelyPokerKeys.Three:
            return Lovely_3;
        case LovelyPokerKeys.Five:
            return Lovely_5;
        case LovelyPokerKeys.Eight:
            return Lovely_8;
        case LovelyPokerKeys.Thirteen:
            return Lovely_13;
        case LovelyPokerKeys.Twenty:
            return Lovely_20;
        case LovelyPokerKeys.Fourty:
            return Lovely_40;
        case LovelyPokerKeys.OneHundred:
            return Lovely_100;
        case LovelyPokerKeys.Coffee:
            return Lovely_Coffee;
        case LovelyPokerKeys.Infinite:
            return Lovely_Infinite;
        case LovelyPokerKeys.Brownie:
            return Lovely_Brownie;
        case LovelyPokerKeys.Dragons:
            return Lovely_Dragons;
        case LovelyPokerKeys.YakShaving:
            return Lovely_YakShaving;
        default:
            return Lovely_Cover;
    }
}

export enum BicyclePokerKeys {
    Zero = "0",
    Half = "1/2",
    One = "1",
    Two = "2",
    Three = "3",
    Five = "5",
    Eight = "8",
    Thirteen = "13",
    Twenty = "20",
    Forty = "40",
    Hundred = "100",
    Infinite = "infinite",
    Coffee = "coffee",
    Question = "?",
}

export function getHeartsImage(key: PokerKey) {
    switch (key) {
        case BicyclePokerKeys.Zero:
            return Hearts_0;
        case BicyclePokerKeys.Half:
            return Hearts_Half;
        case BicyclePokerKeys.One:
            return Hearts_1;
        case BicyclePokerKeys.Two:
            return Hearts_2;
        case BicyclePokerKeys.Three:
            return Hearts_3;
        case BicyclePokerKeys.Five:
            return Hearts_5;
        case BicyclePokerKeys.Eight:
            return Hearts_8;
        case BicyclePokerKeys.Thirteen:
            return Hearts_13;
        case BicyclePokerKeys.Twenty:
            return Hearts_20;
        case BicyclePokerKeys.Forty:
            return Hearts_40;
        case BicyclePokerKeys.Hundred:
            return Hearts_100;
        case BicyclePokerKeys.Infinite:
            return Hearts_Infinite;
        case BicyclePokerKeys.Coffee:
            return Hearts_Coffee;
        case BicyclePokerKeys.Question:
            return Hearts_Question;
        default:
            return AstroBack;
    }
}

export function getSpadesImage(key: PokerKey) {
    switch (key) {
        case BicyclePokerKeys.Zero:
            return Spades_0;
        case BicyclePokerKeys.Half:
            return Spades_Half;
        case BicyclePokerKeys.One:
            return Spades_1;
        case BicyclePokerKeys.Two:
            return Spades_2;
        case BicyclePokerKeys.Three:
            return Spades_3;
        case BicyclePokerKeys.Five:
            return Spades_5;
        case BicyclePokerKeys.Eight:
            return Spades_8;
        case BicyclePokerKeys.Thirteen:
            return Spades_13;
        case BicyclePokerKeys.Twenty:
            return Spades_20;
        case BicyclePokerKeys.Forty:
            return Spades_40;
        case BicyclePokerKeys.Hundred:
            return Spades_100;
        case BicyclePokerKeys.Infinite:
            return Spades_Infinite;
        case BicyclePokerKeys.Coffee:
            return Spades_Coffee;
        case BicyclePokerKeys.Question:
            return Spades_Question;

        default:
            return AstroBack;
    }
}

export function getDiamondImage(key: PokerKey) {
    switch (key) {
        case BicyclePokerKeys.Zero:
            return Diamonds_0;
        case BicyclePokerKeys.Half:
            return Diamonds_Half;
        case BicyclePokerKeys.One:
            return Diamonds_1;
        case BicyclePokerKeys.Two:
            return Diamonds_2;
        case BicyclePokerKeys.Three:
            return Diamonds_3;
        case BicyclePokerKeys.Five:
            return Diamonds_5;
        case BicyclePokerKeys.Eight:
            return Diamonds_8;
        case BicyclePokerKeys.Thirteen:
            return Diamonds_13;
        case BicyclePokerKeys.Twenty:
            return Diamonds_20;
        case BicyclePokerKeys.Forty:
            return Diamonds_40;
        case BicyclePokerKeys.Hundred:
            return Diamonds_100;
        case BicyclePokerKeys.Infinite:
            return Diamonds_Infinite;
        case BicyclePokerKeys.Coffee:
            return Diamonds_Coffee;
        case BicyclePokerKeys.Question:
            return Diamonds_Question;

        default:
            return AstroBack;
    }
}

export function getClubsImage(key: PokerKey) {
    switch (key) {
        case BicyclePokerKeys.Zero:
            return Clubs_0;
        case BicyclePokerKeys.Half:
            return Clubs_Half;
        case BicyclePokerKeys.One:
            return Clubs_1;
        case BicyclePokerKeys.Two:
            return Clubs_2;
        case BicyclePokerKeys.Three:
            return Clubs_3;
        case BicyclePokerKeys.Five:
            return Clubs_5;
        case BicyclePokerKeys.Eight:
            return Clubs_8;
        case BicyclePokerKeys.Thirteen:
            return Clubs_13;
        case BicyclePokerKeys.Twenty:
            return Clubs_20;
        case BicyclePokerKeys.Forty:
            return Clubs_40;
        case BicyclePokerKeys.Hundred:
            return Clubs_100;
        case BicyclePokerKeys.Infinite:
            return Clubs_Infinite;
        case BicyclePokerKeys.Coffee:
            return Clubs_Coffee;
        case BicyclePokerKeys.Question:
            return Clubs_Question;

        default:
            return AstroBack;
    }
}
