const stringify = (data: any) => JSON.stringify(data, null, 2);

export const toString = (data: any) => JSON.stringify(data, null, 2);

export const cutString = (str: string, to: number = 15) => {
    if (str.length <= to) return str;

    return str.slice(0, to - 3) + "...";
};

export default stringify;
