import { Stack, useTheme } from "@mui/material";
import React, { CSSProperties } from "react";
import { DeckIds } from "../../api/IDeck";
import Text from "../Text";
import { TextSize } from "../utils/TextSize";
import { ABSOLUTEFILL } from "../../contracts/Styles";
import { relative } from "path";

type FilledPokerCardProps = {
    deck: DeckIds;
    backgroundSize?: CSSProperties["backgroundSize"];
    value?: string;
};

const FilledPokerCard = ({ value }: FilledPokerCardProps) => {
    const theme = useTheme();
    return (
        <Stack
            flex={1}
            width={"100%"}
            bgcolor={theme.palette.primary.main}
            sx={{
                position: "relative",
                borderRadius: theme.shape.borderRadius + 1,
                pointerEvents: "all",
            }}
            alignItems={"center"}
            justifyContent={"center"}
        >
            <Text
                color={theme.palette.background.paper}
                size={TextSize.Largest}
                bold
                sx={{
                    maxWidth: "85%",
                    textAlign: "center",
                    lineBreak: "anywhere",
                }}
            >
                {value}
            </Text>
            <Stack
                flex={1}
                width={"100%"}
                sx={{
                    ...ABSOLUTEFILL,
                    boxShadow: `0px 0px 0px 4px ${theme.palette.background.paper} inset`,
                    borderRadius: theme.shape.borderRadius,
                    outlineWidth: 1,
                    outlineStyle: "solid",
                    outlineColor: theme.palette.info.main,
                    pointerEvents: "none",
                }}
            ></Stack>
        </Stack>
    );
};

export default FilledPokerCard;
