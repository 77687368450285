import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { toString } from "../../utilities/stringify";
import useTimeoutEffect from "./useTimeoutEffect";

type ComponentDimensions = {
    width: number;
    height: number;
};

const useComponentDimensions = <T extends HTMLElement>(
    deps: (boolean | string | number)[] = []
) =>
    // listenToResize: boolean = false
    {
        const ref = useRef<T | null>(null);
        const [dimensions, setDimensions] = useState<ComponentDimensions>({
            width: 0,
            height: 0,
        });

        useLayoutEffect(() => {
            const updateSize = () => {
                if (!ref.current) return () => {};

                const dims: ComponentDimensions = {
                    width: ref.current.clientWidth,
                    height: ref.current.clientHeight,
                };

                setDimensions(dims);
            };

            window.addEventListener("resize", updateSize);
            updateSize();

            return () => window.removeEventListener("resize", updateSize);
        }, [toString(dimensions)]);

        useTimeoutEffect(
            () => {
                const updateSize = () => {
                    if (!ref.current) return () => {};

                    const dims: ComponentDimensions = {
                        width: ref.current.clientWidth,
                        height: ref.current.clientHeight,
                    };

                    setDimensions(dims);
                };

                updateSize();
            },
            deps,
            500
        );

        return {
            ref,
            ...dimensions,
        };
    };

export default useComponentDimensions;
