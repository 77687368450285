import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useShowFormError } from "../utils/useShowFormError";
import { DeckIds } from "../../api/IDeck";
import useAllDecks from "../api/useAllDecks";
import useShowError from "../utils/useShowError";
import { clear } from "localforage";
import { SelectItem } from "../../components/inputs/SelectInput";
import { useUserStore } from "../../stores/useUserStore";
import { toString } from "../../utilities/stringify";
import useAxios from "../api/useAxios";
import { IUser } from "../../api/IUser";
import useSuccessSnack from "../snackbar/useSuccessSnack";

type SettingsPageState = {
    fullName: string;
    group: string;
    jiraEmail: string;
    jiraToken: string;
};

const useSettingsPage = () => {
    const { user, setUser } = useUserStore();

    const successMsg = useSuccessSnack();

    const [futureMeetings, setFutureMeetings] = useState(user.useDefaults);
    const toggleFutureMeetings = () => setFutureMeetings((prev) => !prev);

    const {
        decks,
        error,
        loading: deckLoading,
        setError,
        clearError,
    } = useAllDecks();
    const [deck, setDeck] = useState(decks.length > 0 ? DeckIds.Blue : "");
    const deckOptions = decks
        .filter((deck) => deck.description !== "Custom")
        .map(
            (deck): SelectItem => ({
                key: deck.id,
                value: deck.id,
                placeHolder: deck.description,
            })
        );
    useShowError(error, clearError);

    const { loading, post } = useAxios({ error, setError });

    const {
        control,
        formState: { errors, submitCount },
        clearErrors,
        handleSubmit,
        setValue,
    } = useForm<SettingsPageState>({
        defaultValues: {
            fullName: user.fullName ?? "",
            group: user.defaultGroup ?? "",
            jiraEmail: user.jiraEmail ?? "",
            jiraToken: user.jiraToken ?? "",
        },
    });
    useShowFormError(errors, submitCount, clearErrors);

    useEffect(() => {
        const setFormState = () => {
            setValue(`fullName`, user.fullName ?? user.userName ?? "");
            setValue(`group`, user.defaultGroup ?? "");
            setValue(`jiraEmail`, user.jiraEmail ?? "");
            setValue(`jiraToken`, user.jiraToken ?? "");
        };
        setFormState();
    }, [toString(user)]);

    useEffect(() => {
        const setUserDeck = () => {
            if (decks.length === 0) return;
            setDeck((user.defaultDeckId ?? decks[0].id) as DeckIds);
        };
        setUserDeck();
    }, [decks.length, toString(user)]);

    const submit = handleSubmit(async (formData) => {
        const undefinedIfEmpty = (v: string) =>
            v.trim().length > 0 ? v.trim() : undefined;

        let res = await post<IUser>(`/api/user/settings?userId=${user.id}`, {
            fullName: undefinedIfEmpty(formData.fullName),
            group: undefinedIfEmpty(formData.group),
            jiraEmail: undefinedIfEmpty(formData.jiraEmail),
            jiraToken: undefinedIfEmpty(formData.jiraToken),
            deckId: undefinedIfEmpty(deck),
            useDefaults: futureMeetings,
        });

        if (!res) return;

        setUser(res);
        successMsg(`Default settings updated`);
    });

    return {
        loading,
        control,
        deck,
        deckOptions,
        futureMeetings,

        toggleFutureMeetings,
        setDeck,
        submit,
    };
};

export default useSettingsPage;
