import { Paper, Stack, SxProps, Theme, useTheme } from "@mui/material";
import React, { ReactNode, useCallback } from "react";
import useIsOpen from "../../hooks/utils/useIsOpen";
import { ABSOLUTEFILL } from "../../contracts/Styles";
import CONSTANTS from "../../contracts/Constants";
import useCardSize from "../../hooks/utils/useCardSize";

type FlipCardProps = {
    isFlipped?: boolean;
    front?: ReactNode;
    back?: ReactNode;
    overideSize?: number;
    onClick?: () => void;
};

const FlipCard = ({
    front,
    back,
    isFlipped,
    overideSize,
    onClick,
}: FlipCardProps) => {
    const theme = useTheme();
    const [width, height] = useCardSize(overideSize);

    const cardStyles: SxProps<Theme> = {
        cursor: onClick ? "pointer" : "default",
        transformStyle: "preserve-3d",
        transform: `perspective(1000px) rotateY(${
            !isFlipped ? "180deg" : "0deg"
        }) scaleX(${isFlipped ? "-1" : "1"}) translateY(0)`,
        transition: "transform 0.5s ease-in-out, width 0.3s, height 0.3s",
    };

    const sharedStyle: SxProps<Theme> = {
        ...ABSOLUTEFILL,
        backfaceVisibility: "hidden",
    };

    const frontStyle: SxProps<Theme> = {
        ...sharedStyle,
        transform: `rotateY(180deg) scale(.98)`,
        backfaceVisibility: "unset",
    };

    const backStyle: SxProps<Theme> = {
        ...sharedStyle,
        transform: `rotateY(180deg)`,
    };

    const paperStyle: SxProps<Theme> = {
        width: "100%",
        height: "100%",
        borderRadius: theme.shape.borderRadius,
        display: "flex",
    };

    return (
        <Stack flex={1} width={width} onClick={onClick} sx={cardStyles}>
            <Stack sx={frontStyle}>
                <Paper sx={paperStyle} elevation={5}>
                    <Stack flex={1} width={"100%"}>
                        {front}
                    </Stack>
                </Paper>
            </Stack>
            <Stack sx={backStyle}>
                <Paper sx={paperStyle} elevation={5}>
                    <Stack flex={1} width={"100%"}>
                        {back}
                    </Stack>
                </Paper>
            </Stack>
        </Stack>
    );
};

export default FlipCard;
