const CONSTANTS = {
    Card: {
        Deck: {
            width: 160,
            height: 240,
        },
        Task: {
            height: 175,
        },
    },
    Jira: {
        jiraEmail: "",
        jiraToken: "",
    },
    SocketUrl: "/agahubs/meeting",
    OneMinute: 1000 * 60,
};

export default CONSTANTS;
