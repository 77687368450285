import React, { useState } from "react";

const useIsOpen = (
    initial: boolean = false
): [boolean, () => void, () => void, () => void] => {
    const [isOpen, setIsOpen] = useState(initial);
    return [
        isOpen,
        () => setIsOpen(true),
        () => setIsOpen(false),
        () => setIsOpen((prev) => !prev),
    ];
};

export default useIsOpen;
