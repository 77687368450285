import { Checkbox, FormControlLabel, FormGroup, Stack } from "@mui/material";
import React from "react";
import { TextFontFamily, TextSize } from "../utils/TextSize";

type CheckBoxInputProps = {
    checked?: boolean;
    name: string;
    onClick?: () => void;
};

const CheckBoxInput = ({
    name,
    checked = false,
    onClick,
}: CheckBoxInputProps) => {
    return (
        <Stack onClick={onClick} sx={{ cursor: "pointer" }}>
            <FormGroup>
                <FormControlLabel
                    sx={{ pointerEvents: "none" }}
                    control={
                        <Checkbox
                            // onClick={onClick}
                            color="info"
                            sx={{ pointerEvents: "all" }}
                            checked={checked}
                        />
                    }
                    componentsProps={{
                        typography: {
                            variant: TextSize.Medium,
                            fontFamily: TextFontFamily.Normal,
                            sx: { pointerEvents: "none" },
                        },
                    }}
                    label={name}
                />
            </FormGroup>
        </Stack>
    );
};

export default CheckBoxInput;
