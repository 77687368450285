import React, { useEffect } from "react";
import { useActiveParticipantStore } from "../../stores/useActiveParticpantStore";
import useIsOpen from "../utils/useIsOpen";
import useAxios from "../api/useAxios";
import { useMeetingStore } from "../../stores/useMeetingStore";
import useShowError from "../utils/useShowError";
import useLeaveMeeting from "../api/useLeaveMeeting";

type UseParticipantModalProps = {
    initialPeek: boolean;
    onClose: () => void;
};

const useParticipantModal = ({
    initialPeek,
    onClose,
}: UseParticipantModalProps) => {
    const meeting = useMeetingStore((state) => state.meeting);

    const participant = useActiveParticipantStore((state) => state.participant);
    const [peek, openPeek, closePeek, togglePeek] = useIsOpen(initialPeek);

    const { loading, leaveMeeting } = useLeaveMeeting();

    useEffect(() => {
        if (initialPeek) openPeek();
        else closePeek();
    }, [participant?.name, initialPeek]);

    return {
        participant,
        peek,
        loading,
        togglePeek,
        kickParticipant: () => leaveMeeting(participant?.name, onClose),
    };
};

export default useParticipantModal;
