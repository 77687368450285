import { Stack, StackProps } from "@mui/material";
import TouchRipple, {
    TouchRippleProps,
} from "@mui/material/ButtonBase/TouchRipple";
import useTouchRipple from "@mui/material/useTouchRipple";
import React, { CSSProperties, useCallback, useRef } from "react";
import remKeys from "../utilities/removeKeys";

type RippleStack = StackProps & {
    containerStyle?: CSSProperties | undefined;
    disabled?: boolean;
};

const RippleStack = (props: RippleStack) => {
    const rippleRef = useRef(null);
    const disabled = props.disabled;
    const { getRippleHandlers } = useTouchRipple({
        disabled: props.disabled ?? false,
        focusVisible: false,
        rippleRef,
    });
    const containerStyle: CSSProperties = props.containerStyle ?? {};

    const removeKeys = useCallback(remKeys, []);

    const params = removeKeys<StackProps>(props, [
        "containerStyle",
        "disabled",
    ]);

    return (
        <Stack
            {...params}
            onClick={undefined}
            style={{ ...params.style, ...containerStyle, position: "relative" }}
        >
            {params.children}

            <Stack
                style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    zIndex: 0,
                }}
            >
                {!disabled && (
                    <Stack
                        {...params}
                        style={{ ...params.style, position: "relative" }}
                        flex={1}
                        {...getRippleHandlers()}
                    >
                        <TouchRipple ref={rippleRef} />
                    </Stack>
                )}
            </Stack>
        </Stack>
    );
};

export default RippleStack;
