import { Button, Container, Stack, useTheme } from "@mui/material";
import React, { useState } from "react";
import HomeLauncher from "../components/launchers/HomeLauncher";
import useLogin from "../hooks/api/useLogin";
import Text from "../components/Text";
import { toString } from "../utilities/stringify";
import { useUserStore } from "../stores/useUserStore";
import useIsOpen from "../hooks/utils/useIsOpen";
import useOrientation from "../hooks/utils/useOrientation";
import useScreenSize from "../hooks/utils/useScreenSize";
import PageCard from "../components/cards/PageCard";

const HomePage = () => {
    const theme = useTheme();
    const user = useUserStore((state) => state.user);
    const [isJoining, _, __, toggleIsJoining] = useIsOpen(!user.scrumMaster);
    const { isLandscape } = useOrientation();
    const { xsOrsm } = useScreenSize();

    return (
        <PageCard>
            <HomeLauncher
                isJoining={isJoining}
                toggleJoining={toggleIsJoining}
            />
        </PageCard>
    );

    // return (
    //     <Stack
    //         flex={1}
    //         alignItems={"center"}
    //         justifyContent={"center"}
    //         py={4}
    //         px={{ xs: 6, md: undefined }}
    //     >
    //         <Container
    //             sx={{
    //                 px: { xs: 0, md: undefined },
    //                 flex: { xs: 1, md: 0 },
    //                 display: { xs: "flex", md: "block" },
    //             }}
    //             maxWidth={xsOrsm ? false : "lg"}
    //         >
    //             <Stack
    //                 flex={{ xs: 1, md: undefined }}
    //                 width={"100%"}
    //                 spacing={4}
    //             >
    // <HomeLauncher
    //     isJoining={isJoining}
    //     toggleJoining={toggleIsJoining}
    // />
    //             </Stack>
    //         </Container>
    //     </Stack>
    // );
};

export default HomePage;
