import React, { useEffect } from "react";
import { toString } from "../../utilities/stringify";

const useOnWindowRefresh = (cb: () => void = () => {}) => {
    useEffect(() => {
        const navEntries = window.performance.getEntriesByType(
            "navigation"
        ) as PerformanceNavigationTiming[];

        if (navEntries.length > 0 && navEntries[0].type === "reload") {
            cb();
        }
    }, []);
};

export default useOnWindowRefresh;
