import { Stack } from "@mui/material";
import React from "react";
import TaskListHeader from "./TaskListHeader";
import MenuInput from "../inputs/MenuInput";
import useTaskList from "../../hooks/components/useTaskList";
import TaskCard from "../cards/TaskCard";
import useComponentDimensions from "../../hooks/utils/useComponentDimensions";
import { ABSOLUTEFILL } from "../../contracts/Styles";
import TaskForm from "../taskForm/TaskForm";
import SubTopicCard from "../cards/SubTopicCard";
import { toString } from "../../utilities/stringify";
import ISubTopic from "../../api/ISubTopic";

const TaskList = () => {
    const {
        sortOptions,
        tasks,
        active,
        totalPoints,
        onSubTopicPress,
        onSubTopicAdd,
        onSubTopicEdit,
        onTaskPress,
        onTaskEdit,
        onTaskDelete,
    } = useTaskList();

    return (
        <Stack flex={1} height={"100%"} spacing={1}>
            <TaskListHeader points={totalPoints} />
            {tasks.length > 0 && (
                <Stack direction={"row"}>
                    <MenuInput title="Sort by" options={sortOptions} />
                </Stack>
            )}
            <Stack flex={1} component={"div"} style={{ position: "relative" }}>
                <Stack
                    sx={{
                        ...ABSOLUTEFILL,
                    }}
                    spacing={1}
                >
                    {tasks.length > 0 && (
                        <Stack
                            p={2}
                            spacing={3}
                            flexShrink={1}
                            sx={{
                                overflowY: "auto",
                            }}
                        >
                            {tasks.map((task, i) => {
                                const isActive = active
                                    ? active.id === task.id
                                    : false;
                                return (
                                    <SubTopicCard
                                        key={task.id}
                                        task={task}
                                        active={isActive}
                                        onCardPress={onSubTopicPress}
                                        onAddPress={onSubTopicAdd}
                                        onEditPress={onSubTopicEdit}
                                        onDeletePress={onTaskDelete}
                                        onTaskPress={onTaskPress(task)}
                                        onTaskEdit={onTaskEdit(task)}
                                        onTaskDelete={onTaskDelete}
                                    />
                                );
                            })}
                        </Stack>
                    )}
                    <Stack
                        flexGrow={1}
                        sx={{
                            position: "sticky",
                            bottom: 0,
                            zIndex: 5,
                            maxHeight: "72%",
                        }}
                    >
                        <TaskForm />
                    </Stack>
                </Stack>
            </Stack>
        </Stack>
    );
};

export default TaskList;
