import React from "react";
import useFocusInputs from "../utils/useFocusInputs";
import useLogin from "../api/useLogin";

const useLoginFormCard = () => {
    const { setInputRef, onEnterFocus } = useFocusInputs({
        inputs: ["username", "password"],
    });

    const { control, scrumMaster, toggleScrumMaster, login, loginGuest } =
        useLogin();

    return {
        control,
        scrumMaster,

        toggleScrumMaster,
        login,
        loginGuest,

        setInputRef,
        onEnterFocus,
    };
};

export default useLoginFormCard;
