import React from "react";
import { useMeetingStore } from "../../stores/useMeetingStore";
import useShowError from "../utils/useShowError";
import useAxios from "./useAxios";

const useLeaveMeeting = () => {
    const meeting = useMeetingStore((state) => state.meeting);

    const { error, loading, setError, clearError, post } = useAxios();
    useShowError(error, clearError);

    const leaveMeeting = async (name?: string, cb?: () => void) => {
        if (!meeting) return setError(`Missing meeting`);
        if (!name) return setError(`Missing participant`);

        await post(`/api/meeting/Leave?meetingId=${meeting.id}&userName=${name}`);

        cb && cb();
    };
    return {
        leaveMeeting,
        loading,
    };
};

export default useLeaveMeeting;
