import React, { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import IMeeting from "../../api/IMeeting";
import { useMeetingStore } from "../../stores/useMeetingStore";
import { useTaskFormStore } from "../../stores/useTaskFormStore";
import useAxios from "../api/useAxios";
import useShowError from "../utils/useShowError";
import { useShowFormError } from "../utils/useShowFormError";
import useGetJiraProjects from "../api/useGetJiraProjects";
import { SelectItem } from "../../components/inputs/SelectInput";
import { useActiveTopicStore } from "../../stores/useActiveTopicStore";
import ISubTopic from "../../api/ISubTopic";
import { ITopicTask } from "../../api/ITopicTask";
import { toString } from "../../utilities/stringify";
import { produce } from "immer";

type CreateJiraTaskFormState = {
    meetingId: string;
    projectName: string;
    issueType: string;
    subTopicId: string;
    taskId: string;
    childTopics: string;
};

type JiraTaskConfig = {
    projectId: string;
    issueType: string;
};

type ChildJiraTaskConfig = JiraTaskConfig & {
    taskId: string;
};

const ValidIssueTypes = ["Task", "Bug", "Story"];

const useCreateJiraTaskForm = () => {
    const meeting = useMeetingStore((state) => state.meeting);
    const { subTopic, task } = useActiveTopicStore(
        ({ activeSubTopic, activeTask }) => ({
            subTopic: activeSubTopic,
            task: activeTask,
        })
    );
    const setOpen = useTaskFormStore((state) => state.setOpen);
    const setMeeting = useMeetingStore((state) => state.setMeeting);

    const hasChildren =
        task === undefined && subTopic && subTopic.tasks.length > 0
            ? true
            : false;
    const isChild = task !== undefined;
    const parentTask = task ?? subTopic;

    const { error, loading, setError, clearError, post } = useAxios();
    useShowError(error, clearError);

    // Main Topic or Subtopic Creation Settings
    const [projectId, setProjectId] = useState("");
    const { projects, loading: projectLoading } = useGetJiraProjects({
        axiosProps: { error, setError },
    });
    const projectOptions: SelectItem[] = projects.map((project) => ({
        key: project.id,
        value: project.id,
        placeHolder: `${project.name} - ${project.key}`,
    }));
    const project = projects.find((project) => project.id === projectId);

    const [issueType, setIssueType] = useState("");
    const issueOptions: SelectItem[] =
        project?.issueTypes
            .filter((type) => ValidIssueTypes.includes(type.name))
            .map((type) => ({
                key: type.name,
                value: type.name,
                placeHolder: type.name,
            })) ?? [];

    // Create Jira Issue for children (Main topic Tasks)
    const [createIssueForChildren, setCreateIssueForChildren] = useState(false);
    const toggleCreateIssueForChildren = () =>
        setCreateIssueForChildren((prev) => !prev);

    // Allow each child to specify which project and issue type they will be in Jira
    const [individualChildren, setIndividualChildren] = useState(false);
    const toggleIndividualChildren = () =>
        setIndividualChildren((prev) => !prev);

    // Default Children Creation Settings
    const [defaultChildProjectId, setDefaultChildProjectId] = useState("");
    const [defaultIssueType, setDefaultIssueType] = useState("");

    // Allow customization of how children are added to jira
    const [childConfig, setChildConfig] = useState<ChildJiraTaskConfig[]>(
        hasChildren
            ? (parentTask as ISubTopic).tasks.map((task) => ({
                  taskId: task.id,
                  issueType: "",
                  projectId: "",
              }))
            : []
    );
    const getChildConfig = useCallback(
        (task: ITopicTask) => {
            let found = childConfig.find((t) => t.taskId === task.id);

            if (!found) {
                found = {
                    taskId: task.id,
                    issueType: defaultIssueType,
                    projectId: defaultChildProjectId,
                };
                setChildConfig((prev) =>
                    produce(prev, (draft) => {
                        draft.push(found!);
                    })
                );
            }

            const setIssueType = (val: string) =>
                setChildConfig((prev) =>
                    produce(prev, (draft) => {
                        let index = draft.findIndex(
                            (t) => t.taskId === task.id
                        );
                        if (index === -1) return;

                        let found = draft[index];
                        found.issueType = val;
                        draft[index] = found;

                        return;
                    })
                );

            const setProjectId = (val: string) =>
                setChildConfig((prev) =>
                    produce(prev, (draft) => {
                        let index = draft.findIndex(
                            (t) => t.taskId === task.id
                        );
                        if (index === -1) return;

                        let found = draft[index];
                        found.projectId = val;
                        draft[index] = found;

                        return;
                    })
                );

            return {
                projectId: found.projectId,
                issueType: found.issueType,
                setIssueType,
                setProjectId,
            };
        },
        [
            toString(childConfig),
            setChildConfig,
            defaultChildProjectId,
            defaultIssueType,
            issueOptions.length,
        ]
    );

    // Control what gets updated and when
    useEffect(() => {
        updateDefaults();
    }, [projectId, issueType]);

    useEffect(() => {
        updateDefaultIndividuals();
    }, [defaultChildProjectId, defaultIssueType]);

    const updateDefaults = useCallback(() => {
        if (createIssueForChildren) return;

        setDefaultChildProjectId(projectId);
        setDefaultIssueType(issueType);
    }, [projectId, issueType, createIssueForChildren]);

    const updateDefaultIndividuals = useCallback(() => {
        if (individualChildren) return;
        setChildConfig((prev) =>
            produce(prev, (draft) => {
                for (let config of draft) {
                    config.projectId = defaultChildProjectId;
                    config.issueType = defaultIssueType;
                }
            })
        );
    }, [defaultChildProjectId, defaultIssueType, individualChildren]);

    // Form Functionality
    const close = () => {
        setOpen(false);
    };

    const isValid = () => {
        if (!meeting) return setError(`Missing Meeting`);
        if (!project) return setError(`Missing Selected Project`);
        if (!issueType) return setError(`Missing Issue Type`);
        if (!subTopic) return setError(`Missing Subtopic`);
        if (!parentTask) return setError(`Missing Parent Task`);

        return true;
    };

    const submit = async () => {
        if (!isValid()) return;

        let res = await post<IMeeting>(
            `/api/meeting/CreateJiraIssue?meetingId=${meeting!.id}`,
            {
                meetingId: meeting!.id,
                projectName: project!.key,
                issueType: issueType,
                subTopicId: subTopic!.id,
                taskId: parentTask?.id,
                childTopics: createIssueForChildren
                    ? childConfig.map((config) => ({
                          taskId: config.taskId,
                          issueType: config.issueType,
                          projectName: projects.find(
                              (project) => project.id === config.projectId
                          )!.key,
                      }))
                    : [],
            }
        );

        if (!res) return;

        setMeeting(res);
        close();
    };

    return {
        parentTask,
        loading,
        projectLoading,
        projectOptions,
        issueOptions,
        projectId,
        issueType,
        createIssueForChildren,
        defaultChildProjectId,
        defaultIssueType,
        hasChildren,
        individualChildren,
        isChild,

        submit,
        close,
        setProjectId,
        setIssueType,
        toggleCreateIssueForChildren,
        setDefaultChildProjectId,
        setDefaultIssueType,
        toggleIndividualChildren,
        getChildConfig,
    };
};

export default useCreateJiraTaskForm;
