import React, { useEffect } from "react";

const useTimeoutEffect = (cb: () => void, deps: any[], delay: number = 250) => {
    useEffect(() => {
        const timeout = setTimeout(cb, delay);

        return () => {
            clearTimeout(timeout);
        };
    }, deps);
};

export default useTimeoutEffect;
