import { Stack } from "@mui/material";
import React from "react";
import LauncherHeader from "./LauncherHeader";
import useParticipantLauncher from "../../hooks/components/useParticipantLauncher";
import TextInput from "../inputs/TextInput";
import SelectInput from "../inputs/SelectInput";
import CheckBoxInput from "../inputs/CheckBoxInput";
import LauncherButtons from "./LauncherButtons";

type ParticipantLauncherProps = {
    toHostMeeting: () => void;
};

const ParticipantLauncher = ({ toHostMeeting }: ParticipantLauncherProps) => {
    const {
        user,
        name,
        meeting,
        group,
        meetings,
        canBeHost,
        joinAsHost,
        selectedMeeting,

        setName,
        setMeeting,
        setGroup,
        toggleJoinAsHost,
        submit,
        clearForm,
    } = useParticipantLauncher();

    return (
        <Stack
            width={"100%"}
            spacing={3}
            alignItems={"center"}
            justifyContent={"flex-start"}
        >
            <LauncherHeader title={"Join Meeting"} />

            <Stack spacing={{ xs: 2, md: 3 }} width={"100%"}>
                {(canBeHost ? !joinAsHost : true) && (
                    <TextInput
                        value={name}
                        setValue={setName}
                        name="Display Name"
                        fullWidth
                    />
                )}
                <SelectInput
                    fullWidth
                    value={meeting}
                    setValue={setMeeting}
                    name="Select Meeting"
                    options={meetings.map((meeting) => ({
                        key: meeting.id,
                        value: meeting.id,
                        placeHolder: meeting.topic,
                    }))}
                />
                {(canBeHost ? !joinAsHost : true) && selectedMeeting && (
                    <SelectInput
                        fullWidth
                        value={group}
                        setValue={setGroup}
                        name="Select Meeting Group"
                        options={selectedMeeting.groups.map((group) => ({
                            key: group,
                            value: group,
                            placeHolder: group,
                        }))}
                    />
                )}
                {canBeHost && (
                    <Stack width={"100%"}>
                        <CheckBoxInput
                            name="Join meeting as host"
                            checked={joinAsHost}
                            onClick={toggleJoinAsHost}
                        />
                    </Stack>
                )}
            </Stack>

            <LauncherButtons
                leftText={user.scrumMaster ? "Host Meeting" : undefined}
                submitText="Join"
                clearText="Clear"
                onSubmit={submit}
                onClear={clearForm}
                onLeft={toHostMeeting}
            />
        </Stack>
    );
};

export default ParticipantLauncher;
