import { useMediaQuery, useTheme } from "@mui/material";
import React, { useEffect } from "react";

const useScreenSize = (logs?: boolean) => {
    const theme = useTheme();
    const xs = useMediaQuery(theme.breakpoints.only("xs"));
    // 600 px break point
    const sm = useMediaQuery(theme.breakpoints.only("sm"));
    // 900 px break point
    const md = useMediaQuery(theme.breakpoints.only("md"));
    // 1200 px break point
    const lg = useMediaQuery(theme.breakpoints.only("lg"));
    const xl = useMediaQuery(theme.breakpoints.only("xl"));

    const xsOrsm = xs || sm;
    const mdUp = md || lg || xl;

    // useEffect(() => {
    //     xs && console.log(`xs`, xs);
    //     sm && console.log(`sm`, sm);
    //     md && console.log(`md`, md);
    //     lg && console.log(`lg`, lg);
    //     xl && console.log(`xl`, xl);
    //     xsOrsm && console.log(`xsOrsm`, xsOrsm);
    //     mdUp && console.log(`mdUp`, mdUp);
    //     console.log(``);
    // }, [xs, sm, md, lg, xl]);

    return {
        xs,
        sm,
        xsOrsm,
        mdUp,
        md,
        lg,
        xl,
    };
};

export default useScreenSize;
