import React, { useEffect, useState } from "react";
import useAxios, { UseAxiosProps } from "./useAxios";
import useTimeoutEffect from "../utils/useTimeoutEffect";
import IMeeting from "../../api/IMeeting";

type UseGetMeetingProps = {
    meetingId?: string;
    axiosProps?: UseAxiosProps;
};

const useGetMeeting = (params?: UseGetMeetingProps) => {
    const [meeting, setMeeting] = useState<IMeeting | undefined>(undefined);
    const { error, loading, clearError, setError, get } = useAxios(
        params?.axiosProps
    );

    useTimeoutEffect(
        () => {
            if (params?.meetingId) getMeeting(params.meetingId, true);
        },
        [params?.meetingId],
        500
    );

    const getMeeting = async (id: string, setState: boolean = false) => {
        let res = await get<IMeeting>(`/api/meeting/FindMeeting?meetingId=${id}`);

        if (!res) return undefined;

        if (setState) setMeeting(res);

        return res;
    };

    return {
        error,
        loading,
        meeting,
        getMeeting,
        clearError,
        setError,
    };
};

export default useGetMeeting;
