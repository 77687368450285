import { BrowserRouter } from "react-router-dom";
import { SnackbarProvider } from "notistack";
import { ThemeProvider } from "@emotion/react";
import { theme } from "./utilities/theme";
import V2AppRoutes from "./navigation/AppRoutes";

export default function App() {
    return (
        <ThemeProvider theme={theme}>
            <SnackbarProvider
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
            >
                <BrowserRouter>
                    {/* <V1AppRoutes /> */}
                    <V2AppRoutes />
                </BrowserRouter>
            </SnackbarProvider>
        </ThemeProvider>
    );
}
