import { Stack } from "@mui/material";
import React from "react";
import IParticipant from "../api/IParticipant";
import { DeckIds } from "../api/IDeck";
import { BicyclePokerKeys, LovelyPokerKeys } from "../utilities/pokerImages";
import useParticipantDeck from "../hooks/components/useParticipantDeck";
import PokerCard from "./cards/PokerCard";
import FilledPokerCard from "./cards/FilledPokerCard";
import TextInput from "./inputs/TextInput";

type ParticipantDeckProps = {
    participant: IParticipant;
    selected?: string;
    select?: (value: string) => void;
};

const ParticipantDeck = ({
    participant,
    selected,
    select,
}: ParticipantDeckProps) => {
    const { ref, rows, selectedRow, isCustom } = useParticipantDeck({
        participant,
        selected,
    });
    return (
        <Stack
            ref={ref}
            width={"100%"}
            alignItems={"center"}
            justifyContent={"center"}
            spacing={2}
        >
            {isCustom ? (
                <Stack
                    spacing={3}
                    alignItems={"center"}
                    justifyContent={"center"}
                >
                    <PokerCard deck={DeckIds.Blue} value={selected} />
                    <TextInput
                        name="Custom Value"
                        value={selected ?? ""}
                        setValue={(v) => select && select(v)}
                    />
                </Stack>
            ) : (
                rows.map((row, i) => {
                    const needsMargin = i !== 0 && selectedRow === i;
                    return (
                        <Stack
                            key={i}
                            pt={needsMargin ? 3 : 0}
                            direction={"row"}
                            alignItems={"center"}
                            justifyContent={"center"}
                            spacing={2}
                            sx={{
                                transition: `250ms ease-in-out`,
                                transitionProperty: `margin margin-top`,
                            }}
                        >
                            {row.map((cardValue) => {
                                const isSelected = cardValue === selected;
                                return (
                                    <PokerCard
                                        key={cardValue}
                                        deck={participant.deckId as DeckIds}
                                        value={cardValue}
                                        sx={{
                                            transform: `translateY(${
                                                isSelected ? -25 : 0
                                            }px) scale(${
                                                isSelected ? 1.05 : 1
                                            })`,
                                            transition: `250ms ease-in-out`,
                                            transitionProperty: `transform scale`,
                                            ":hover": {
                                                transform: `translateY(${
                                                    isSelected ? -25 : -5
                                                }px) scale(${
                                                    isSelected ? 1.05 : 1
                                                })`,
                                            },
                                        }}
                                        onClick={() =>
                                            select && select(cardValue)
                                        }
                                    />
                                );
                            })}
                        </Stack>
                    );
                })
            )}
        </Stack>
    );
};

export default ParticipantDeck;
