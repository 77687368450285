import { CircularProgress, Stack } from "@mui/material";
import React from "react";

const PageLoading = () => {
    return (
        <Stack flex={1} alignItems={"center"} justifyContent={"center"}>
            <CircularProgress color="info" />
        </Stack>
    );
};

export default PageLoading;
