import { Stack } from "@mui/material";
import React from "react";
import IParticipantPoker from "../../api/IParticipantPoker";
import { DeckIds, DeckNames } from "../../api/IDeck";
import useSvgCard from "../../hooks/components/useSvgCard";
import { theme } from "../../utilities/theme";
import { Deck } from "@mui/icons-material";
import { CSSProperties } from "@mui/styled-engine";

type SvgCardProps = {
    deck: DeckIds;
    backgroundSize?: CSSProperties["backgroundSize"];
    value?: string;
};

const SvgCard = ({ value, backgroundSize = "cover", deck }: SvgCardProps) => {
    const { getCardImage } = useSvgCard({ value, deck });

    return (
        <Stack
            flex={1}
            width={"100%"}
            sx={{
                borderRadius: value
                    ? deck === DeckIds.Lovely
                        ? theme.shape.borderRadius
                        : undefined
                    : theme.shape.borderRadius,
                backgroundSize,
                backgroundImage: `url(${getCardImage()})`,
                backgroundRepeat: "no-repeat",
                backgroundPosition:
                    backgroundSize === "contain" ? "center" : undefined,
            }}
        ></Stack>
    );
};

export default SvgCard;
