import React, { useEffect, useState } from "react";
import IMeeting from "../../api/IMeeting";
import { useUserStore } from "../../stores/useUserStore";
import useAxios, { UseAxiosProps } from "./useAxios";
import { IJiraProject } from "../../api/IJiraProject";
import { useMeetingStore } from "../../stores/useMeetingStore";

type UseGetJiraProjcets = {
    axiosProps?: UseAxiosProps;
};

const useGetJiraProjects = (props?: UseGetJiraProjcets) => {
    const user = useUserStore((state) => state.user);
    const meeting = useMeetingStore((state) => state.meeting);
    const [projects, setProjects] = useState<IJiraProject[]>([]);

    const { loading, error, setError, clearError, get } = useAxios(
        props?.axiosProps
    );

    useEffect(() => {
        if (user.refreshToken.length > 0) getProjects();
    }, [user.refreshToken.length]);

    const getProjects = async () => {
        if (!meeting)
            return props?.axiosProps?.setError
                ? props?.axiosProps.setError(`Missing meeting`)
                : setError(`Missing Meeting`);

        let res = await get<IJiraProject[]>(
            `/api/meeting/GetJiraProjects?meetingId=${meeting.id}`
        );

        if (!res) return;

        setProjects(res);
    };

    return {
        loading,
        error,
        projects,
        setError,
        clearError,
    };
};

export default useGetJiraProjects;
