import React, { useCallback } from "react";
import IMeeting from "../../api/IMeeting";
import { useMeetingStore } from "../../stores/useMeetingStore";
import { toString } from "../../utilities/stringify";
import { BicyclePokerKeys, LovelyPokerKeys } from "../../utilities/pokerImages";

type PersonCard = {
    value: string;
    person: string;
};

type ResultPortion = {
    value: string;
    people: string[];
};

type Result = {
    lowest: ResultPortion;
    highest: ResultPortion;
    favorite: ResultPortion;
};

enum CardStringValues {
    Infinite = LovelyPokerKeys.Infinite,
    Coffee = LovelyPokerKeys.Coffee,
    Brownie = LovelyPokerKeys.Brownie,
    Dragons = LovelyPokerKeys.Dragons,
    YakShaving = LovelyPokerKeys.YakShaving,
    Question = BicyclePokerKeys.Question,
}

const useFavoriteCardModal = () => {
    const meeting = useMeetingStore((state) => state.meeting);

    const getSortingValue = useCallback((value: string) => {
        switch (value) {
            case CardStringValues.Infinite as unknown as string:
            case CardStringValues.Coffee as unknown as string:
            case CardStringValues.Brownie as unknown as string:
            case CardStringValues.Dragons as unknown as string:
            case CardStringValues.YakShaving as unknown as string:
            case CardStringValues.Question as unknown as string:
                return -1;
            default:
                return parseFloat(value);
        }
    }, []);

    const analyzeParticipants = useCallback((meeting?: IMeeting) => {
        let result: Result = {
            lowest: {
                value: "",
                people: [],
            },
            favorite: {
                value: "",
                people: [],
            },
            highest: {
                value: "",
                people: [],
            },
        };

        if (!meeting) return result;

        let allCards = meeting.participants
            .map((person) => ({
                value: person.selectedPoker?.pokerValue,
                person: person.name,
            }))
            .filter((person) => person.value) as PersonCard[];

        let minKey = "";
        let maxKey = "";
        let favKey = "";

        let minCount = Number.MAX_SAFE_INTEGER;
        let maxCount = 0;
        let favCount = 0;

        let counts: { [key: string]: string[] } = {};

        for (let pc of allCards) {
            let value = getSortingValue(pc.value);

            const key = value === -1 ? pc.value : value;

            counts[key]
                ? counts[key].push(pc.person)
                : (counts[key] = [pc.person]);
        }

        for (let key in counts) {
            let cardValue = getSortingValue(key);

            if (cardValue !== -1) {
                if (cardValue < minCount) {
                    minCount = cardValue;
                    minKey = key;
                }

                if (cardValue > maxCount) {
                    maxCount = cardValue;
                    maxKey = key;
                }
            }

            let votesGathered = counts[key].length;

            if (votesGathered > favCount) {
                favCount = votesGathered;
                favKey = key;
            }
        }

        if (maxKey !== minKey) {
            result.lowest = {
                value: minKey,
                people: counts[minKey],
            };
        }

        result.favorite = {
            value: favKey,
            people: counts[favKey],
        };

        if (favKey !== maxKey) {
            result.highest = {
                value: maxKey,
                people: counts[maxKey],
            };
        }

        return result;
    }, []);

    return analyzeParticipants(meeting);
};

export default useFavoriteCardModal;
